import { init } from './appFetch';
import * as elementService from './elementService';
import * as userService from './userService';
import * as configurationParameterService from './configurationParameterService';
import * as geometricLayerService from './geometricLayerService';
import * as locationService from './locationService';
import * as attachedFileService from './attachedFileService';
import * as applicationDataService from './applicationDataService';

export { default as NetworkError } from "./NetworkError";

export default { init, elementService, userService, configurationParameterService, geometricLayerService, locationService, attachedFileService, applicationDataService };