const getModuleState = state => state.geometricElement;

export const getGeometricElementType = state =>
    getModuleState(state).geometricElementType;

export const getGeometricElementsByElementType = state =>
    getModuleState(state).geometricElementsByElementType;

export const getAllCodes = state =>
    getModuleState(state).allCodes;

export const getTotalAttributeType = state =>
    getModuleState(state).totalAttributeType;

export const getTotalGeometricElementType = state =>
    getModuleState(state).totalGeometricElementType;

//Center by population entities selectors

export const getListProvinces = state =>
    getModuleState(state).listProvinces;

export const getListCouncils = state =>
    getModuleState(state).listCouncils;

export const getListParishes = state =>
    getModuleState(state).listParishes;

export const getListEntityPopulations = state =>
    getModuleState(state).listEntityPopulations;

export const getListCounties = state =>
    getModuleState(state).listCounties;

//GeometricLayerService selectors
export const getTotalGeometricLayerGroup = state =>
    getModuleState(state).totalGeometricLayerGroup

export const getTotalGeometricLayer = state =>
    getModuleState(state).totalGeometricLayer

// Map current zoom
export const getMapCurrentExtent = state =>
    getModuleState(state).mapCurrentExtent

// Geometric element relations
export const getGeometricElementRelations = state =>
    getModuleState(state).geometricElementRelations

// Geometric element
export const getGeometricElement = state =>
    getModuleState(state).geometricElement

// Geometric element join
export const getGeometricElementJoins = state =>
    getModuleState(state).geometricElementJoins

// Geometric element type styles
export const getStylesForPoint = state =>
    getModuleState(state).stylesForPoint

export const getStylesForPolygon = state =>
    getModuleState(state).stylesForPolygon

export const getStylesForLine = state =>
    getModuleState(state).stylesForLine

// Layer source
export const getLayerSource = state =>
    getModuleState(state).layerSource