import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Logout from '../../users/components/Logout';
import AllConfigurationparameters from '../../configurationParameter/components/AllConfigurationParameters';
import AllUserAccounts from '../../users/components/AllUserAccounts';
import AllGeometricElementType from '../../geometricElements/components/AllGeometricElementType';
import AllAttributeType from '../../geometricElements/components/AllAttributeType';
import GeometricElementsByGeometricElementType from '../../geometricElements/components/GeometricElementsByGeometricElementType';
import OpenLayersMap from '../../geometricElements/components/OpenLayersMap';
import Component404 from './Component404';
import { ErrorDialog, LoadingDialog } from '../../common';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../selectors';
import * as actions from '../actions';
import AllGeometricLayerGroup from '../../geometricElements/components/AllGeometricLayerGroup';
import AllGeometricLayer from '../../geometricElements/components/AllGeometricLayer';
import GeometricElementDetails from '../../geometricElements/components/GeometricElementDetails';
import AllGeneralAttachedFile from '../../attachedFile/components/AllGeneralAttachedFile';
import AllGeometricElementTypeStyles from '../../geometricElements/components/AllGeometricElementTypeStyles';
import users from '../../users';
import ApplicationData from './ApplicationData';

const Body = () => {

    let location = useLocation();
    let error = useSelector(selectors.getError);
    let dispatch = useDispatch();
    let authenticatedUserLogin = useSelector(users.selectors.getLogin);
    const authenticatedUser = useSelector(users.selectors.getUser);

    return (
        <div id="container">
            <ErrorDialog
                error={error}
                onClose={() => {
                    dispatch(actions.error(null));
                }}
            />
            <LoadingDialog />
            <Switch location={location}>
                <Route exact path="/" component={OpenLayersMap} />
                <Route exact path="/createElement" component={OpenLayersMap} />
                <Route exact path="/users/logout" component={Logout} />
                <Route exact path="/admin/elementType" component={AllGeometricElementType} />
                <Route exact path="/admin/attributeType" component={AllAttributeType} />
                <Route exact path="/admin/configurationParameter" component={AllConfigurationparameters} />
                <Route exact path="/admin/users" component={AllUserAccounts} />
                <Route exact path="/geometric_elements/:id" component={() => <GeometricElementsByGeometricElementType key={location.pathname} />} />
                <Route exact path="/geometric_elements/:id/myElements" component={() => <GeometricElementsByGeometricElementType key={location.pathname} />} />
                <Route exact path="/geometric_element/details/:id" component={() => <GeometricElementDetails key={authenticatedUserLogin} />} />
                <Route exact path="/admin/layerGroup" component={AllGeometricLayerGroup} />
                <Route exact path="/admin/layers" component={AllGeometricLayer} />
                <Route exact path="/admin/generalAttachedFiles" component={AllGeneralAttachedFile} />
                <Route exact path="/admin/geometricElementTypeStyles" component={AllGeometricElementTypeStyles} />
                {authenticatedUser && authenticatedUser.userRoleDto.code === "ADMIN" && <Route exact path="/admin/about" component={ApplicationData} />}
                <Route component={Component404} />
            </Switch>
        </div>
    );

};

export default Body;