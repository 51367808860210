import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import { withRouter } from 'react-router-dom';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeneralAttachedFile from './AddModifyGeneralAttachedFile';
import axios from 'axios'
import { Modal } from 'react-bootstrap';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        user: userSelectors.getUser(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalGeneralAttachedFiles: selectors.getTotalGeneralAttachedFiles(state),
    }
}

class AllGeneralAttachedFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalGeneralAttachedFiles: null,
            page: 1,
            sizePerPage: 10,
            filters: {},
            backendErrors: null,
            addModifyGeneralAttachedFileModalShow: false,
            generalAttachedFileToModify: null,
            deleteGeneralAttachedFileModalShow: false,
            deleteGeneralAttachedFileId: null,
            deleteGeneralAttachedFileDescription: null,
            downloadFileErrorModalVisible: false
        }
        this.clearFilters = (generalAttachedFileId) => {
            let { idFilter, descriptionFilter, internalFilenameFilter, originalFileNameFilter,
                sizeFilter, fileFormatFilter, typeFilter } = this.state.filters;
            if (generalAttachedFileId) {
                idFilter(`${generalAttachedFileId}`)
            } else {
                idFilter('');
            }
            descriptionFilter('');
            internalFilenameFilter('');
            originalFileNameFilter('');
            sizeFilter('');
            fileFormatFilter('');
            typeFilter('');
        }

        this.showActionButtonTooltip = () => {
            jQuery(function () {
                jQuery('.actionButton').tooltip({ trigger: "hover" });
            });

            jQuery(function () {
                jQuery('.actionButton').tooltip().click(function () {
                    jQuery('.actionButton').tooltip("hide");
                });
            });
        }
    }

    componentDidMount() {
        this.setState({ totalGeneralAttachedFiles: this.props.totalGeneralAttachedFiles });
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
        if (!this.state.totalGeneralAttachedFiles && this.props.totalGeneralAttachedFiles) {
            this.setState({ totalGeneralAttachedFiles: this.props.totalGeneralAttachedFiles });
        }
        this.showActionButtonTooltip();
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                let fileFormatFilterOptions = [];
                let allFileFormat = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('FILE_FORMAT_GROUP_CODE') !== -1);
                allFileFormat.forEach((legendType, index) => {
                    fileFormatFilterOptions[index] = {
                        value: legendType.code,
                        label: getInternationalization(this.props.language, legendType.code, this.props.allCodes, this.props.activeLanguages)
                    }
                });

                let attachedFileTypeOptions = [];
                let allFileTypeOptions = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('GENERAL_ATTACHED_FILE_TYPE') !== -1);
                allFileTypeOptions.forEach((attachedFileType, index) => {
                    attachedFileTypeOptions[index] = {
                        value: attachedFileType.code,
                        label: getInternationalization(this.props.language, attachedFileType.code, this.props.allCodes, this.props.activeLanguages)
                    }
                });

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    })
                },
                {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.description" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.description" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.descriptionFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    })
                }, {
                    dataField: 'type',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.type" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ?
                            <InternationalizationRender
                                locale={this.props.language}
                                value={cellContent}
                                listInternationalization={this.props.allCodes}
                            />
                            :
                            "-"
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.type" }).toLocaleLowerCase() }),
                        options: attachedFileTypeOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.typeFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    }),
                    sortValue: (cell, row) => getInternationalization(this.props.language, cell, this.props.allCodes, this.props.activeLanguages)
                }, {
                    dataField: 'internalFilename',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.internalFilename" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.internalFilename" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.internalFilenameFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    }),
                }, {
                    dataField: 'originalFilename',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.originalFilename" }),
                    headerAlign: 'left',
                    sort: true,
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.originalFilename" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.originalFileNameFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    })
                }, {
                    dataField: 'size',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.size" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent >= 1024 * 1024 ? this.props.intl.formatNumber(cellContent / (1024 * 1024), { style: 'decimal', maximumFractionDigits: 2 }) + ' MB'
                            :
                            cellContent >= 1024 ?
                                this.props.intl.formatNumber(cellContent / 1024, { style: 'decimal', maximumFractionDigits: 2 }) + ' KB'
                                :
                                this.props.intl.formatNumber(cellContent, { style: 'decimal', maximumFractionDigits: 2 }) + ' bytes'
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.size" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.sizeFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        }
                    }),
                    filterValue: (cellContent, row) => cellContent >= 1024 * 1024 ? this.props.intl.formatNumber(cellContent / (1024 * 1024), { style: 'decimal', maximumFractionDigits: 2 }) + ' MB'
                        :
                        cellContent >= 1024 ?
                            this.props.intl.formatNumber(cellContent / 1024, { style: 'decimal', maximumFractionDigits: 2 }) + ' KB'
                            :
                            this.props.intl.formatNumber(cellContent, { style: 'decimal', maximumFractionDigits: 2 }) + ' bytes'
                }, {
                    dataField: 'fileFormat.code',
                    text: this.props.intl.formatMessage({ id: "project.attachedFiles.fileFormat" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        <InternationalizationRender
                            locale={this.props.language}
                            value={cellContent}
                            listInternationalization={this.props.allCodes}
                        />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.attachedFiles.fileFormat" }).toLocaleLowerCase() }),
                        options: fileFormatFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.fileFormatFilter = filter;
                                return { filters }
                            })
                        },
                        onFilter: (filterValue) => {
                            this.showActionButtonTooltip()
                        },
                    }),
                    sortValue: (cell, row) => getInternationalization(this.props.language, cell, this.props.allCodes, this.props.activeLanguages)
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <div className="text-left">
                            <span
                                onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeneralAttachedFileModalShow: true,
                                        generalAttachedFileToModify: row
                                    })
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            <span
                                id={'downloadFile' + row.id}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                onClick={() => {
                                    axios({
                                        url: row.fileUrl,
                                        method: 'GET',
                                        responseType: 'blob'
                                    }).then((response) => {
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', row.originalFilename);
                                        document.body.appendChild(link);
                                        link.click();
                                    }).catch(error => {
                                        this.setState({ downloadFileErrorModalVisible: true });
                                    });
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.download" })}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </span>
                            <span onClick={() =>
                                this.setState({
                                    backendErrors: null,
                                    deleteGeneralAttachedFileModalShow: true,
                                    deleteGeneralAttachedFileId: row.id,
                                    deleteGeneralAttachedFileDescription: row.description
                                })
                            }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"delete" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.attachedFile" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            backendErrors: null,
                                            addModifyGeneralAttachedFileModalShow: true,
                                            generalAttachedFileToModify: null
                                        })
                                    }}
                                    id="addGeneralAttachedFile"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.attachedFiles.add" />
                                </button>
                            </div>

                            {/* Delete general attached file dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeneralAttachedFileModalShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeneralAttachedFileDescription}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeneralAttachedFileModalShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeneralAttachedFile(this.state.deleteGeneralAttachedFileId,
                                        () => {
                                            this.setState({
                                                deleteGeneralAttachedFileModalShow: false,
                                                totalGeneralAttachedFiles: this.props.totalGeneralAttachedFiles
                                            });
                                        },
                                        (error) => setBackendErrors(error)))

                                }}
                                setBackendErrors={setBackendErrors}
                            />
                            {/* Add/Modify general attached file */}
                            <AddModifyGeneralAttachedFile
                                modalShow={this.state.addModifyGeneralAttachedFileModalShow}
                                generalAttachedFileToModify={this.state.generalAttachedFileToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        addModifyGeneralAttachedFileModalShow: false,
                                        totalGeneralAttachedFiles: this.props.totalGeneralAttachedFiles
                                    });
                                    if (this.state.generalAttachedFileToModify) {
                                        this.clearFilters(this.state.generalAttachedFileToModify.id);
                                    } else {
                                        let newGeneralAttachedFile =
                                            this.props.totalGeneralAttachedFiles[this.props.totalGeneralAttachedFiles.length - 1];
                                        if (newGeneralAttachedFile && newGeneralAttachedFile.id) {
                                            this.clearFilters(newGeneralAttachedFile.id);
                                        } else {
                                            this.clearFilters();
                                        }
                                    }
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeneralAttachedFileModalShow: false })}
                            />
                            {/* Modal download file error */}
                            <Modal
                                show={this.state.downloadFileErrorModalVisible}
                                onHide={() => this.setState({ downloadFileErrorModalVisible: false })}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <FormattedMessage id="project.attachedFiles.error.cannotDownloadFileHeader" />
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormattedMessage id="project.attachedFiles.error.cannotDownloadFileBody" />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={() => this.setState({ downloadFileErrorModalVisible: false })}>
                                        <FormattedMessage id="project.common.close" />
                                    </button>
                                </Modal.Footer>
                            </Modal>
                            <br />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                rowClasses="text-left"
                                data={this.state.totalGeneralAttachedFiles ?
                                    this.state.totalGeneralAttachedFiles : []
                                }
                                noDataIndication={this.state.totalGeneralAttachedFiles ?
                                    this.props.intl.formatMessage(
                                        { id: "project.attachedFiles.noElements" }) :
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                striped
                                condensed
                                pagination={paginationFactory({
                                    sizePerPage: this.state.currentSize,
                                    showTotal: true,
                                    alwaysShowAllBtns: true,
                                    hidePageListOnlyOnePage: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    onPageChange: (page, sizePerPage) => this.setState({ page }),
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({ sizePerPage: sizePerPage })
                                    },
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    ),
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                })}
                            />
                        </div>
                        <br />
                    </div>
                )
            }

            if (this.props.user && this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return (
            null
        );
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllGeneralAttachedFile)));