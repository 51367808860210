import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import backend from '../../backend';
import { generateIdentificatorForLoadingAction } from './util';

export const error = (error) => ({
    type: actionTypes.ERROR,
    error
});

export const loading = (id) => ({
    type: actionTypes.LOADING,
    id
});

export const loaded = (id) => ({
    type: actionTypes.LOADED,
    id
});

export const languageCompleted = language => ({
    type: actionTypes.APP_LANGUAGE,
    language
});

export const language = language => (dispatch, getState) => {
    const currentLanguage = selectors.getLanguage(getState());

    if (currentLanguage) {
        if (currentLanguage !== language) {
            dispatch(languageCompleted(language));
        }
    } else {
        dispatch(languageCompleted(language));
    }
};

export const activeLanguages = activeLanguages => ({
    type: actionTypes.ACTIVE_LANGUAGES,
    activeLanguages
});

export const availableLanguages = availableLanguages => ({
    type: actionTypes.AVAILABLE_LANGUAGES,
    availableLanguages
});

const applicationDataCompleted = applicationData => ({

    type: actionTypes.APPLICATION_DATA,
    applicationData

});

export const applicationData = (onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(loading(loadingId));
    backend.applicationDataService.findApplicationData(selectors.getLanguage(getState()),
        (applicationData) => {
            dispatch(applicationDataCompleted(applicationData));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(loaded(loadingId));
        },
        (errors) => {
            if (typeof onErrors === 'function') {

                onErrors(errors);
            }
            dispatch(loaded(loadingId));
        }
    );
}

export const locale = locale => ({
    type: actionTypes.APP_LOCALE,
    locale
});
