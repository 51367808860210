import app from ".";

export const generateIdentificatorForLoadingAction = () => {

    return (Math.random().toString(36)).substring(2);
}

export const reauthenticationCallback = (props) => {

    props.dispatch(app.actions.loaded(null));
    props.history.push('/users/logout');
}