import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as userSelectors from '../../users/selectors';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import axios from 'axios';
import { faDownload, faTrashAlt, faImages, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter, ModalTitle, Modal } from 'react-bootstrap';
import GeometricElementFileGallery from './GeometricElementFileGallery';

const ADMIN_ROLE = "ADMIN";

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
    });
}

const GeometricElementFilesTable = ({
    geometricElement, handleOpenAddGeometricElementFile, handleOpenDeleteGeometricElementFile, files, ...props
}) => {

    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [filesGalleryModalVisible, setFilesGalleryModalVisible] = useState(false);

    let renderActionButtons = () => {
        if (props.authenticatedUser) {
            if ((files.filter(file => file.contentType.startsWith("image/")).length &&
                props.canShowFilesGallery)
                ||
                (props.authenticatedUser.userRoleDto.code === ADMIN_ROLE ||
                    (geometricElement.isOwnerUser))) {
                return (
                    <div className="text-right">
                        {
                            files.filter(file => file.contentType.startsWith("image/")).length &&
                                props.canShowFilesGallery ?
                                <span id={"showGallery" + geometricElement.id}
                                    className="actionButton btn-link mx-1"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title={props.intl.formatMessage({ id: "project.common.viewFiles" })}
                                    onClick={() => {
                                        setFilesGalleryModalVisible(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faImages} />
                                </span>
                                : ""
                        }
                        {
                            props.authenticatedUser.userRoleDto.code === ADMIN_ROLE ||
                                (geometricElement.isOwnerUser) ?
                                <span id={"addFiles" + geometricElement.id}
                                    className="actionButton btn-link mx-1"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title={props.intl.formatMessage({ id: "project.common.addFilesButton" })}
                                    onClick={() => {
                                        handleOpenAddGeometricElementFile();
                                    }}

                                >
                                    <FontAwesomeIcon icon={faFileUpload} />
                                </span>
                                : ""
                        }
                    </div>
                )
            } else {
                return null
            }
        } else {
            if (files.filter(file => file.contentType.startsWith("image/")).length &&
                props.canShowFilesGallery) {
                return (
                    <div className="text-right">
                        <span id={"showGallery" + geometricElement.id}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer"
                            }}
                            data-toggle="tooltip"
                            data-placement="left"
                            title={props.intl.formatMessage({ id: "project.common.viewFiles" })}
                            onClick={() => {
                                setFilesGalleryModalVisible(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faImages} />
                        </span>
                    </div>
                )
            } else {
                return null
            }
        }
    }

    let fileColumns = [{
        dataField: 'originalFilename',
        text: props.intl.formatMessage({ id: "project.elements.files.filename" }),
        headerAlign: 'left',
        sort: true,
        formatter: (cellContent, row) => (
            <span>{cellContent === null ? "NULL" : cellContent}</span>
        )
    }, {
        dataField: 'format',
        text: props.intl.formatMessage({ id: "project.elements.files.format" }),
        headerAlign: 'left',
        sort: true
    }, {
        dataField: 'size',
        text: props.intl.formatMessage({ id: "project.elements.files.size" }),
        headerAlign: 'left',
        sort: true,
        formatter: (cellContent, row) => (
            cellContent >= 1024 * 1024 ? props.intl.formatNumber(cellContent / (1024 * 1024), { style: 'decimal', maximumFractionDigits: 2 }) + ' MB'
                :
                cellContent >= 1024 ?
                    props.intl.formatNumber(cellContent / 1024, { style: 'decimal', maximumFractionDigits: 2 }) + ' KB'
                    :
                    props.intl.formatNumber(cellContent, { style: 'decimal', maximumFractionDigits: 2 }) + ' bytes'
        )
    }, {
        dataField: 'action',
        isDummyField: true,
        text: props.intl.formatMessage({ id: "project.common.action" }),
        headerAlign: 'left',
        formatter: (cellContent, row, rowIndex) => (
            <div className="text-left">
                <span
                    id={'downloadFile' + rowIndex}
                    className="actionButton btn-link"
                    style={{
                        cursor: "pointer",
                        marginLeft: "0.5em"
                    }}
                    onClick={() => {
                        axios({
                            url: row.fileUrl,
                            method: 'GET',
                            responseType: 'blob'
                        }).then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', row.originalFilename);
                            document.body.appendChild(link);
                            link.click();
                        }).catch(error => {
                            this.setState({ errorModalVisible: true });
                        });
                    }}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={props.intl.formatMessage({ id: "project.common.download" })}
                >
                    <FontAwesomeIcon icon={faDownload} />
                </span>
                {props.authenticatedUser ?
                    props.authenticatedUser.userRoleDto.code === ADMIN_ROLE ||
                        (geometricElement.isOwnerUser) ?
                        <span id={'deleteFile' + rowIndex}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            onClick={() => {
                                handleOpenDeleteGeometricElementFile(row.originalFilename, row.uuid)
                            }}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={props.intl.formatMessage({ id: "project.common.deleteFile" })}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        : ""
                    : ""
                }
            </div>
        )
    }]

    if (props.authenticatedUser && props.authenticatedUser.userRoleDto.code === ADMIN_ROLE) {
        fileColumns.splice(0, 0, {
            dataField: 'uuid',
            text: 'UUID',
            sort: true,
            headerAlign: 'left'
        })
        fileColumns.splice(3, 0, {
            dataField: 'contentType',
            text: props.intl.formatMessage({ id: 'project.elements.files.type' }),
            headerAlign: 'left',
            sort: true
        })
    }

    return (
        <div className="card">
            <h5 className="card-header text-left">
                <FormattedMessage id="project.elements.files" />
            </h5>
            <div className="card-body">
                {renderActionButtons()}
                <BootstrapTable
                    bootstrap4
                    keyField="uuid"
                    columns={fileColumns}
                    data={files}
                    noDataIndication={props.intl.formatMessage({ id: "project.elements.files.noFiles" })}
                    striped
                    condensed
                    emp
                    rowClasses="text-left"
                    pagination={
                        paginationFactory({
                            showTotal: true,
                            alwaysShowAllBtns: true,
                            hidePageListOnlyOnePage: true,
                            paginationTotalRenderer: (from, to, size) => {
                                return <FormattedMessage id="project.common.tables.totalElements"
                                    values={{
                                        from: from,
                                        to: to,
                                        total: size
                                    }
                                    }
                                />
                            },
                            sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                <li
                                    key={text}
                                    role="presentation"
                                    className={`dropdown-item ${"sizePerPage" === Number(text) ? "active" : ""}`}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        onSizePerPageChange(page);
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    {text}
                                </li>
                            ),
                            nextPageTitle: props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                            prePageTitle: props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                            lastPageTitle: props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                            firstPageTitle: props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' })
                        })
                    }
                />
            </div>
            {/* Modal download file error */}
            <Modal show={errorModalVisible} onHide={() => setErrorModalVisible(false)}>
                <Modal.Header closeButton>
                    <ModalTitle><FormattedMessage id="project.elements.files.fileNoExistsHeader" /></ModalTitle>
                </Modal.Header>
                <ModalBody>
                    <FormattedMessage id="project.elements.files.fileNoExistsBody" />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={() => setErrorModalVisible(false)}>
                        <FormattedMessage id="project.common.close" />
                    </button>
                </ModalFooter>
            </Modal>
            {props.canShowFilesGallery ?
                <GeometricElementFileGallery
                    geometricElementFiles={files}
                    modalShow={filesGalleryModalVisible}
                    hideModalWindow={() => { setFilesGalleryModalVisible(false) }}
                />
                :
                ""
            }
            {/* Photos gallery */}

        </div>
    )
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementFilesTable)));