import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import * as userSelectors from '../../users/selectors';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as actions from '../actions';
import { faEdit, faQuestionCircle, faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jQuery from 'jquery';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { DeleteDialog } from '../../common';
import AddModifyGeometricElementJoin from './AddModifyGeometricElementJoin';

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        geometricElementJoin: selectors.getGeometricElementJoins(state),
        geometricElement: selectors.getGeometricElement(state)
    });
}

class GeometricElementJoinTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'modificationDate',
            currentSortOrder: 'desc',
            deleteGeometricElementJoinModalShow: false,
            deleteGeometricElementDialogShow: false,
            addUpdateGeometricElementJoinModalShow: false,
            deleteGeometricElementJoinId: null,
            geometricElementJoinToModify: null,
            backendErrors: null,
            userCanJoin: this.props.geometricElement.userCanJoin
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });

        jQuery(function () {
            //show tooltip
            jQuery('th sup.helpTooltip').tooltip({
                html: true,
                placement: 'right',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['right'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('th sup.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
            //Prevent clicking on help button activates parent
            jQuery('th sup.helpTooltip').on('click tap', function (event) {
                event.stopPropagation();
            })
        });
    }

    render() {

        let setBackendErrors = backendErrors => {
            this.setState({ backendErrors });
        }

        let handleTableChange = (page, sizePerPage, sortField, sortOrder) => {
            let pageToSearch = page > 0 ? page - 1 : 0;
            if (this.props.authenticatedUser) {
                if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                    this.props.dispatch(actions.findAdminGeometricElementJoin(
                        this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                        sortOrder))
                }
                else {
                    this.props.dispatch(actions.findUserGeometricElementJoin(
                        this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                        sortOrder))
                }
            } else {
                this.props.dispatch(actions.findPublicGeometricElementJoin(
                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                    sortOrder))
            }
            this.setState({
                currentPage: page,
                currentSize: sizePerPage,
                currentSortField: sortField,
                currentSortOrder: sortOrder
            });
        }

        let columns = [{
            dataField: 'authorUserAccount.id',
            text: this.props.intl.formatMessage({ id: "project.elements.join.user" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                this.props.authenticatedUser && this.props.authenticatedUser.userRoleDto.code === "ADMIN" ?
                    <span
                        onClick={() => {
                            this.props.history.push({
                                pathname: `/admin/users`,
                                state: {
                                    idFilter: row.userAccountId
                                }
                            })
                        }}
                        className="btn btn-link"
                    >{row.userAccountId}</span>
                    :
                    row.userAccountId
            )
        }, {
            dataField: 'comments',
            text: this.props.intl.formatMessage({ id: "project.elements.join.comments" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                cellContent ? cellContent : "-"
            )
        }, {
            dataField: 'modificationDate',
            text: this.props.intl.formatMessage({ id: "project.elements.modificationDate" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                <span>
                    <FormattedDate
                        value={cellContent}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                    />&nbsp;
                    <FormattedTime
                        value={cellContent}
                    />
                </span>
            )
        }, {
            dataField: 'action',
            isDummyField: true,
            text: this.props.intl.formatMessage({ id: "project.common.action" }),
            headerAlign: 'left',
            formatter: (cellContent, row) => (
                <div className="text-left">
                    {this.props.authenticatedUser ?
                        this.props.authenticatedUser.userRoleDto.code === "ADMIN" ||
                            (this.props.authenticatedUser.id === row.userAccountId) ?
                            <span
                                onClick={() => {
                                    this.setState({
                                        geometricElementJoinToModify: row,
                                        backendErrors: null,
                                        addUpdateGeometricElementJoinModalShow: true
                                    });
                                }
                                }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modifyGeometricElementJoin" + row.id}
                                data-toggle="tooltip"
                                data-placement="left"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            :
                            ""
                        : ""
                    }
                    {this.props.authenticatedUser ?
                        this.props.authenticatedUser.userRoleDto.code === "ADMIN" ||
                            (this.props.authenticatedUser.id === row.userAccountId) ?
                            <span
                                onClick={() => {
                                    this.setState({
                                        deleteGeometricElementJoinId: row.id,
                                        backendErrors: null,
                                        deleteGeometricElementJoinModalShow: true
                                    });
                                }
                                }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"deleteGeometricElementJoin" + row.id}
                                data-toggle="tooltip"
                                data-placement="left"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                            :
                            ""
                        : ""
                    }
                </div>
            )
        },
        ];

        if (this.props.authenticatedUser) {
            if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                columns.splice(0, 0, {
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left'
                })
            }
            columns.splice(this.props.authenticatedUser.userRoleDto.code === "ADMIN" ? 3 : 2, 0, {
                dataField: 'isPublic',
                text: this.props.intl.formatMessage({ id: "project.elements.join.isPublic" }),
                headerFormatter: (column, colIndex, { sortElement }) => (
                    <>
                        {column.text}
                        &nbsp;
                        <sup id="isPublicHelp"
                            className="helpTooltip btn-link"
                            style={{ cursor: "pointer" }}
                            aria-hidden={true}
                            data-toggle="tooltip"
                            data-placement="left"
                            data-original-title={
                                this.props.intl.formatMessage({
                                    id: this.props.authenticatedUser.userRoleDto.code === "ADMIN" ?
                                        "project.elements.join.isPublic.help.admin"
                                        :
                                        "project.elements.join.isPublic.help.user"
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </sup>
                        {sortElement}
                    </>
                ),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    row.public ? <span><FormattedMessage id="project.common.yes" /></span> :
                        <span><FormattedMessage id="project.common.no" /></span>
                )
            });
            columns.splice(this.props.authenticatedUser.userRoleDto.code === "ADMIN" ? 4 : 3, 0, {
                dataField: 'isReviewed',
                text: this.props.intl.formatMessage({ id: "project.elements.join.isReviewed" }),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    row.reviewed ? <span><FormattedMessage id="project.common.yes" /></span> :
                        <span><FormattedMessage id="project.common.no" /></span>
                )
            });
        }

        return (
            <div id="geometricElementJoinsTable" className={this.props.className ? `${this.props.className} card` : "card"}>
                <h5 className="card-header text-left">
                    <InternationalizationRender value={this.props.geometricElement.geometricElementTypeDto.joinTableText} />
                </h5>
                <div className="card-body">
                    {this.state.userCanJoin &&
                        <div className="text-right">
                            <span id={"addGeometricElementJoin" + this.props.match.params.id}
                                onClick={() => {
                                    this.setState({
                                        updateGeometricElementJoin: null,
                                        backendErrors: null,
                                        addUpdateGeometricElementJoinModalShow: true
                                    });
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                className="actionButton btn-link mx-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                title={getInternationalization(this.props.language,
                                    this.props.geometricElement.geometricElementTypeDto.joinTooltipText,
                                    this.props.allCodes, this.props.activeLanguages)
                                }
                            >
                                <FontAwesomeIcon icon={faUserPlus} />
                            </span>
                        </div>
                    }
                    <BootstrapTable
                        key={
                            this.props.geometricElement.id
                        }
                        bootstrap4
                        keyField="id"
                        columns={columns}
                        data={this.props.geometricElementJoin ? this.props.geometricElementJoin.result.items : []}
                        defaultSorted={[{ dataField: 'modificationDate', order: 'desc' }]}
                        noDataIndication={this.props.geometricElementJoin ?
                            this.props.intl.formatMessage({ id: "project.elements.join.noJoin" })
                            :
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        striped
                        condensed
                        loading={true}
                        remote
                        rowClasses="text-left"
                        onTableChange={(type, { page, sizePerPage, sortField, sortOrder }) => {
                            handleTableChange(page, sizePerPage, sortField, sortOrder);
                        }}
                        pagination={
                            paginationFactory({
                                page: this.state.currentPage,
                                sizePerPage: this.state.currentSize,
                                showTotal: true,
                                hidePageListOnlyOnePage: true,
                                totalSize: this.props.geometricElementJoin ?
                                    this.props.geometricElementJoin.result.totalItems : 0,
                                paginationTotalRenderer: (from, to, size) => {
                                    return <FormattedMessage id="project.common.tables.totalElements"
                                        values={{
                                            from: from,
                                            to: to,
                                            total: size
                                        }
                                        }
                                    />
                                },
                                sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                    <li
                                        key={text}
                                        role="presentation"
                                        className={`dropdown-item ${this.state.currentSize === Number(text) ? "active" : ""}`}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            onSizePerPageChange(page);
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {text}
                                    </li>
                                ),
                                nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' })
                            })
                        }
                    />
                </div>
                {/* Join geometric elements dialog */}
                <AddModifyGeometricElementJoin
                    modalShow={this.state.addUpdateGeometricElementJoinModalShow}
                    geometricElement={this.props.geometricElement}
                    handleSubmit={() => {
                        this.setState({ addUpdateGeometricElementJoinModalShow: false });
                        handleTableChange(this.state.currentPage, this.state.currentSize,
                            this.state.currentSortField, this.state.currentSortOrder);
                    }}
                    geometricElementJoinToModify={this.state.geometricElementJoinToModify}
                    backendErrors={this.state.backendErrors}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => this.setState({ addUpdateGeometricElementJoinModalShow: false })}
                />
                {/* Delete geometric element dialog */}
                <DeleteDialog
                    modalShow={this.state.deleteGeometricElementJoinModalShow}
                    title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                    details={this.props.intl.formatMessage(
                        { id: 'project.elements.join.delete.message' },
                        {
                            join: <b>{this.state.deleteGeometricElementJoinId}</b>
                        })}
                    backendErrors={this.state.backendErrors}
                    hideModalWindow={() => { this.setState({ deleteGeometricElementJoinModalShow: false }) }}
                    handleSubmit={() => {
                        this.props.dispatch(actions.deleteGeometricElementJoin(this.state.deleteGeometricElementJoinId,
                            () => {
                                let currentPage = this.state.currentPage;
                                if (this.props.geometricElementJoin.result.items.length === 1 && currentPage > 0) {
                                    currentPage = currentPage - 1;
                                }
                                handleTableChange(currentPage, this.state.currentSize,
                                    this.state.currentSortField, this.state.currentSortOrder);
                                this.setState({ deleteGeometricElementJoinModalShow: false });
                                if (this.props.authenticatedUser && this.props.authenticatedUser.userRoleDto.code === "USER") {
                                    this.setState({ userCanJoin: true });
                                }
                            },
                            (error) => {
                                setBackendErrors(error);
                            }))
                    }}
                    setBackendErrors={setBackendErrors}
                />
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementJoinTable)));