import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import * as FilePond from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import * as selectors from '../selectors';
import * as geometricElementSelectors from '../../geometricElements/selectors';
import * as appSelectors from '../../app/selectors';
import { connect } from 'react-redux';
import { getInternationalization } from '../../app/components/InternationalizationRender';

const mapStateToProps = function (state) {
    return ({
        allFileFormat: selectors.getAllFileFormat(state),
        allCodes: geometricElementSelectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state)
    });
}

export const validateGeneralAttachedFileField = function (mustValidate, mustBeImage, allFileFormat, values) {
    let file = values.file;

    if (!mustValidate) {
        return;
    }

    if (!file) {
        return {
            file: <FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.attachedFiles.add.file" />,
                }}
            />
        }
    }

    let fileContentType = Array.isArray(file) ? file[0].type : file.type;
    let fileFormat = allFileFormat.filter(fileFormat =>
        fileFormat.mainMimeType === fileContentType || (fileFormat.additionalMimeType && fileFormat.additionalMimeType.includes(
            fileContentType
        )));

    if (!fileFormat.length) {
        return {
            file: <FormattedMessage
                id="project.attachedFiles.errors.formatNotPermitted"
                values={{
                    filename: <em>{Array.isArray(file) ? file[0].name : file.filename}</em>
                }}
            />
        };
    }

    fileFormat = fileFormat[0];

    if (mustBeImage && !fileFormat.image) {
        return {
            file: <FormattedMessage
                id="project.attachedFiles.errors.mustBeImage"
                values={{
                    filename: <em>{Array.isArray(file) ? file[0].name : file.filename}</em>
                }}
            />
        };
    }

    if (!fileFormat.allowedForGeneralAttachments) {
        return {
            file: <FormattedMessage
                id="project.attachedFiles.errors.formatNotPermitted"
                values={{
                    filename: <em>{Array.isArray(file) ? file[0].name : file.filename}</em>
                }}
            />
        };
    }

    if (Array.isArray(file) ? file[0].size > fileFormat.generalMaxSize : file.size > fileFormat.generalMaxSize) {
        let maxSize = fileFormat.generalMaxSize;
        return {
            file: <FormattedMessage
                id="project.attachedFiles.errors.maxSize"
                values={{
                    //eslint-disable-next-line
                    maxSize: maxSize >= 1024 * 1024 ? <FormattedNumber value={maxSize / (1024 * 1024)} style='unit' unit="megabyte" />
                        :
                        maxSize >= 1024 ?
                            //eslint-disable-next-line
                            <FormattedNumber value={maxSize / 1024} style="unit" unit="kilobyte" />
                            :
                            //eslint-disable-next-line
                            <FormattedNumber value={maxSize} style="unit" unit="byte" />,
                    filename: <em>{Array.isArray(file) ? file[0].name : file.filename}</em>
                }}
            />
        };
    }
}

const AddModifyGeneralAttachedFileFormFields = ({ mustBeImage, showTypeField, setFieldError, setFieldValue, errors, setHasAddedFiles, resetValues, files, isLegendFile, ...props }) => {
    if (isLegendFile) {
        FilePond.registerPlugin(FilePondPluginFileEncode);
    }

    let typeSelectorRender = () => { }
    if (showTypeField) {
        typeSelectorRender = () => {
            if (props.allCodes) {
                let generalAttachedFileTypeList = props.allCodes.filter(code => code.codeGroup.groupCode
                    .indexOf('GENERAL_ATTACHED_FILE_TYPE') !== -1);
                return (
                    generalAttachedFileTypeList.map(generalAttachedFileType =>
                        <option value={generalAttachedFileType.code} key={generalAttachedFileType.code}>
                            {getInternationalization(props.language, generalAttachedFileType.code, generalAttachedFileTypeList, props.activeLanguages)}
                        </option>
                    )
                );
            } else {
                return (null)
            }
        }
    }

    return (
        <>
            <div className="form-group">
                <label htmlFor="description" className="font-weight-bold required">
                    <FormattedMessage id="project.attachedFiles.description" />
                </label>
                <Field as="textarea" name="description" className="form-control"
                    id="description" rows="3" />
            </div>
            <ErrorMessage name="description" render={(msg) =>
                <div id='descriptionError' className="alert alert-danger alert-dismissible fade show" role="alert"
                    hidden={typeof errors.description === "undefined"}
                >
                    <button type="button" className="close" aria-label="Close"
                        onClick={() => setFieldError("description", undefined)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {msg}
                </div>
            }
            />

            {
                showTypeField &&
                <div className="form-group">
                    <label htmlFor="type" className="font-weight-bold">
                        <FormattedMessage id="project.attachedFiles.type" />
                    </label>
                    <Field as="select" id="type" name="type" className="form-control">
                        <FormattedMessage id="project.common.selectOneOption">
                            {(msg) => <option value="">{msg}</option>}
                        </FormattedMessage>
                        {typeSelectorRender()}
                    </Field>
                </div>
            }

            <div className="form-group">
                <label className="font-weight-bold required" htmlFor="file">
                    <FormattedMessage id="project.attachedFiles.add.file" />
                </label>
                <FilePond.FilePond
                    id="file"
                    instantUpload={false}
                    allowMultiple={false}
                    labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                    labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                    labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                    labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                    onupdatefiles={fileItems => {
                        if (!isLegendFile) {
                            setFieldValue("file", fileItems.map(fileItem => fileItem.file));
                        } else {
                            setFieldValue("files", fileItems.map(fileItem => fileItem.file));
                        }
                    }}
                    onremovefile={(error, file) => {
                        setHasAddedFiles(false);
                        if (isLegendFile) {
                            setFieldValue("file", null);
                            setFieldValue("files", [])
                        }
                    }}
                    onaddfile={(error, file) => {
                        setHasAddedFiles(true);
                        if (isLegendFile) {
                            setFieldValue("file", {
                                filename: file.filename,
                                size: file.fileSize,
                                type: file.fileType,
                                base64: file.getFileEncodeBase64String()
                            })
                        }
                    }}
                    files={files}
                />
            </div>
            <ErrorMessage name="file" render={(msg) =>
                <div id='fileError' className="alert alert-danger alert-dismissible fade show" role="alert"
                    hidden={typeof errors.file === "undefined"}
                >
                    <button type="button" className="close" aria-label="Close"
                        onClick={() => setFieldError("file", undefined)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {msg}
                </div>
            }
            />
        </>
    )
}

export default connect(mapStateToProps)(injectIntl(AddModifyGeneralAttachedFileFormFields));