import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import { withRouter } from 'react-router-dom';
import InternationalizationRender, { getInternationalization, hasHelp } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import UpdateConfigurationParameter from './UpdateConfigurationParameter';
import { DeleteDialog } from '../../common';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        user: userSelectors.getUser(state),
        allCodes: geometricElementsSelector.getAllCodes(state),
        totalConfigurationParameters: selectors.getTotalConfigurationParameters(state),
    }
}

class AllConfigurationParameters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalConfigurationParameters: null,
            sizePerPage: 10,
            filters: {},
            backendErrors: null,
            updateConfigurationParameterModalShow: false,
            configurationParameterToUpdate: null,
            deleteConfigurationParameterModalShow: false,
            isAllCodesChanged: 0
        }
    }

    componentDidMount() {
        this.setState({ totalConfigurationParameters: this.props.totalConfigurationParameters });
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
        if (!this.state.totalConfigurationParameters && this.props.totalConfigurationParameters) {
            this.setState({ totalConfigurationParameters: this.props.totalConfigurationParameters });
        }
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        let formatBooleanDataType = value => {
            if (value) {
                if (value === "true") {
                    return <FormattedMessage id="project.common.yes" />
                } else {
                    return <FormattedMessage id="project.common.no" />
                }
            } else {
                return <FormattedMessage id="project.common.no" />
            }
        }

        let clearAllFilters = (configurationParameterId) => {
            let { idFilter, nameFilter, codeFilter, dataTypeFilter, valueFilter, userInterfaceFilter } = this.state.filters;
            if (configurationParameterId) {
                idFilter(`${configurationParameterId}`);
            } else {
                idFilter('');
            }
            nameFilter('');
            codeFilter('');
            dataTypeFilter('');
            valueFilter('');
            userInterfaceFilter('');
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN" && this.props.allCodes) {

                jQuery(function () {
                    jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip().click(function () {
                        jQuery('.actionButton').tooltip("hide");
                    });
                });

                jQuery(function () {
                    jQuery('.valueTooltip').tooltip({
                        html: true,
                        placement: 'left',
                        trigger: 'click',
                        // Prevent placement flip
                        fallbackPlacement: ['left'],
                        // Show tables and custom styles inside tooltip
                        sanitize: false,
                        boundary: 'window',
                        template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                            '</div><div class="help-tooltip tooltip-inner"></div></div>'
                    });

                    //hide it when clicking anywhere else
                    jQuery('body').on('click', function (e) {
                        jQuery('.valueTooltip').each(function () {
                            //the 'is' for buttons that trigger popups
                            //the 'has' for icons within a button that triggers a popup
                            if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                                jQuery(this).tooltip('hide');
                            }
                        });
                    });
                });

                let dataTypeSelectOptions = [];
                let allDataType = this.props.allCodes.filter(code => code.codeGroup.groupCode.indexOf('DATA_TYPE') !== -1);
                allDataType.forEach((dataType, index) => {
                    dataTypeSelectOptions[index] = {
                        value: dataType.code,
                        label: getInternationalization(this.props.language, dataType.code, this.props.allCodes, this.props.activeLanguages)
                    }
                });
                let isUserInterfaceSelectOptions = [{
                    value: true,
                    label: this.props.intl.formatMessage({ id: 'project.common.yes' })
                }, {
                    value: false,
                    label: this.props.intl.formatMessage({ id: 'project.common.no' })
                }];

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        }
                    })
                },
                {
                    dataField: 'description',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={row.code} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.name" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.nameFilter = filter;
                                return { filters }
                            })
                        }
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code, this.props.allCodes, this.props.activeLanguages)
                },
                {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.code" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.code" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.codeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'attributeType.dataType',
                    text: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.attributes.dataType" }).toLocaleLowerCase() }),
                        options: dataTypeSelectOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.dataTypeFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'value',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.value" }),
                    headerAlign: 'left',
                    formatExtraData: this.state.isAllCodesChanged,
                    formatter: (cellContent, row, a, formatExtraData) => {
                        if (cellContent) {
                            switch (row.attributeType.dataType) {
                                case "IMAGE":
                                    return (
                                        <img src={`${cellContent}`} className="img-thumbnail" alt={""} />
                                    );
                                case "BOOLEAN_CHECK":
                                    return (
                                        formatBooleanDataType(cellContent)
                                    );
                                case "COLOR":
                                    return (
                                        <>
                                            <div className='d-inline-block' style={{
                                                borderWidth: '1px',
                                                borderColor: 'black',
                                                backgroundColor: cellContent,
                                                width: '1em',
                                                height: '1em'
                                            }} />
                                            &nbsp;
                                            <span className='d-inline-block'>{cellContent}</span>
                                        </>
                                    )
                                case "LANGUAGE":
                                    let splittedLanguage = cellContent.split(",");
                                    return splittedLanguage.map((language, index, array) => {
                                        if (index < array.length - 1) {
                                            return (
                                                <>
                                                    <InternationalizationRender value={language} />,&nbsp;
                                                </>
                                            )
                                        }

                                        return <InternationalizationRender value={language} />
                                    })
                                case "SELECT":
                                    return <InternationalizationRender value={cellContent} />
                                default:
                                    return (cellContent);
                            }
                        } else {
                            if (row.attributeType.dataType === "INTERNATIONALIZATED_TEXT") {
                                return this.props.activeLanguages.map(language => {
                                    let splittedLanguage = language.split("-")[0].toLowerCase();
                                    return (
                                        <>
                                            <span className='d-block font-weight-bold'>
                                                <InternationalizationRender value={language} />
                                            </span>
                                            <span className='d-block' key={formatExtraData + splittedLanguage}>
                                                {getInternationalization(splittedLanguage, `${row.code}_VALUE`, this.props.allCodes, this.props.activeLanguages) || '-'}
                                            </span>
                                        </>
                                    )
                                })
                            }
                            return "-"
                        }
                    },
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.value" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.valueFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'userInterface',
                    text: this.props.intl.formatMessage({ id: "project.configurationParameter.userInterface" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> : <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.configurationParameter.userInterface" }).toLocaleLowerCase() }),
                        options: isUserInterfaceSelectOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.userInterfaceFilter = filter;
                                return { filters }
                            })
                        }
                    })
                },
                {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: this.props.language,
                    // It's necessary to pass the language as formatExtraData 
                    // to rerender column when user changes language.
                    formatter: (cellContent, row, rowIndex, formatExtraData) => (
                        <div className="text-left">
                            <span className="btn-link actionButton"
                                onClick={() =>
                                    this.setState({
                                        backendErrors: null,
                                        updateConfigurationParameterModalShow: true,
                                        configurationParameterToUpdate: row
                                    })
                                }
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            {!row.mandatory && row.value && row.value !== "" ?
                                <span className="btn-link actionButton"
                                    onClick={() =>
                                        this.setState({
                                            backendErrors: null,
                                            deleteConfigurationParameterModalShow: true,
                                            configurationParameterToUpdate: row
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"delete" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.configurationParameter.delete" })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                                : ""
                            }
                            {row.attributeType.dataType === "FORMATTED_TEXT" && row.value && row.value !== "" ?
                                <span className="btn-link valueTooltip"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"value" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-html={true}
                                    title={row.value}
                                    data-original-title={row.value}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </span>
                                : ""
                            }
                            {hasHelp(formatExtraData, row.attributeType.helpCode) ?
                                <span className="btn-link valueTooltip"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"help" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-html={true}
                                    title={getInternationalization(this.props.language, row.attributeType.helpCode.code, this.props.allCodes)}
                                    data-original-title={getInternationalization(this.props.language, row.attributeType.helpCode.code, this.props.allCodes, this.props.activeLanguages, this.props.activeLanguages)}
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </span>
                                : ""
                            }
                        </div>
                    )
                },
                ];

                return (

                    <div className="card card-body">
                        <br />
                        <h3><FormattedMessage id="project.app.Header.admin.configurationParameters" /></h3>

                        {/* Delete configuration parameter value dialog */}
                        <DeleteDialog
                            backendErrors={this.state.backendErrors}
                            modalShow={this.state.deleteConfigurationParameterModalShow}
                            hideModalWindow={() => { this.setState({ deleteConfigurationParameterModalShow: false }) }}
                            title={this.props.intl.formatMessage({ id: 'project.configurationParameter.delete' })}
                            details={this.props.intl.formatMessage(
                                { id: 'project.configurationParameter.delete.message' },
                                {
                                    configurationParameter:
                                        <b>
                                            {getInternationalization(
                                                this.props.language,
                                                this.state.configurationParameterToUpdate ? this.state.configurationParameterToUpdate.code : "",
                                                this.props.allCodes,
                                                this.props.activeLanguages
                                            )}
                                        </b>
                                })
                            }
                            setBackendErrors={setBackendErrors}
                            handleSubmit={() => {
                                let json = {
                                    value: null,
                                    mimeType: null,
                                    filename: null
                                }
                                this.props.dispatch(actions.updateConfigurationParameter(this.state.configurationParameterToUpdate.id, json,
                                    () => {
                                        this.setState({
                                            deleteConfigurationParameterModalShow: false,
                                            totalConfigurationParameters: this.props.totalConfigurationParameters
                                        });
                                        if (this.state.configurationParameterToUpdate) {
                                            clearAllFilters(this.state.configurationParameterToUpdate.id);
                                        } else {
                                            clearAllFilters();
                                        }
                                    },
                                    errors => {
                                        setBackendErrors(errors)
                                    }));
                            }}
                        />

                        {/* Update configuration parameter dialog */}
                        <UpdateConfigurationParameter
                            modalShow={this.state.updateConfigurationParameterModalShow}
                            configurationParameterToUpdate={this.state.configurationParameterToUpdate}
                            backendErrors={this.state.backendErrors}
                            setBackendErrors={setBackendErrors}
                            handleSubmit={() => {
                                this.setState({
                                    updateConfigurationParameterModalShow: false,
                                    totalConfigurationParameters: this.props.totalConfigurationParameters
                                });
                                if (this.state.configurationParameterToUpdate.attributeType.dataType === "INTERNATIONALIZATED_TEXT") {
                                    this.setState(previousState => ({ isAllCodesChanged: previousState.isAllCodesChanged + 1 }));
                                }
                                if (this.state.configurationParameterToUpdate) {
                                    clearAllFilters(this.state.configurationParameterToUpdate.id);
                                } else {
                                    clearAllFilters();
                                }
                            }}
                            hideModalWindow={() => this.setState({
                                configurationParameterToUpdate: null,
                                updateConfigurationParameterModalShow: false
                            })}
                        />

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            columns={columns}
                            data={this.state.totalConfigurationParameters ?
                                this.state.totalConfigurationParameters : []
                            }
                            noDataIndication={this.state.totalConfigurationParameters ?
                                this.props.intl.formatMessage({ id: "project.configurationParameter.noDataIndication" }) :
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                            defaultSorted={[{
                                dataField: 'id',
                                order: 'asc'
                            }]}
                            rowClasses="text-left"
                            filter={filterFactory()}
                            filterPosition={"top"}
                            condensed
                            striped
                            pagination={paginationFactory({
                                sizePerPage: this.state.currentSize,
                                showTotal: true,
                                alwaysShowAllBtns: true,
                                hidePageListOnlyOnePage: true,
                                paginationTotalRenderer: (from, to, size) => {
                                    return <FormattedMessage id="project.common.tables.totalElements"
                                        values={{
                                            from: from,
                                            to: to,
                                            total: size
                                        }
                                        }
                                    />
                                },
                                onSizePerPageChange: (sizePerPage, page) => {
                                    this.setState({ sizePerPage: sizePerPage })
                                },
                                sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                    <li
                                        key={text}
                                        role="presentation"
                                        className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            onSizePerPageChange(page);
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {text}
                                    </li>
                                ),
                                nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                            })}
                        />
                        <br />
                    </div>
                );
            }

            if (this.props.user && this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }
            return (
                null
            );
        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllConfigurationParameters)));