import React from 'react';
import * as userSelectors from '../../users/selectors'
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import InternationalizationRender, { PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_ELEMENT_TEXT_SINGULAR } from '../../app/components/InternationalizationRender';

const mapStateToProps = function (state) {
    return ({
        user: userSelectors.getUser(state)
    });
}

const Schema = Yup.object().shape({
    geometricElement1: Yup.number().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.relations.geometricElement1" values={{
                singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
            }} />,
        }} />),
    geometricElement2: Yup.number().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.relations.geometricElement2" values={{
                singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
            }} />,
        }} />)
})

class AddModifyGeometricElementRelation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricElementRelationToModify !== this.props.geometricElementRelationToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors !== null || this.props.backendErrors !== null) {
            let container = jQuery('.modal-body');
            if (container.length > 0) {
                if (this.props.backendErrors) {
                    container.scrollTop(0);
                } else {
                    if (this.state.formikErrors) {
                        let field = jQuery('.alert:visible:first').prev();
                        if (field.length)
                            jQuery(container).animate({
                                scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                            });
                    }
                }
            }
        }
    }

    render() {
        if (this.props.user) {
            let handleResetFormAndHideModalWindow = (resetForm) => {
                this.props.hideModalWindow();
                resetForm()
            }
            let initialValues = {}
            if (this.props.geometricElementRelationToModify) {
                initialValues = {
                    isPublicRelation: this.props.geometricElementRelationToModify.isPublic,
                    isReviewedRelation: this.props.geometricElementRelationToModify.isReviewed,
                    comments: this.props.geometricElementRelationToModify.comments,
                    geometricElement1: this.props.geometricElementRelationToModify.geometricElement1.id,
                    geometricElement2: this.props.geometricElementRelationToModify.geometricElement2.id
                }
            } else {
                initialValues = {
                    isPublicRelation: true,
                    isReviewedRelation: false,
                    comments: "",
                    geometricElement1: this.props.geometricElement ? this.props.geometricElement.id : "",
                    geometricElement2: ""
                }
            }

            return (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.props.setBackendErrors(null);
                        let geometricElementRelationForInsert = {
                            isPublic: values.isPublicRelation,
                            isReviewed: values.isReviewedRelation,
                            comments: values.comments,
                        }
                        if (this.props.geometricElementRelationToModify) {
                            geometricElementRelationForInsert.geometricElement1 = values.geometricElement1;
                            geometricElementRelationForInsert.geometricElement2 = values.geometricElement2;

                            this.props.dispatch(actions.updateGeometricElementRelation(this.props.geometricElementRelationToModify.id, geometricElementRelationForInsert, () => {
                                resetForm();
                                this.props.handleSubmit();
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                })
                            );
                        } else {
                            if (values.geometricElement1 <= values.geometricElement2) {
                                geometricElementRelationForInsert.geometricElement1 = values.geometricElement1;
                                geometricElementRelationForInsert.geometricElement2 = values.geometricElement2;
                            } else {
                                geometricElementRelationForInsert.geometricElement1 = values.geometricElement2;
                                geometricElementRelationForInsert.geometricElement2 = values.geometricElement1;
                            }

                            this.props.dispatch(actions.addGeometricElementRelation(geometricElementRelationForInsert, () => {
                                resetForm();
                                this.props.handleSubmit();
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                })
                            );
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ errors, setFieldError, resetForm }) =>
                        <Modal show={this.props.modalShow} onHide={() => handleResetFormAndHideModalWindow(resetForm)} scrollable={true} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.props.geometricElementRelationToModify ?
                                        <FormattedMessage id="project.elements.relations.modify" values={{
                                            plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                        }} />
                                        :
                                        <FormattedMessage id="project.elements.relations.add" values={
                                            {
                                                plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                            }
                                        } />
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addModifyGeometricElementRelationForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                    <div className="form-group">
                                        <label htmlFor="geometricElement1" className="font-weight-bold">
                                            <FormattedMessage id="project.elements.relations.geometricElement1" values={{
                                                singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                            }} />
                                        </label>
                                        <Field type="number" id="geometricElement1" name="geometricElement1" className="form-control" disabled />
                                        {
                                            !this.props.geometricElementRelationToModify ?
                                                <small className="form-text text-muted">
                                                    {
                                                        this.props.geometricElement ?
                                                            this.props.geometricElement.description
                                                            : ""
                                                    }
                                                </small>
                                                : <small className="form-text text-muted">
                                                    {this.props.geometricElementRelationToModify.geometricElement1.description}
                                                </small>
                                        }
                                    </div>
                                    <ErrorMessage name="geometricElement1" render={(msg) =>
                                        <div id="geometricElement1Error" className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.geometricElement1 === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("geometricElement1", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="form-group">
                                        <label htmlFor="geometricElement2"
                                            className={this.props.geometricElementRelationToModify ? "font-weight-bold" : "font-weight-bold required"}
                                        >
                                            <FormattedMessage id="project.elements.relations.geometricElement2" values={{
                                                singular: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_SINGULAR} />
                                            }} />
                                        </label>
                                        <Field type="number" id="geometricElement2" name="geometricElement2" className="form-control"
                                            disabled={this.props.geometricElementRelationToModify ? true : false}
                                        />
                                        {
                                            this.props.geometricElementRelationToModify ?
                                                <small className="form-text text-muted">
                                                    {this.props.geometricElementRelationToModify.geometricElement2.description}
                                                </small>
                                                : ""
                                        }
                                    </div>
                                    <ErrorMessage name="geometricElement2" render={(msg) =>
                                        <div id="geometricElement2Error" className="alert alert-danger alert-dismissible fade show" role="alert"
                                            hidden={typeof errors.geometricElement2 === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("geometricElement2", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                    />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="isPublicRelation" name="isPublicRelation" />
                                            </div>
                                        </div>
                                        <label htmlFor="isPublicRelation" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.relations.isPublic" />
                                        </label>
                                    </div>

                                    {
                                        this.props.user ?
                                            this.props.user.userRoleDto.code === "ADMIN" ?
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <Field type="checkbox" id="isReviewedRelation" name="isReviewedRelation" />
                                                        </div>
                                                    </div>
                                                    <label htmlFor="isReviewedRelation" className="btn input-group-text">
                                                        <FormattedMessage id="project.elements.relations.isReviewed" />
                                                    </label>
                                                </div>
                                                : "" : ""
                                    }

                                    <div className="form-group">
                                        <label className="font-weight-bold" htmlFor="comments">
                                            <FormattedMessage id="project.elements.relations.comments" />
                                        </label>
                                        <Field as="textarea" id="comments" name="comments" className="form-control" />
                                    </div>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="addModifyGeometricElementRelationSubmit" type="submit" className="btn btn-primary"
                                    form="addModifyGeometricElementRelationForm"
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <button id="addModifyGeometricElementRelationCancel" type="button" className="btn btn-danger"
                                    onClick={() => {
                                        handleResetFormAndHideModalWindow(resetForm)
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Formik>
            );
        } else {
            return (
                <Modal show={this.props.modalShow} onClose={this.props.hideModalWindow} onHide={this.props.hideModalWindow} scrollable={true} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="project.elements.relations.add" values={
                                {
                                    plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                                }
                            } />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert alert-primary text-left">
                            <FormattedMessage id="project.elements.relations.anonymous.message"
                                values={{
                                    link: <b>{this.props.intl.formatMessage({ id: "project.app.Header.login" }).toLocaleLowerCase()}</b>
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary"
                            onClick={this.props.hideModalWindow}
                        >
                            <FormattedMessage id="project.common.close" />
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }
}

AddModifyGeometricElementRelation.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricElement: PropTypes.object.isRequired,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(injectIntl(AddModifyGeometricElementRelation));