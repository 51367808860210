export const validGeometryTypes = {

    'Line': ['ANY_GEOMETRY', 'LINE_POLYGON', 'POINT_LINE', 'LINE'],
    'Point': ['ANY_GEOMETRY', 'POINT_LINE', 'POINT_POLYGON', 'POINT'],
    'Polygon': ['ANY_GEOMETRY', 'LINE_POLYGON', 'POINT_POLYGON', 'POLYGON']

}

export const geometryByGeometryTypeIfParameterNull = {

    'ANY_GEOMETRY': 'Polygon',
    'LINE': 'LineString',
    'LINE_POLYGON': 'Polygon',
    'POINT_LINE': 'Line',
    'POINT_POLYGON': 'Polygon',
    'POINT': 'Point',
    'POLYGON': 'Polygon'

}

export const formatCreateElement = (allGeometricElementType, urlSearchParams, showError) => {

    let createElementByTypeId = urlSearchParams.get('typeId');
    let createElementByTypeCode = urlSearchParams.get('typeCode');
    let geometryType = urlSearchParams.get('geometryType');

    if (createElementByTypeId || createElementByTypeCode) {

        let geometricElementType;
        if (createElementByTypeId) {

            geometricElementType = allGeometricElementType.filter(elementType => `${elementType.id}` === `${createElementByTypeId}`);
            if (geometricElementType.length > 0) {

                geometricElementType = geometricElementType[0];

            } else {

                showError();
                return null;

            }

        } else {

            if (createElementByTypeCode) {

                geometricElementType = allGeometricElementType.filter(elementType => `${elementType.code}` === `${createElementByTypeCode}`);
                if (geometricElementType.length > 0) {

                    geometricElementType = geometricElementType[0];

                } else {

                    showError();
                    return null;

                }

            }

        }

        if (geometryType) {

            let possibleGeometryTypes = validGeometryTypes[geometryType];
            if (possibleGeometryTypes) {

                if (possibleGeometryTypes.includes(geometricElementType.geometryType)) {

                    return `${geometricElementType.id}-${geometryType === 'Line' ? 'LineString' : geometryType}`;

                } else {

                    showError();
                    return null;

                }

            } else {

                showError();
                return null;

            }

        } else {

            return `${geometricElementType.id}-${geometryByGeometryTypeIfParameterNull[geometricElementType.geometryType]}`;

        }

    } else {

        if ((!createElementByTypeCode || !createElementByTypeId) && geometryType) {

            showError();
            return null;

        }

    }

};