import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';
import { Field } from "formik";
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as configuationParameterSelectors from '../../configurationParameter/selectors'
import { GeometricElementTypeAttributeFieldErrorMessage, GeometricElementTypeAttributeFieldLabel } from '../../common/util';
import { uniqueId } from 'lodash'

const mapStateToProps = function (state) {

    return {

        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        geometricElementType: selectors.getGeometricElementType(state),
        configurationParameters: configuationParameterSelectors.getTotalConfigurationParameters(state)

    }

}


const GeometricElementContactDataField = ({ attributeType, setFieldError, errors, isPublic, configurationParameters, ...props }) => {

    const [enableGeometricElementContactDataAlert, setEnableGeometricElementContactDataAlert] = useState(false);
    const [privateGeometricElementContactDataAlert, setPrivateGeometricElementContactDataAlert] = useState(null);
    const [publicGeometricElementContactDataAlert, setPublicGeometricElementContactDataAlert] = useState(null);
    const [geometricElementContactDataAlertId] = useState(uniqueId('geometricElementContactData'));

    useEffect(() => {

        if (configurationParameters) {

            let enableGeometricElementContactDataAlertParameter = configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_CONTACT_DATA_ALERT") !== -1);

            if (enableGeometricElementContactDataAlertParameter.length > 0) {

                setEnableGeometricElementContactDataAlert(enableGeometricElementContactDataAlertParameter[0].value === 'true');

            } else {

                setEnableGeometricElementContactDataAlert(false);

            }

            let privateGeometricElementContactDataAlertParameter = configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("PRIVATE_GEOMETRIC_ELEMENT_CONTACT_DATA_ALERT") !== -1);

            if (privateGeometricElementContactDataAlertParameter.length > 0) {

                setPrivateGeometricElementContactDataAlert(privateGeometricElementContactDataAlertParameter[0].value);

            } else {

                setPrivateGeometricElementContactDataAlert(null);

            }

            let publicGeometricElementContactDataAlertParameter = configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("PUBLIC_GEOMETRIC_ELEMENT_CONTACT_DATA_ALERT") !== -1);

            if (publicGeometricElementContactDataAlertParameter.length > 0) {

                setPublicGeometricElementContactDataAlert(publicGeometricElementContactDataAlertParameter[0].value);

            } else {

                setPublicGeometricElementContactDataAlert(null);

            }

            if (enableGeometricElementContactDataAlert && isPublic && publicGeometricElementContactDataAlert) {

                jQuery(`#${geometricElementContactDataAlertId}`).html(publicGeometricElementContactDataAlert);

            }

            if (enableGeometricElementContactDataAlert && !isPublic && privateGeometricElementContactDataAlert) {

                jQuery(`#${geometricElementContactDataAlertId}`).html(privateGeometricElementContactDataAlert);

            }

        }

        // eslint-disable-next-line
    }, [isPublic, configurationParameters, enableGeometricElementContactDataAlert]);

    return (

        <div>
            <div className="form-group">
                <GeometricElementTypeAttributeFieldLabel
                    locale={props.locale}
                    attributeType={attributeType}
                    allCodes={props.allCodes}
                    activeLanguages={props.activeLanguages}
                    checkbox={false}
                />
                <Field as="textarea" className="form-control"
                    id={attributeType.attributeType.code.code}
                    name={attributeType.attributeType.code.code}
                />
                {((isPublic && enableGeometricElementContactDataAlert && privateGeometricElementContactDataAlert) ||
                    (!isPublic && enableGeometricElementContactDataAlert && publicGeometricElementContactDataAlert)) &&
                    <div id={geometricElementContactDataAlertId}
                        className="alert alert-warning mt-2"
                        role="alert"
                        style={{ maxHeight: '6.5em', overflowY: 'auto' }}
                    >
                    </div>
                }
            </div>
            <GeometricElementTypeAttributeFieldErrorMessage
                attributeType={attributeType}
                errors={errors}
                setFieldError={setFieldError}
            />
        </div>

    );
};

export default connect(mapStateToProps)(GeometricElementContactDataField)