import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { Errors } from '../../common';
import jQuery from 'jquery'
import * as Yup from 'yup';
import AddModifyGeneralAttachedFileFormFields, { validateGeneralAttachedFileField } from './AddModifyGeneralAttachedFileFormFields';

const mapStateToProps = function (state) {
    return ({
        allFileFormat: selectors.getAllFileFormat(state)
    });
}

const Schema = Yup.object().shape({
    description: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.attachedFiles.description" />,
        }} />)
})

class AddModifyGeneralAttachedFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null,
            hasAddedFiles: false
        };
        this.resetValues = {
            description: "",
            type: "",
            file: []
        }
        this.hideModalWindow = (resetForm) => {
            resetForm({
                values: this.resetValues
            })
            this.initialValues = this.resetValues;
            this.setState({
                generalAttachedFileToModify: null,
                fileErrors: null,
                hasAddedFiles: false
            });
            this.props.hideModalWindow();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }

        if (nextState.fileErrors !== this.state.fileErrors) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors || this.state.fileErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
    }

    render() {
        if (this.props.modalShow) {

            let initialValues = {
                description: "",
                file: [],
                type: ""
            }

            if (this.props.generalAttachedFileToModify) {
                initialValues = {
                    description: this.props.generalAttachedFileToModify.description,
                    file: [{
                        source: '',
                        options: {
                            type: 'local',
                            file: {
                                name: this.props.generalAttachedFileToModify.originalFilename,
                                size: this.props.generalAttachedFileToModify.size,
                                type: this.props.generalAttachedFileToModify.fileFormat.mainMimeType
                            }
                        }
                    }],
                    type: this.props.generalAttachedFileToModify.type ? this.props.generalAttachedFileToModify.type : ""
                }
            }

            return (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={Schema}
                    validate={(values) => validateGeneralAttachedFileField(true, false, this.props.allFileFormat, values)}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.props.setBackendErrors(null);
                        let formData = new FormData();
                        formData.append("description", values.description);
                        if (values.type && values.type.length) {
                            formData.append("type", values.type);
                        }
                        let file = null;
                        if (values.file[0] instanceof File) {
                            file = values.file[0]
                        } else {
                            let fileToCreate = values.file[0]
                            file = new File([], fileToCreate.name, { type: fileToCreate.type })
                        }
                        formData.append("file", file)
                        if (this.props.generalAttachedFileToModify) {
                            formData.append("id", this.props.generalAttachedFileToModify.id);
                            this.props.dispatch(actions.modifyGeneralAttachedFile(formData.get('id'), formData,
                                () => {
                                    this.initialValues = this.resetValues;
                                    resetForm({
                                        values: this.resetValues
                                    })
                                    this.setState({
                                        generalAttachedFileToModify: null,
                                        fileErrors: null,
                                        hasAddedFiles: false
                                    });
                                    this.props.handleSubmit();
                                }, (errors) => {
                                    this.props.setBackendErrors(errors);
                                }));
                        } else {
                            this.props.dispatch(actions.addGeneralAttachedFile(formData,
                                () => {
                                    this.initialValues = this.resetValues;
                                    resetForm({
                                        values: this.resetValues
                                    })
                                    this.setState({
                                        generalAttachedFileToModify: null,
                                        fileErrors: null,
                                        hasAddedFiles: false
                                    });
                                    this.props.handleSubmit();
                                }, (errors) => {
                                    this.props.setBackendErrors(errors);
                                }));
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, setFieldError, resetForm }) =>
                        <Modal show={this.props.modalShow} onHide={() => this.hideModalWindow(resetForm)} scrollable={true} size="lg" enforceFocus={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.props.generalAttachedFileToModify ?
                                        <FormattedMessage id="project.attachedFiles.modify" />
                                        :
                                        <FormattedMessage id="project.attachedFiles.add" />
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addModifyGeneralAttachedFileForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />
                                    <AddModifyGeneralAttachedFileFormFields
                                        mustBeImage={false}
                                        showTypeField={true}
                                        setFieldValue={setFieldValue}
                                        setFieldError={setFieldError}
                                        errors={errors}
                                        setHasAddedFiles={(hasAddedFiles) => this.setState({ hasAddedFiles })}
                                        files={values.file}
                                        resetValues={this.resetValues}
                                        isLayerFile={false}
                                    />
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="addModifyGeneralAttachedFileSubmit" type="submit" className="btn btn-primary"
                                    form="addModifyGeneralAttachedFileForm" disabled={!this.state.hasAddedFiles}
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <button id="createAttributeTypeCancel" type="button" className="btn btn-danger"
                                    onClick={() => {
                                        this.hideModalWindow(resetForm);
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Formik>
            );
        }

        return null;
    }

}

export default connect(mapStateToProps)(injectIntl(AddModifyGeneralAttachedFile));