import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import * as userSelectors from '../../users/selectors';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as actions from '../actions';
import { faEdit, faInfoCircle, faTrashAlt, faMapMarkerAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jQuery from 'jquery';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InternationalizationRender, { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_ELEMENT_TEXT_SINGULAR } from '../../app/components/InternationalizationRender';
import { groupBy } from 'lodash';
import AddModifyGeometricElementRelation from './AddModifyGeometricElementRelation';
import { DeleteDialog } from '../../common';

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        geometricElementRelations: selectors.getGeometricElementRelations(state),
        geometricElement: selectors.getGeometricElement(state)
    });
}

class GeometricElementRelationsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            currentSize: 10,
            currentSortField: 'modificationDate',
            currentSortOrder: 'desc',
            deleteGeometricElementRelationModalShow: false,
            deleteGeometricElementDialogShow: false,
            addUpdateGeometricElementRelationModalShow: false,
            deleteGeometricElementRelation: null,
            updateGeometricElementRelation: null,
            backendErrors: null
        }
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        let setBackendErrors = backendErrors => {
            this.setState({ backendErrors });
        }

        let handleTableChange = (page, sizePerPage, sortField, sortOrder) => {
            let pageToSearch = page > 0 ? page - 1 : 0;
            if (this.props.authenticatedUser) {
                if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                    this.props.dispatch(actions.findAdminGeometricElementRelation(
                        this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                        sortOrder))
                }
                else {
                    this.props.dispatch(actions.findUserGeometricElementRelation(
                        this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                        sortOrder))
                }
            } else {
                this.props.dispatch(actions.findPublicGeometricElementRelation(
                    this.props.match.params.id, pageToSearch, sizePerPage, sortField,
                    sortOrder))
            }
            this.setState({
                currentPage: page,
                currentSize: sizePerPage,
                currentSortField: sortField,
                currentSortOrder: sortOrder
            });
        }

        let renderCenterRelatedGeometricElement = (row) => {
            let relatedGeometricElement;
            if (row.geometricElement1.id !== this.props.geometricElement.id) {
                relatedGeometricElement = row.geometricElement1;
            } else {
                relatedGeometricElement = row.geometricElement2;
            }
            if (this.props.authenticatedUser) {
                if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                    return (
                        <span
                            onClick={() => {
                                this.props.handleCenterFeature(relatedGeometricElement);
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"centerGeometricElementRelation" + row.id}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </span>
                    )
                } else {
                    if (relatedGeometricElement.isPublic || relatedGeometricElement.isOwnerUser) {
                        return (
                            <span
                                onClick={() => {
                                    this.props.handleCenterFeature(relatedGeometricElement);
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"centerGeometricElementRelation" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                            >
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                        )
                    } else {
                        return null;
                    }
                }
            } else {
                if (relatedGeometricElement.isPublic) {
                    return (
                        <span
                            onClick={() => {
                                this.props.handleCenterFeature(relatedGeometricElement);
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"centerGeometricElementRelation" + row.id}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.common.centerOnViewer" })}
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </span>
                    )
                } else {
                    return null
                }
            }
        }

        let renderGeometricElementDetailsButton = (row) => {
            let relatedGeometricElement;
            if (row.geometricElement1.id !== this.props.geometricElement.id) {
                relatedGeometricElement = row.geometricElement1;
            } else {
                relatedGeometricElement = row.geometricElement2;
            }
            if (this.props.authenticatedUser) {
                if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                    return (
                        <span
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/geometric_element/details/${relatedGeometricElement.id}`,
                                    state: {
                                        centerFeature: row.geometricElement2
                                    }
                                })
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"showGeoemtricElementRelation" + row.id}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.elemens.relations.geometricElement.showDetails" }, {
                                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
                            })}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                    )
                } else {
                    if (relatedGeometricElement.isPublic || relatedGeometricElement.isOwnerUser) {
                        return (
                            <span
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: `/geometric_element/details/${relatedGeometricElement.id}`,
                                        state: {
                                            centerFeature: row.geometricElement2
                                        }
                                    })
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"showGeoemtricElementRelation" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.elemens.relations.geometricElement.showDetails" }, {
                                    singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
                                })}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                        )
                    } else {
                        return null;
                    }
                }
            } else {
                if (relatedGeometricElement.isPublic) {
                    return (
                        <span
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/geometric_element/details/${relatedGeometricElement.id}`,
                                    state: {
                                        centerFeature: row.geometricElement2
                                    }
                                })
                            }}
                            className="actionButton btn-link"
                            style={{
                                cursor: "pointer",
                                marginLeft: "0.5em"
                            }}
                            id={"showGeoemtricElementRelation" + row.id}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={this.props.intl.formatMessage({ id: "project.elemens.relations.geometricElement.showDetails" }, {
                                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
                            })}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                    )
                } else {
                    return null
                }
            }
        }

        let attributesFormatter = (cellContent, row) => {
            let relatedGeometricElement;
            if (row.geometricElement1.id !== this.props.geometricElement.id) {
                relatedGeometricElement = row.geometricElement1;
            } else {
                relatedGeometricElement = row.geometricElement2;
            }

            if (this.props.authenticatedUser) {
                if (this.props.authenticatedUser.userRoleDto.code !== "ADMIN") {
                    if (
                        !(relatedGeometricElement.isPublic ||
                            relatedGeometricElement.isOwnerUser)
                    )
                        return "-"
                }
            } else {
                if (!relatedGeometricElement.isPublic)
                    return "-"
            }

            let groupedValues = groupBy(relatedGeometricElement.listAttributeValueDto.sort(function (a, b) {
                return (a.elementTypeAttributeType.attributeOrder > b.elementTypeAttributeType.attributeOrder) ||
                    - (a.elementTypeAttributeType.attributeOrder < b.elementTypeAttributeType.attributeOrder)
            }), "elementTypeAttributeType.attributeType.code.code");
            let attributeNames = Object.keys(groupedValues);

            if (attributeNames.length === 0) {
                return "-"
            }

            return (
                attributeNames.map(attributeName =>
                    <div className="col-sm text-md-left" key={attributeName}>
                        <b>
                            <InternationalizationRender
                                locale={this.props.language}
                                value={attributeName}
                                listInternationalization={this.props.allCodes}
                            />
                        </b>
                        <br />
                        <p>
                            {groupedValues[attributeName].map((attributeValue, index, array) => {
                                if (attributeValue.value) {
                                    return (
                                        <span key={index}>
                                            <InternationalizationRender
                                                locale={this.props.language}
                                                value={attributeValue.value}
                                                listInternationalization={this.props.allCodes}
                                            />
                                            {array.length - 1 !== index ? ", " : ""}
                                        </span>
                                    )
                                } else {
                                    return "-"
                                }
                            }
                            )}
                        </p>
                    </div>
                )
            );
        }

        let columns = [{
            dataField: 'id',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.id" }),
            sort: true,
            headerAlign: 'left'
        }, {
            dataField: 'comments',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.comments" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                cellContent ? cellContent : "-"
            )
        }, {
            dataField: 'geometricElementId',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.geometricElementId" }, {
                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
            }),
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cellContent, row) => {
                if (row.geometricElement1.id !== this.props.geometricElement.id) {
                    return row.geometricElement1.id
                } else {
                    return row.geometricElement2.id
                }
            }
        }, {
            dataField: 'geometricElementType',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.geometricElementType" }, {
                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
            }),
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cellContent, row) => {
                if (row.geometricElement1.id !== this.props.geometricElement.id) {
                    return getInternationalization(
                        this.props.language,
                        row.geometricElement1.geometricElementType,
                        this.props.allCodes, 
                        this.props.activeLanguages
                    )
                } else {
                    return getInternationalization(
                        this.props.language,
                        row.geometricElement2.geometricElementType,
                        this.props.allCodes, 
                        this.props.activeLanguages
                    )
                }
            }
        }, {
            dataField: 'geometricElementDescription',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.geometricElementDescription" }, {
                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
            }),
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cellContent, row) => {
                let relatedGeometricElement;
                if (row.geometricElement1.id !== this.props.geometricElement.id) {
                    relatedGeometricElement = row.geometricElement1;
                } else {
                    relatedGeometricElement = row.geometricElement2;
                }

                if (this.props.authenticatedUser) {
                    if (this.props.authenticatedUser.userRoleDto.code !== "ADMIN") {
                        if (
                            !(relatedGeometricElement.isPublic || relatedGeometricElement.isOwnerUser)
                        )
                            return "-"
                    }
                } else {
                    if (!relatedGeometricElement.isPublic)
                        return "-"
                }

                return relatedGeometricElement.description

            }
        }, {
            dataField: 'geometricElementAttributes',
            text: this.props.intl.formatMessage({ id: "project.elements.relations.geometricElementAttributes" }, {
                singular: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_SINGULAR, this.props.allCodes, this.props.activeLanguages)
            }),
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: attributesFormatter
        }, {
            dataField: 'creationDate',
            text: this.props.intl.formatMessage({ id: "project.elements.creationDate" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                <span>
                    <FormattedDate
                        value={cellContent}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                    />&nbsp;
                    <FormattedTime
                        value={cellContent}
                    />
                </span>
            )
        }, {
            dataField: 'modificationDate',
            text: this.props.intl.formatMessage({ id: "project.elements.modificationDate" }),
            headerAlign: 'left',
            sort: true,
            formatter: (cellContent, row) => (
                <span>
                    <FormattedDate
                        value={cellContent}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                    />&nbsp;
                    <FormattedTime
                        value={cellContent}
                    />
                </span>
            )
        }, {
            dataField: 'action',
            isDummyField: true,
            text: this.props.intl.formatMessage({ id: "project.common.action" }),
            headerAlign: 'left',
            formatter: (cellContent, row) => (
                <div className="text-left">
                    {renderGeometricElementDetailsButton(row)}
                    {renderCenterRelatedGeometricElement(row)}
                    {this.props.authenticatedUser ?
                        this.props.authenticatedUser.userRoleDto.code === "ADMIN" ||
                            (this.props.authenticatedUser.id === row.authorUserAccount.id) ?
                            <span
                                onClick={() => {
                                    this.setState({
                                        updateGeometricElementRelation: row,
                                        backendErrors: null,
                                        addUpdateGeometricElementRelationModalShow: true
                                    });
                                }
                                }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modifyGeometricElementRelation" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            :
                            ""
                        : ""
                    }
                    {this.props.authenticatedUser ?
                        this.props.authenticatedUser.userRoleDto.code === "ADMIN" ||
                            (this.props.authenticatedUser.id === row.authorUserAccount.id) ?
                            <span
                                onClick={() => {
                                    this.setState({
                                        deleteGeometricElementRelation: row,
                                        backendErrors: null,
                                        deleteGeometricElementRelationModalShow: true
                                    });
                                }
                                }
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"deleteGeometricElementRelation" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                            :
                            ""
                        : ""
                    }
                </div>
            )
        },
        ];

        if (this.props.authenticatedUser) {
            columns.splice(8, 0, {
                dataField: 'isPublic',
                text: this.props.intl.formatMessage({ id: "project.elements.relations.isPublic" }),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                        <span><FormattedMessage id="project.common.no" /></span>
                )
            });
            columns.splice(9, 0, {
                dataField: 'isReviewed',
                text: this.props.intl.formatMessage({ id: "project.elements.relations.isReviewed" }),
                headerAlign: 'left',
                sort: true,
                formatter: (cellContent, row) => (
                    cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                        <span><FormattedMessage id="project.common.no" /></span>
                )
            });
            if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                columns.splice(10, 0, {
                    dataField: 'authorUserAccount.login',
                    text: this.props.intl.formatMessage({ id: "project.elements.relations.author" }),
                    headerAlign: 'left',
                    sort: true
                });
            }
        }

        return (
            <div id="geometricElementRelationsTable" className={this.props.className ? `${this.props.className} card` : "card"}>
                <h5 className="card-header text-left">
                    <FormattedMessage id="project.elements.relations" values={
                        {
                            plural: <InternationalizationRender value={PARAMETER_ELEMENT_TEXT_PLURAL} />
                        }}
                    />
                </h5>
                <div className="card-body">
                    <div className="text-right">
                        <span id={"addGeometricElementRelation" + this.props.match.params.id}
                            onClick={() => {
                                this.setState({
                                    updateGeometricElementRelation: null,
                                    backendErrors: null,
                                    addUpdateGeometricElementRelationModalShow: true
                                });
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                            className="actionButton btn-link mx-1"
                            data-toggle="tooltip"
                            data-placement="left"
                            title={this.props.intl.formatMessage({ id: "project.elements.relations.add" },
                                {
                                    plural: getInternationalization(this.props.language, PARAMETER_ELEMENT_TEXT_PLURAL, this.props.allCodes, this.props.activeLanguages)
                                }
                            )}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                    </div>
                    <BootstrapTable
                        key={
                            this.props.geometricElement.id
                        }
                        bootstrap4
                        keyField="id"
                        columns={columns}
                        data={this.props.geometricElementRelations ? this.props.geometricElementRelations.result.items : []}
                        defaultSorted={[{ dataField: 'modificationDate', order: 'desc' }]}
                        noDataIndication={this.props.geometricElementRelations ?
                            this.props.intl.formatMessage({ id: "project.elements.relations.noRelations" })
                            :
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        striped
                        condensed
                        loading={true}
                        remote
                        rowClasses="text-left"
                        onTableChange={(type, { page, sizePerPage, sortField, sortOrder }) => {
                            handleTableChange(page, sizePerPage, sortField, sortOrder);
                        }}
                        pagination={
                            paginationFactory({
                                page: this.state.currentPage,
                                sizePerPage: this.state.currentSize,
                                showTotal: true,
                                hidePageListOnlyOnePage: true,
                                totalSize: this.props.geometricElementRelations ?
                                    this.props.geometricElementRelations.result.totalItems : 0,
                                paginationTotalRenderer: (from, to, size) => {
                                    return <FormattedMessage id="project.common.tables.totalElements"
                                        values={{
                                            from: from,
                                            to: to,
                                            total: size
                                        }
                                        }
                                    />
                                },
                                sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                    <li
                                        key={text}
                                        role="presentation"
                                        className={`dropdown-item ${this.state.currentSize === Number(text) ? "active" : ""}`}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            onSizePerPageChange(page);
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {text}
                                    </li>
                                ),
                                nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' })
                            })
                        }
                    />
                </div>
                {/* Relate geometric elements dialog */}
                <AddModifyGeometricElementRelation
                    modalShow={this.state.addUpdateGeometricElementRelationModalShow}
                    geometricElement={this.props.geometricElement}
                    handleSubmit={() => {
                        handleTableChange(this.state.currentPage,
                            this.state.currentSize,
                            this.state.currentSortField,
                            this.state.currentSortOrder
                        )
                        this.setState({ addUpdateGeometricElementRelationModalShow: false })
                    }}
                    geometricElementRelationToModify={this.state.updateGeometricElementRelation}
                    backendErrors={this.state.backendErrors}
                    setBackendErrors={setBackendErrors}
                    hideModalWindow={() => this.setState({ addUpdateGeometricElementRelationModalShow: false })}
                />
                {/* Delete geometric element dialog */}
                {this.state.deleteGeometricElementRelation ?
                    <DeleteDialog
                        modalShow={this.state.deleteGeometricElementRelationModalShow}
                        title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                        details={this.props.intl.formatMessage(
                            { id: 'project.elements.relations.delete.message' },
                            {
                                geometricElement1: <b>{this.state.deleteGeometricElementRelation.geometricElement1.id}</b>,
                                geometricElement2: <b>{this.state.deleteGeometricElementRelation.geometricElement2.id}</b>
                            })}
                        backendErrors={this.state.backendErrors}
                        hideModalWindow={() => { this.setState({ deleteGeometricElementRelationModalShow: false }) }}
                        handleSubmit={() => {
                            this.props.dispatch(actions.deleteGeometricElementRelation(this.state.deleteGeometricElementRelation.id,
                                () => {
                                    let currentPage = this.state.currentPage;
                                    if (this.props.geometricElementRelations.result.items.length === 1 && currentPage > 0) {
                                        currentPage = currentPage - 1;
                                    }
                                    handleTableChange(currentPage, this.state.currentSize,
                                        this.state.currentSortField, this.state.currentSortOrder);
                                    this.setState({ deleteGeometricElementRelationModalShow: false });
                                },
                                (error) => {
                                    setBackendErrors(error);
                                }))
                        }}
                        setBackendErrors={setBackendErrors}
                    />
                    :
                    ""}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementRelationsTable)));