import * as actionTypes from './actionTypes';
import backend from '../../backend';
import * as selectors from './selectors';
import * as appActions from '../app/actions';
import * as appSelectors from '../app/selectors';
import { generateIdentificatorForLoadingAction } from '../app/util';

const signUpCompleted = user => ({
    type: actionTypes.SIGN_UP_COMPLETED,
    user
});

export const signUp = (user, onSuccess, onErrors, reauthenticationCallback) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.signUp(user, appSelectors.getLanguage(getState()), newUser => {
        dispatch(signUpCompleted(newUser));
        onSuccess(newUser);
        dispatch(appActions.loaded(loadingId));
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded(loadingId));
    }, reauthenticationCallback);
}

const loginCompleted = user => ({
    type: actionTypes.LOGIN_COMPLETED,
    user
});

export const login = (login, password, onSuccess, onErrors, reauthenticationCallback) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.login(login, password, appSelectors.getLanguage(getState()), user => {
        dispatch(loginCompleted(user));
        onSuccess(user);
        dispatch(appActions.loaded(loadingId));
    },
        (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        }, reauthenticationCallback);
}

export const tryLoginFromServiceToken = (onSuccess, reauthenticationCallback) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.tryLoginFromServiceToken(appSelectors.getLanguage(getState()),
        authenticatedUser => {
            if (authenticatedUser) {
                dispatch(appActions.language(authenticatedUser.user.locale.split("-")[0]));
                dispatch(loginCompleted(authenticatedUser));
                if (typeof onSuccess === 'function') {
                    onSuccess(authenticatedUser);
                }
            }
            dispatch(appActions.loaded(loadingId));
        },
        () => {
            dispatch(appActions.loaded(loadingId));
        },
        reauthenticationCallback
    );
}

export const logout = () => {
    backend.userService.logout();

    return { type: actionTypes.LOGOUT_COMPLETED };
}

export const changePassword = (id, currentPassword, newPassword, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.changePassword(id, currentPassword, newPassword, appSelectors.getLanguage(getState()),
        () => {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

export const changeLanguage = (user, onSuccess, onErrors) => (dispatch, getState) => {

    if (selectors.getUser(getState())) {

        const loadingId = generateIdentificatorForLoadingAction();
        dispatch(appActions.loading(loadingId));

        backend.userService.changeUserAccountLanguage(user, appSelectors.getLanguage(getState()),
            () => {
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
                dispatch(appActions.loaded(loadingId));
            }, (errors) => {
                if (typeof onErrors === 'function') {
                    onErrors(errors);
                }
                dispatch(appActions.loaded(loadingId));
            });

    }

}

const findTotalUserAccountCompleted = totalUserAccount => ({
    type: actionTypes.FIND_TOTAL_USER_ACCOUNT_COMPLETED,
    totalUserAccount
});

export const findTotalUserAccount = (onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    dispatch(findTotalUserAccountCompleted(null));
    backend.userService.findAllUserAccount(null, null, null, null, appSelectors.getLanguage(getState()), result => {
        dispatch(findTotalUserAccountCompleted(result));
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
        dispatch(appActions.loaded(loadingId));
    }, (errors) => {
        if (typeof onErrors === 'function') {
            onErrors(errors);
        }
        dispatch(appActions.loaded(loadingId));
    });
}

export const lockUserAccountCompleted = (lockedUserAccount) => ({
    type: actionTypes.LOCK_USER_ACCOUNT_COMPLETED,
    lockedUserAccount
})

export const lockUserAccount = (id, userAccount, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.lockUserAccount(id, userAccount, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(lockUserAccountCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

const findRoleByCodeCompleted = roleByCode => ({
    type: actionTypes.FIND_ROLE_BY_CODE_COMPLETED,
    roleByCode
});

export const findRoleByCode = (code) => (dispatch, getState) => {

    const roleByCode = selectors.getAllRoles(getState());

    if (roleByCode) {
        if (roleByCode.code !== code) {
            backend.userService.findUserRoleByCode(code, appSelectors.getLanguage(getState()), result =>
                dispatch(findRoleByCodeCompleted({ code, result })));
        }
    } else {
        backend.userService.findUserRoleByCode(code, appSelectors.getLanguage(getState()), result =>
            dispatch(findRoleByCodeCompleted({ code, result })));
    }
}

const findAllRolesCompleted = allRoles => ({
    type: actionTypes.FIND_ALL_ROLES_COMPLETED,
    allRoles
});

export const findAllRoles = () => (dispatch, getState) => {

    const allRoles = selectors.getAllRoles(getState());

    if (!allRoles) {
        backend.userService.findAllUserRole(appSelectors.getLanguage(getState()), result =>
            dispatch(findAllRolesCompleted(result)));
    }
}

export const createUserAccountCompleted = (newUserAccount) => ({
    type: actionTypes.CREATE_USER_COMPLETED,
    newUserAccount
})

export const createUserAccount = (json, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.userService.createUserAccount(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(createUserAccountCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}