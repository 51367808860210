import React from 'react';
import { Form, Formik, Field } from "formik";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import axios from 'axios';
import { getServiceToken } from '../../../backend/appFetch';
import app from '../../app';
import { connect } from 'react-redux';
import { NetworkError } from '../../../backend';
import { generateIdentificatorForLoadingAction } from '../../app/util';

const ExportStyle = ({ modalShow, hideModalWindow, downloadURL, fileName, backendErrors, setBackendErrors, ...props }) => {

    if (modalShow) {

        const initialValues = {
            styleType: "SLD"
        }

        return (
            <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting },) => {
                    const loadingId = generateIdentificatorForLoadingAction();
                    props.dispatch(app.actions.loading(loadingId))
                    axios({
                        url: downloadURL + `&styleType=${values.styleType}`,
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${getServiceToken()}`
                        },
                        responseType: 'blob'
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                        const link = document.createElement('a');
                        link.href = url;
                        let exportedStyleFilename;
                        if (values.styleType === "SLD") {
                            exportedStyleFilename = fileName + ".sld"
                        } else {
                            exportedStyleFilename = fileName + ".qml"
                        }
                        link.setAttribute('download', exportedStyleFilename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        hideModalWindow();
                        props.dispatch(app.actions.loaded(loadingId))
                    }).catch(error => {
                        props.dispatch(app.actions.loaded(loadingId));
                        if (error.response && error.response.status < 500) {
                            error.response.data.text().then(errorMessage => {

                                setBackendErrors(JSON.parse(errorMessage));
                            });
                        } else {

                            props.dispatch(app.actions.error(new NetworkError()));
                        }
                    });
                    setSubmitting(false);
                }}
            >
                {() =>
                    <Modal show={modalShow} onHide={hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="project.elements.styles.export" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="exportStyleForm">
                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="styleType" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.styles.styleType" />
                                    </label>
                                    <Field as="select" name="styleType" className="form-control" id="styleType">
                                        <option value="SLD">SLD</option>
                                        <option value="QML">QML</option>
                                    </Field>
                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="exportStyleSubmit"
                                type="submit"
                                className="btn btn-primary"
                                form="exportStyleForm"
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="exportStyleCancel"
                                onClick={() => {
                                    hideModalWindow()
                                }}
                                type="cancel"
                                className="btn btn-danger"
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }

    return null;
}

ExportStyle.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    downloadURL: PropTypes.string,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired
}

export default connect()(injectIntl(ExportStyle));