import React, { Component } from "react";
import GeographicalViewer from "./GeographicalViewer";
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as parameterSelectors from '../../configurationParameter/selectors'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import jQuery from 'jquery';
import { getInternationalization } from "../../app/components/InternationalizationRender";
import { FormattedMessage, injectIntl } from "react-intl";
import GeometricElementFilesTable from "./GeometricElementFilesTable";
import { DeleteDialog } from "../../common";
import AddGeometricElementFile from "./AddGeometricElementFile";
import GeometricElementRelationsTable from "./GeometricElementRelationsTable";
import GeometricElementData from "./GeometricElementData";
import GeometricElementJoinTable from "./GeometricElementJoinTable";

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        configurationParameters: parameterSelectors.getTotalConfigurationParameters(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricElementType: selectors.getTotalGeometricElementType(state),
        geometricElementType: selectors.getGeometricElementType(state),
        listProvinces: selectors.getListProvinces(state),
        listCouncils: selectors.getListCouncils(state),
        listParishes: selectors.getListParishes(state),
        listEntityPopulation: selectors.getListEntityPopulations(state),
        listCounties: selectors.getListCounties(state),
        listAllGeometricLayerType: selectors.getTotalGeometricLayerGroup(state),
        authenticatedUser: userSelectors.getUser(state),
        geometricElementRelations: selectors.getGeometricElementRelations(state),
        geometricElementById: selectors.getGeometricElement(state)
    }
}

class GeometricElementDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            geometricElement: null,
            backendErrors: null,
            deleteGeometricElementFileName: null,
            deleteGeometricElementFileUuid: null,
            deleteGeometricElementFileDialogShow: false,
            deleteGeometricElementDialogShow: false,
            addGeometricElementFileModalShow: false,
            geometricElementBackendError: null,
            featureToModify: null,
            centerFeature: null,
            forceCenterFeature: 0
        };
    }

    scrollToViewer = () => {
        let container = jQuery('#mainFooter');
        let map = jQuery('#geographicalViewer');
        if (map.length)
            jQuery(container).animate({
                scrollTop: ((map.offset().top - container.offset().top + container.scrollTop()))
            });
    }

    handleCenterFeature = (centerFeature) => {
        this.setState({
            centerFeature,
            forceCenterFeature: this.state.forceCenterFeature + 1
        }, this.scrollToViewer())
    }

    findGeometricElementToShowDetails = (callback) => {
        if (this.props.authenticatedUser) {
            if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                this.props.dispatch(actions.findAdminGeometricElementById(
                    this.props.match.params.id,
                    () => {
                        this.setState({
                            geometricElement: this.props.geometricElementById,
                            centerFeature: this.props.geometricElementById
                        });
                        if (callback) {
                            callback()
                        }
                    }, (error) => {
                        this.setState({
                            geometricElementBackendError: error
                        })
                    }
                ));
            } else {
                this.props.dispatch(actions.findUserGeometricElementById(
                    this.props.match.params.id,
                    () => {
                        this.setState({
                            geometricElement: this.props.geometricElementById,
                            centerFeature: this.props.geometricElementById
                        });
                        if (callback) {
                            callback();
                        }
                    }, (error) => {
                        this.setState({
                            geometricElementBackendError: error
                        })
                    }
                ));
            }
        } else {
            this.props.dispatch(actions.findPublicGeometricElementById(
                this.props.match.params.id,
                () => {
                    this.setState({
                        geometricElement: this.props.geometricElementById,
                        centerFeature: this.props.geometricElementById
                    });
                    if (callback) {
                        callback();
                    }
                }, (error) => {
                    this.setState({
                        geometricElementBackendError: error
                    })
                }
            ));
        }
    }

    componentDidMount() {
        let container = jQuery('#mainFooter');
        jQuery(container).scrollTop(0);
        this.findGeometricElementToShowDetails(() => {
            if (this.props.location.state && this.props.location.state.scrollToRelations) {
                let relationsTable = jQuery('#geometricElementRelationsTable');
                if (relationsTable.length) {
                    jQuery(container).scrollTop(((relationsTable.offset().top - container.offset().top + container.scrollTop())));
                }
            }
            if (this.props.location.state && this.props.location.state.scrollToJoins) {
                let joinsTable = jQuery('#geometricElementJoinsTable');
                if (joinsTable.length) {
                    jQuery(container).scrollTop(((joinsTable.offset().top - container.offset().top + container.scrollTop())));
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id || prevProps.authenticatedUser !== this.props.authenticatedUser) {
            jQuery('#mainFooter').scrollTop(0);
            this.findGeometricElementToShowDetails();
        }
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    }

    render() {

        jQuery(function () {
            jQuery('.actionButton').tooltip({
                trigger: "hover",
                title: function () {
                    return jQuery(this).attr('title');
                }
            });
        });

        jQuery(function () {
            jQuery('.actionButton').tooltip().click(function () {
                jQuery('.actionButton').tooltip("hide")
            });
        });


        let setBackendErrors = backendErrors => {
            this.setState({ backendErrors })
        }

        let handleOpenDeleteGeometricElementFile = (originalFilename, uuid) => {
            this.setState({
                deleteGeometricElementFileDialogShow: true,
                deleteGeometricElementFileName: originalFilename,
                deleteGeometricElementFileUuid: uuid
            })
        }

        let handleOpenAddGeometricElementFile = () => {
            this.setState({
                addGeometricElementFileModalShow: true
            })
        }

        let openAddGeometricElementForm = (map, geometry) => {
            jQuery('#map-container').css('margin-right', '30vw');
            jQuery('#addGeometricElementContainer').css('display', 'block');
            map.updateSize();
        }

        let closeAddGeometricElementForm = (map) => {
            jQuery('#map-container').css('margin-right', '0');
            jQuery('#addGeometricElementContainer').css('display', 'none');
            map.updateSize();
        }

        if (this.props.allGeometricElementType && this.props.allCodes && this.props.configurationParameters && this.props.listProvinces &&
            this.props.listCouncils && this.props.listParishes && this.props.listEntityPopulation && this.props.listCounties &&
            this.props.listAllGeometricLayerType
        ) {

            let maxExtentParameter = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("MAP_MAX_EXTENT") !== -1);

            let initialExtendParameter = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("MAP_INITIAL_EXTENT") !== -1);

            let clickPixelTolerance = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("CLICK_PIXEL_TOLERANCE") !== -1);

            let provincesBuffer = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("PROVINCES_BUFFER") !== -1);

            let councilsBuffer = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("COUNCILS_BUFFER") !== -1);

            let parishesBuffer = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("PARISHES_BUFFER") !== -1);

            let entityPopulationsBuffer = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("POPULATION_ENTITIES_BUFFER") !== -1);

            let countiesBuffer = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("COUNTIES_BUFFER") !== -1);

            let maxExtentIcon = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("MAX_EXTENT_CONTROL_ICON") !== -1);

            let enableGeometricElementsQuery = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENT_QUERY") !== -1);

            let allowConfigureLayersOpacity = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_CONFIGURE_LAYERS_OPACITY") !== -1);

            let enableGeographicalViewerScale = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("ENABLE_GEOGRAPHICAL_VIEWER_SCALE") !== -1);

            let allowExportGeographicalViewerToPDF = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("ALLOW_EXPORT_GEOGRAPHICAL_VIEWER_TO_PDF") !== -1);

            let otherLayersInfoTimeout = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("OTHER_LAYERS_INFO_TIMEOUT") !== -1);

            let showMousePositionCoordinates = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("SHOW_MOUSE_POSITION_COORDINATES") !== -1);

            let showMousePositionCoordinatesProjection = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("SHOW_MOUSE_POSITION_COORDINATES_PROJECTION") !== -1);

            let showEntityPopulationCenterControl = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("SHOW_ENTITY_POPULATION_CENTER_CONTROL") !== -1);

            let showGeoBookmarkControl = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("SHOW_GEOBOOKMARK_CONTROL") !== -1);

            let enableGeometricElementsRelation = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("ENABLE_GEOMETRIC_ELEMENTS_RELATION") !== -1);

            let defaultEnablePopupControl = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("DEFAULT_ACTIVATE_GEOGRAPHICAL_VIEWER_QUERY") !== -1);

            let defaultEnableGeographicalViewerLegend = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("DEFAULT_ACTIVATE_GEOGRAPHICAL_VIEWER_LEGEND") !== -1);

            let manageDefaultActivateGeographicalViewerSearch = Object.values(this.props.configurationParameters).filter(parameter =>
                parameter.code.indexOf("MANAGE_DEFAULT_ACTIVATE_GEOGRAPHICAL_VIEWER_SEARCH") !== -1);

            if (maxExtentParameter.length > 0) {
                maxExtentParameter = maxExtentParameter[0].value;
                maxExtentParameter = maxExtentParameter.split(",");
                maxExtentParameter = maxExtentParameter.map(Number);
            }

            if (initialExtendParameter.length > 0) {
                initialExtendParameter = initialExtendParameter[0].value;
                initialExtendParameter = initialExtendParameter.split(",");
                initialExtendParameter = initialExtendParameter.map(Number);
            }

            if (clickPixelTolerance.length > 0) {
                clickPixelTolerance = clickPixelTolerance[0].value;
                clickPixelTolerance = Number(clickPixelTolerance);
            }

            if (provincesBuffer.length > 0) {
                provincesBuffer = provincesBuffer[0].value;
                provincesBuffer = Number(provincesBuffer);
            } else {
                provincesBuffer = 50
            }

            if (councilsBuffer.length > 0) {
                councilsBuffer = councilsBuffer[0].value;
                councilsBuffer = Number(councilsBuffer);
            } else {
                councilsBuffer = 50
            }

            if (parishesBuffer.length > 0) {
                parishesBuffer = parishesBuffer[0].value;
                parishesBuffer = Number(parishesBuffer);
            } else {
                parishesBuffer = 50
            }

            if (entityPopulationsBuffer.length > 0) {
                entityPopulationsBuffer = entityPopulationsBuffer[0].value;
                entityPopulationsBuffer = Number(entityPopulationsBuffer);
            } else {
                entityPopulationsBuffer = 100
            }

            if (countiesBuffer.length > 0) {
                countiesBuffer = countiesBuffer[0].value;
                countiesBuffer = Number(countiesBuffer);
            } else {
                countiesBuffer = 50
            }

            if (maxExtentIcon.length > 0 && maxExtentIcon[0].value && maxExtentIcon[0].value !== "") {
                let icon = document.createElement('img');
                icon.src = maxExtentIcon[0].value;
                icon.className = "svg-inline--fa"
                icon.style.width = '1em';
                icon.style.height = '1em';
                maxExtentIcon = icon;
            } else {
                maxExtentIcon = 'E';
            }

            if (enableGeometricElementsQuery.length > 0) {
                enableGeometricElementsQuery = enableGeometricElementsQuery[0].value;
                enableGeometricElementsQuery = (enableGeometricElementsQuery === 'true');
            } else {
                enableGeometricElementsQuery = true;
            }

            if (allowConfigureLayersOpacity.length > 0) {
                allowConfigureLayersOpacity = allowConfigureLayersOpacity[0].value;
                allowConfigureLayersOpacity = (allowConfigureLayersOpacity === 'true');
            } else {
                allowConfigureLayersOpacity = true;
            }

            if (enableGeographicalViewerScale.length > 0) {
                enableGeographicalViewerScale = enableGeographicalViewerScale[0].value;
                enableGeographicalViewerScale = (enableGeographicalViewerScale === 'true');
            } else {
                enableGeographicalViewerScale = true;
            }

            if (allowExportGeographicalViewerToPDF.length > 0) {
                allowExportGeographicalViewerToPDF = allowExportGeographicalViewerToPDF[0].value;
                allowExportGeographicalViewerToPDF = (allowExportGeographicalViewerToPDF === 'true');
            } else {
                allowExportGeographicalViewerToPDF = true;
            }

            if (otherLayersInfoTimeout.length > 0) {
                otherLayersInfoTimeout = otherLayersInfoTimeout[0].value;
                otherLayersInfoTimeout = Number(otherLayersInfoTimeout);
            } else {
                otherLayersInfoTimeout = 15000
            }

            if (showMousePositionCoordinates.length > 0) {
                showMousePositionCoordinates = showMousePositionCoordinates[0].value;
                showMousePositionCoordinates = (showMousePositionCoordinates === 'true');
            } else {
                showMousePositionCoordinates = false;
            }

            if (showMousePositionCoordinatesProjection.length > 0) {
                showMousePositionCoordinatesProjection = showMousePositionCoordinatesProjection[0].value || 'EPSG:25829';
            } else {
                showMousePositionCoordinatesProjection = 'EPSG:25829';
            }

            if (showEntityPopulationCenterControl.length > 0) {
                showEntityPopulationCenterControl = showEntityPopulationCenterControl[0].value;
                showEntityPopulationCenterControl = (showEntityPopulationCenterControl === 'true');
            } else {
                showEntityPopulationCenterControl = false;
            }

            if (showGeoBookmarkControl.length > 0) {
                showGeoBookmarkControl = showGeoBookmarkControl[0].value;
                showGeoBookmarkControl = (showGeoBookmarkControl === 'true');
            } else {
                showGeoBookmarkControl = true;
            }

            if (enableGeometricElementsRelation.length > 0) {
                enableGeometricElementsRelation = enableGeometricElementsRelation[0].value;
                enableGeometricElementsRelation = (enableGeometricElementsRelation === 'true') ||
                    (this.props.authenticatedUser && this.props.authenticatedUser.userRoleDto.code === "ADMIN");
            } else {
                enableGeometricElementsRelation = false;
            }

            if (defaultEnablePopupControl.length > 0) {

                defaultEnablePopupControl = defaultEnablePopupControl[0].value;
                defaultEnablePopupControl = (defaultEnablePopupControl === 'true');

            } else {

                defaultEnablePopupControl = false;

            }

            if (defaultEnableGeographicalViewerLegend.length > 0) {

                defaultEnableGeographicalViewerLegend = defaultEnableGeographicalViewerLegend[0].value;
                defaultEnableGeographicalViewerLegend = (defaultEnableGeographicalViewerLegend === 'true');

            } else {

                defaultEnableGeographicalViewerLegend = false;

            }

            if (manageDefaultActivateGeographicalViewerSearch.length > 0) {

                manageDefaultActivateGeographicalViewerSearch = manageDefaultActivateGeographicalViewerSearch[0].value || 'SEARCH_ACTIVATE_NONE';

            } else {

                manageDefaultActivateGeographicalViewerSearch = 'SEARCH_ACTIVATE_NONE';

            }

            if (this.state.geometricElement) {

                return (
                    <div className="card">
                        <h4 className="card-header">
                            {getInternationalization(this.props.language,
                                this.state.geometricElement.geometricElementTypeDto.code,
                                this.props.allCodes, this.props.activeLanguages)}
                            &nbsp;
                            {this.state.geometricElement.id}
                        </h4>
                        <div className="card-body">

                            <GeometricElementData
                                authenticatedUser={this.props.authenticatedUser}
                                geometricElement={this.state.geometricElement}
                                modifyGeometricElement={() => {
                                    this.setState({
                                        featureToModify: this.state.geometricElement
                                    }, this.scrollToViewer());
                                }}
                                deleteGeometricElement={() => {
                                    this.setState({
                                        deleteGeometricElementDialogShow: true
                                    });
                                }}
                                handleCenterFeature={this.handleCenterFeature}
                            />

                            {this.state.geometricElement.geometricElementTypeDto.canJoin &&
                                <GeometricElementJoinTable
                                    geometricElement={this.state.geometricElement}
                                    className="my-3"
                                />
                            }

                            <GeometricElementFilesTable
                                files={
                                    this.state.geometricElement.listGeometricElementFileDto.map((elementFile) =>
                                    ({
                                        ...elementFile,
                                        owner: this.state.geometricElement.owner,
                                        isPublic: this.state.geometricElement.isPublic,
                                        id: this.state.geometricElement.id
                                    }))
                                }
                                geometricElement={this.state.geometricElement}
                                handleOpenDeleteGeometricElementFile={handleOpenDeleteGeometricElementFile}
                                handleOpenAddGeometricElementFile={handleOpenAddGeometricElementFile}
                                canShowFilesGallery={true}
                            />

                            {enableGeometricElementsRelation &&
                                <GeometricElementRelationsTable
                                    geometricElement={this.state.geometricElement}
                                    className="my-3"
                                    handleCenterFeature={this.handleCenterFeature}
                                />
                            }

                            <GeographicalViewer
                                key={
                                    this.state.featureToModify ?
                                        this.state.featureToModify.id + this.props.language + this.state.geometricElement.id + this.state.forceCenterFeature
                                        :
                                        this.props.language + this.state.geometricElement.id + this.state.forceCenterFeature
                                }
                                openAddGeometricElementForm={openAddGeometricElementForm}
                                closeAddGeometricElementForm={closeAddGeometricElementForm}
                                maxExtentParameter={maxExtentParameter}
                                initialExtendParameter={initialExtendParameter}
                                clickPixelTolerance={clickPixelTolerance}
                                provincesBuffer={provincesBuffer}
                                councilsBuffer={councilsBuffer}
                                parishesBuffer={parishesBuffer}
                                entityPopulationsBuffer={entityPopulationsBuffer}
                                countiesBuffer={countiesBuffer}
                                maxExtentIcon={maxExtentIcon}
                                centerFeature={this.state.centerFeature}
                                canQueryGeometricElement={this.props.allGeometricElementType.length > 0 && (enableGeometricElementsQuery ||
                                    (this.props.authenticatedUser && this.props.authenticatedUser.userRoleDto.code === "ADMIN"))}
                                canAddGeometricElement={false}
                                allowConfigureLayersOpacity={allowConfigureLayersOpacity}
                                enableGeographicalViewerScale={enableGeographicalViewerScale}
                                allowExportGeographicalViewerToPDF={allowExportGeographicalViewerToPDF}
                                otherLayersInfoTimeout={otherLayersInfoTimeout}
                                showMousePositionCoordinates={showMousePositionCoordinates}
                                showMousePositionCoordinatesProjection={showMousePositionCoordinatesProjection}
                                showEntityPopulationCenterControl={showEntityPopulationCenterControl}
                                showGeoBookmarkControl={showGeoBookmarkControl}
                                featureToModify={this.state.featureToModify}
                                enableGeometricElementsRelation={enableGeometricElementsRelation}
                                defaultEnablePopupControl={defaultEnablePopupControl}
                                defaultEnableGeographicalViewerLegend={defaultEnableGeographicalViewerLegend}
                                manageDefaultActivateGeographicalViewerSearch={manageDefaultActivateGeographicalViewerSearch}
                                submitModifyFromDetailsPage={() => {
                                    jQuery('#mainFooter').scrollTop(0);
                                    this.findGeometricElementToShowDetails()
                                    this.setState({
                                        featureToModify: null
                                    });
                                }}
                                cancelModifyFromDetailsPage={() => {
                                    this.setState({
                                        featureToModify: null
                                    })
                                }}
                                style={{
                                    width: "100%",
                                    height: "75vh"
                                }}
                            />
                        </div>
                        {/*Delete geometric element file dialog*/}
                        <DeleteDialog
                            modalShow={this.state.deleteGeometricElementFileDialogShow}
                            title={this.props.intl.formatMessage({ id: 'project.common.deleteFile' })}
                            details={this.props.intl.formatMessage(
                                { id: 'project.elements.delete.message' }, {
                                element:
                                    <b>
                                        {this.state.deleteGeometricElementFileName}
                                    </b>
                            })}
                            backendErrors={this.state.backendErrors}
                            hideModalWindow={() => { this.setState({ deleteGeometricElementFileDialogShow: false }) }}
                            handleSubmit={() => {
                                this.props.dispatch(actions.deleteGeometricElementFile(
                                    this.state.deleteGeometricElementFileUuid,
                                    () => {
                                        if (this.props.authenticatedUser) {
                                            if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                                                this.props.dispatch(actions.findAdminGeometricElementById(
                                                    this.props.match.params.id,
                                                    () => {
                                                        this.setState({
                                                            geometricElement: this.props.geometricElementById
                                                        })
                                                    }, (error) => {
                                                        this.setState({
                                                            geometricElementBackendError: error
                                                        })
                                                    }
                                                ));
                                            } else {
                                                this.props.dispatch(actions.findUserGeometricElementById(
                                                    this.props.match.params.id,
                                                    () => {
                                                        this.setState({
                                                            geometricElement: this.props.geometricElementById
                                                        })
                                                    }, (error) => {
                                                        this.setState({
                                                            geometricElementBackendError: error
                                                        })
                                                    }
                                                ));
                                            }
                                        } else {
                                            this.props.dispatch(actions.findPublicGeometricElementById(
                                                this.props.match.params.id,
                                                () => {
                                                    this.setState({
                                                        geometricElement: this.props.geometricElementById
                                                    })
                                                }, (error) => {
                                                    this.setState({
                                                        geometricElementBackendError: error
                                                    })
                                                }
                                            ));
                                        }
                                        this.setState({ deleteGeometricElementFileDialogShow: false })
                                    },
                                    (error) => {
                                        setBackendErrors(error);
                                    }))
                            }}
                            setBackendErrors={setBackendErrors}
                        />
                        {/* Add geometric element file dialog */}
                        <AddGeometricElementFile
                            modalShow={this.state.addGeometricElementFileModalShow}
                            geometricElement={this.state.geometricElement}
                            handleSubmit={() => {
                                if (this.props.authenticatedUser) {
                                    if (this.props.authenticatedUser.userRoleDto.code === "ADMIN") {
                                        this.props.dispatch(actions.findAdminGeometricElementById(
                                            this.props.match.params.id,
                                            () => {
                                                this.setState({
                                                    geometricElement: this.props.geometricElementById
                                                })
                                            }, (error) => {
                                                this.setState({
                                                    geometricElementBackendError: error
                                                })
                                            }
                                        ));
                                    } else {
                                        this.props.dispatch(actions.findUserGeometricElementById(
                                            this.props.match.params.id,
                                            () => {
                                                this.setState({
                                                    geometricElement: this.props.geometricElementById
                                                })
                                            }, (error) => {
                                                this.setState({
                                                    geometricElementBackendError: error
                                                })
                                            }
                                        ));
                                    }
                                } else {
                                    this.props.dispatch(actions.findPublicGeometricElementById(
                                        this.props.match.params.id,
                                        () => {
                                            this.setState({
                                                geometricElement: this.props.geometricElementById
                                            })
                                        }, (error) => {
                                            this.setState({
                                                geometricElementBackendError: error
                                            })
                                        }
                                    ));
                                }
                                this.setState({ addGeometricElementFileModalShow: false });
                            }}
                            hideModalWindow={() => this.setState({ addGeometricElementFileModalShow: false })}
                        />
                        {/* Delete geometric element dialog */}
                        <DeleteDialog
                            modalShow={this.state.deleteGeometricElementDialogShow}
                            title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                            details={this.props.intl.formatMessage(
                                { id: 'project.elements.geometricElement.delete.message' },
                                {
                                    element: <b>{this.state.geometricElement.description}</b>,
                                    alert: <div class="alert alert-primary" role="alert">
                                        <FormattedMessage id="project.elements.geometricElement.delete.message.alert" />
                                    </div>
                                })}
                            backendErrors={this.state.backendErrors}
                            hideModalWindow={() => { this.setState({ deleteGeometricElementDialogShow: false }) }}
                            handleSubmit={() => {
                                this.props.dispatch(actions.deleteGeometricElement(this.state.geometricElement.id,
                                    () => {
                                        this.props.history.push("/geometric_elements/" + this.state.geometricElement.geometricElementTypeDto.id)
                                    },
                                    (error) => {
                                        setBackendErrors(error);
                                    }))
                            }}
                            setBackendErrors={setBackendErrors}
                        />
                    </div>
                );
            } else {
                if (this.state.geometricElementBackendError) {
                    return (
                        <div className="container text-center">
                            <div class="alert alert-danger mt-3" role="alert">
                                <h4>{
                                    this.state.geometricElementBackendError.globalError ?
                                        this.state.geometricElementBackendError.globalError
                                        : ""
                                }</h4>
                            </div>
                        </div>
                    )
                }
            }
        }

        return null;

    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeometricElementDetails)));