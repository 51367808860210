import { config, appFetch, setServiceToken, removeServiceToken, setReauthenticationCallback, getServiceToken } from './appFetch';

export const login = (login, password, language, onSuccess, onErrors, reauthenticationCallback) => {
    appFetch('/users/login/', config('POST', { login, password }, language),
        user => {
            setServiceToken(user.serviceToken);
            setReauthenticationCallback(reauthenticationCallback);
            onSuccess(user);
        },
        onErrors
    );
}

export const signUp = (newUser, language, onSuccess, onErrors, reauthenticationCallback) => {

    appFetch('/users/signUp/', config('POST', newUser, language),
        user => {
            setServiceToken(user.serviceToken);
            setReauthenticationCallback(reauthenticationCallback);
            onSuccess(user);
        },
        onErrors
    );
}

export const logout = () => removeServiceToken();

export const changePassword = (id, currentPasword, newPassword, language, onSuccess, onErrors) => {
    appFetch(`/users/${id}`, config('POST', { "currentPassword": currentPasword, "newPassword": newPassword }, language),
        onSuccess, onErrors);

}

export const tryLoginFromServiceToken = (language, onSuccess, onErrors, reauthenticationCallback) => {
    const serviceToken = getServiceToken();

    if (!serviceToken) {
        onSuccess()
        return;
    }

    setReauthenticationCallback(reauthenticationCallback);

    appFetch('/users/loginFromServiceToken', config('POST'),
        authenticatedUser => onSuccess(authenticatedUser),
        () => {
            if (typeof onErrors === 'function') {
                onErrors();
            }
            removeServiceToken()
        }
    );
}

export const lockUserAccount = (id, userAccount, language, onSuccess, onErrors) => {
    appFetch(`/users/${id}/lock`, config('PUT', userAccount, language), onSuccess, onErrors);
}

export const findAllUserAccount = (page, size, sortField, sortOrder, language, onSuccess, onErrors) => {
    let path = '/users/all';
    if (page || size || sortField || sortOrder) {
        path += '?';
    }
    if (page) {
        path += `page=${page}`;
    }
    if (size) {
        if (page) {
            path += `&size=${size}`;
        } else {
            path += `size=${size}`;
        }
    }
    if (sortField) {
        if (page || size) {
            path += `&sortField=${sortField}`;
        } else {
            path += `sortField=${sortField}`;
        }
    }

    if (sortOrder) {
        if (page || size || sortField) {
            path += `&sortOrder=${sortOrder}`;
        } else {
            path += `sortOrder=${sortOrder}`;
        }
    }
    appFetch(path, config('GET', null, language), onSuccess, onErrors);
}

export const changeUserAccountLanguage = (user, language, onSuccess, onErrors) => {
    appFetch(`/users/changeLanguage`, config('POST', user, language), onSuccess, onErrors);
}

export const findAllUserRole = (language, onSuccess, onErrors) => {
    appFetch(`/users/roles/all`, config('GET', null, language), onSuccess, onErrors);
}

export const findUserRoleByCode = (code, language, onSuccess, onErrors) => {
    appFetch(`/users/roles/${code}`, config('GET', null, language), onSuccess, onErrors);
}

export const createUserAccount = (json, language, onSuccess, onErrors) => {
    appFetch(`/users/createUser`, config('Post', json, language), onSuccess, onErrors);
}