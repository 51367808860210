import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import jQuery from 'jquery';

const pixelsToMillimeters = (pixels) => {

    return pixels * 25.4 / 96;
}

const millimetersToPixels = (millimeters) => {

    return millimeters * 96 / 25.4;
}

const GeographicalViewerExportMenu = ({ modalShow, closeModalWindow, showError, map }) => {

    useEffect(() => {

        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
    });

    const [generatingPDF, setGeneratingPDF] = useState(false);

    let initialValues = {
        includeLegend: true,
        includeScale: true,
        orientation: 'landscape',
        size: 'a4'
    }

    var allCanvas = document.querySelectorAll('#map > .ol-viewport > .ol-layers > .ol-layer canvas');
    if (allCanvas.length > 0) {

        if (allCanvas[0].width < allCanvas[0].height) {

            initialValues.orientation = 'portrait'
        } else {

            initialValues.orientation = 'landscape';
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {

                setGeneratingPDF(true);

                const mapMargin = 15;
                const legendMargin = 5;
                const jsPdf = new jsPDF(values.orientation, undefined, values.size);
                jsPdf.setFontSize(12)
                const mapCanvas = document.createElement('canvas');
                const mapContext = mapCanvas.getContext('2d', { willReadFrequently: true });
                mapContext.globalCompositeOperation = "destination-over"
                mapCanvas.width = allCanvas[0].width;
                mapCanvas.height = allCanvas[0].height;

                Array.prototype.forEach.call(
                    allCanvas,
                    function (canvas) {

                        if (canvas.width > 0) {

                            const opacity = canvas.parentNode.style.opacity;
                            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                            const transform = canvas.style.transform;
                            // Get the transform parameters from the style's transform matrix
                            const matrix = transform
                                .match(/^matrix\(([^(]*)\)$/)[1]
                                .split(',')
                                .map(Number);
                            // Apply the transform to the export map context
                            CanvasRenderingContext2D.prototype.setTransform.apply(
                                mapContext,
                                matrix
                            );
                            mapContext.drawImage(canvas, 0, 0);
                        }
                    }
                );
                const mapImageWidth = jsPdf.internal.pageSize.getWidth() - (mapMargin * 2);
                const mapImageWidthRatio = mapImageWidth / mapCanvas.width;
                const mapImageHeigth = (mapCanvas.height / mapCanvas.width) * mapImageWidth;
                const mapImageVerticalPosition = (jsPdf.internal.pageSize.getHeight() - mapImageHeigth) / 2
                jsPdf.addImage({
                    imageData: mapCanvas.toDataURL('image/png'),
                    x: mapMargin,
                    y: mapImageVerticalPosition,
                    width: mapImageWidth,
                    height: mapImageHeigth
                });

                let allPromises = [];

                if (values.includeLegend) {

                    const legendMaxWidth = millimetersToPixels(mapImageWidth) / 5;
                    const legendMaxHeight = mapImageHeigth - 2;
                    let allLayers = [];

                    // This must be done in order to maintain the layers order in the legend. 
                    map.getLayerGroup().getLayersArray().reverse().forEach(newLayer => {
                        let layerIndex = allLayers.findIndex(layer => layer.get('title') === newLayer.get('title'));
                        if (layerIndex === -1 &&
                            newLayer.get('legendType')
                        ) {

                            allLayers.push(newLayer);
                        } else {

                            if (layerIndex !== -1) {

                                if (!allLayers[layerIndex].getVisible() && newLayer.getVisible()) {

                                    allLayers[layerIndex] = newLayer;
                                }
                            }
                        }
                    });

                    // Only show visible layers and layers with legend
                    allLayers = allLayers.filter(layer => layer.getVisible() && layer.get('legendType') !== "LEGEND_TYPE_NONE");

                    let legendPosition = 0;
                    let legendCanvasHeight = 0;
                    let legendCanvasWidth = legendMargin;
                    const legendCanvas = document.createElement('canvas');
                    const legendCanvasContext = legendCanvas.getContext('2d', { willReadFrequently: true });
                    legendCanvasContext.font = 'bold 12px Arial';
                    let imagesLoaded = [];

                    var staticLegend = document.querySelector('#staticLegend');
                    if (allLayers.length > 0 || staticLegend != null) {

                        allLayers = staticLegend != null ? [staticLegend, ...allLayers] : allLayers
                        // All images must be loaded
                        var legendPromise = Promise.all(allLayers.map(async (layer, index) => {

                            if (staticLegend != null && index === 0) {

                                if (legendCanvasWidth < legendMaxWidth) {

                                    const imageWidth = staticLegend.naturalWidth;
                                    const imageHeight = staticLegend.naturalHeight;
                                    if (legendCanvasWidth < imageWidth) {

                                        if (imageWidth <= legendMaxWidth) {

                                            legendCanvasWidth = imageWidth;
                                        } else {

                                            legendCanvasWidth = legendMaxWidth;
                                        }
                                    }

                                    legendCanvasHeight = imageHeight;

                                    return Promise.resolve(true).then(() => {

                                        imagesLoaded.splice(index, 0, { image: [staticLegend], title: null });
                                    });
                                }
                            } else {

                                const images = Array.from(document.querySelectorAll(`#legendAccordion #collapse${index} img`));
                                if (images.length > 0) {

                                    if (legendCanvasWidth < legendMaxWidth) {

                                        const titleWidth = Math.round(legendCanvasContext.measureText(layer.get('title')).width) + legendMargin;
                                        const imageWidth = Math.max(...images.map(image => image.width));
                                        if (legendCanvasWidth < titleWidth) {

                                            if (titleWidth <= legendMaxWidth) {

                                                legendCanvasWidth = titleWidth;
                                            } else {

                                                legendCanvasWidth = legendMaxWidth
                                            }
                                        }
                                        if (legendCanvasWidth < imageWidth) {

                                            if (imageWidth <= legendMaxWidth) {

                                                legendCanvasWidth = imageWidth;
                                            } else {

                                                legendCanvasWidth = legendMaxWidth
                                            }
                                        }
                                    }

                                    return Promise.all(images.map(async image => {

                                        let imageWidth = image.width;
                                        let imageHeight = image.height;
                                        if (imageWidth > legendCanvasWidth) {

                                            imageWidth = legendCanvasWidth - legendMargin * 2;
                                            imageHeight = (image.height / image.width) * imageWidth;
                                        }

                                        legendCanvasHeight = legendCanvasHeight + 12 + imageHeight;
                                        let title = layer.get('title');
                                        image.crossOrigin = "Anonymous";

                                        if (image.complete) {

                                            return Promise.resolve(image.naturalHeight !== 0).then(() => {

                                                if (imagesLoaded[index] && imagesLoaded[index].title === title) {

                                                    let imageLoaded = imagesLoaded[index];
                                                    imagesLoaded[index] = { image: [...imageLoaded.image, image], title }
                                                } else {

                                                    imagesLoaded.splice(index, 0, { image: [image], title });
                                                }
                                            });
                                        } else {

                                            return new Promise((resolve, reject) => {

                                                image.onload = resolve;
                                                image.crossOrigin = 'anonymous'
                                                let attemps = 0;
                                                image.onerror = function () {

                                                    if (attemps > 0) {

                                                        reject();
                                                    } else {

                                                        attemps++;
                                                        let src = image.src;
                                                        if (src.includes("?")) {

                                                            src += `&${new Date().getTime()}`;
                                                        } else {

                                                            src += `?${new Date().getTime()}`;
                                                        }
                                                        image.src = src;
                                                    }
                                                }
                                            }).then(() => {

                                                if (imagesLoaded[index] && imagesLoaded[index].title === title) {

                                                    let imageLoaded = imagesLoaded[index];
                                                    imagesLoaded[index] = { image: [...imageLoaded.image, image], title }
                                                } else {

                                                    imagesLoaded.splice(index, 0, { image: [image], title });
                                                }
                                            });
                                        }
                                    }));
                                }
                            }
                        })).then(() => {

                            legendCanvas.height = legendCanvasHeight;
                            legendCanvas.width = legendCanvasWidth;
                            legendCanvasContext.fillStyle = "white";
                            legendCanvasContext.fillRect(0, 0, legendCanvasWidth, legendCanvasHeight);

                            imagesLoaded.forEach((loadedImage) => {

                                const title = loadedImage.title;
                                if (title != null) {

                                    legendPosition += 12;
                                    legendCanvasContext.fillStyle = 'black';
                                    legendCanvasContext.font = 'bold 12px Arial'
                                    legendCanvasContext.fillText(title, legendMargin, legendPosition, legendCanvasWidth - legendMargin);
                                }
                                const imageArray = loadedImage.image;

                                imageArray.forEach(image => {

                                    let imageWidth = image.width;
                                    let imageHeight = image.height;
                                    if (imageWidth > legendCanvasWidth) {

                                        imageWidth = legendCanvasWidth - legendMargin * 2;
                                        imageHeight = (image.height / image.width) * imageWidth;
                                    }

                                    legendCanvasContext.drawImage(
                                        image,
                                        legendMargin,
                                        legendPosition,
                                        imageWidth,
                                        imageHeight
                                    );

                                    legendPosition += imageHeight;
                                });
                            });

                            legendCanvasWidth = pixelsToMillimeters(legendCanvasWidth);
                            legendCanvasHeight = pixelsToMillimeters(legendCanvasHeight);

                            if (legendCanvasHeight > legendMaxHeight) {

                                legendCanvasHeight = legendMaxHeight;
                                let legendCanvasPercentage = legendCanvasHeight / pixelsToMillimeters(legendCanvas.height);
                                legendCanvasWidth = legendCanvasWidth * legendCanvasPercentage;
                            }

                            jsPdf.addImage({
                                imageData: legendCanvas.toDataURL('image/png'),
                                // + mapMargin: left margin added to map image
                                // - legendMargin: right margin
                                x: mapImageWidth + mapMargin - legendCanvasWidth - 1,
                                y: mapImageVerticalPosition + 1,
                                width: legendCanvasWidth,
                                height: legendCanvasHeight
                            });
                        });

                        allPromises.push(legendPromise);
                    }
                }

                if (values.includeScale) {

                    let scaleBar = document.querySelector('.ol-scale-bar');
                    let scaleBarTexts = document.querySelectorAll('.ol-scale-step-text');
                    let scaleBarOriginalWidth = scaleBar.offsetWidth;
                    let scaleBarOriginalHeight = scaleBar.offsetHeight;
                    scaleBarOriginalWidth += scaleBarTexts[2].offsetLeft / 2;
                    scaleBarOriginalHeight += scaleBarTexts[2].offsetTop / 2;
                    var scaleLinePromise = html2canvas(scaleBar, {
                        backgroundColor: null,
                        scrollX: 2,
                        scrollY: 0,
                        width: scaleBarOriginalWidth,
                        height: scaleBarOriginalHeight
                    }).then(canvas => {

                        const scaleBarHorizontalPosition = mapMargin + 1;
                        const scaleBarWidth = (scaleBarOriginalWidth * mapImageWidthRatio);
                        const scaleBarHeight = (scaleBarOriginalHeight / scaleBarOriginalWidth) * scaleBarWidth;
                        const scaleLineVerticalPosition = mapImageVerticalPosition + mapImageHeigth - scaleBarHeight;
                        jsPdf.addImage({
                            imageData: canvas.toDataURL('image/png'),
                            x: scaleBarHorizontalPosition,
                            y: scaleLineVerticalPosition,
                            width: scaleBarWidth,
                            height: scaleBarHeight
                        });
                    });

                    allPromises.push(scaleLinePromise)
                }

                Promise.all(allPromises).then(() => {

                    let promise = jsPdf.save("map.pdf", { returnPromise: true });
                    promise.then(() => {
                        closeModalWindow();
                        resetForm();
                        setGeneratingPDF(false);
                    });
                }).catch((error) => {

                    showError();
                    closeModalWindow();
                    resetForm();
                    setGeneratingPDF(false);
                });

                setSubmitting(false);
            }}
        >
            {({ resetForm }) =>
                <Modal show={modalShow} onHide={closeModalWindow} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="project.elements.map.exportPDFTip" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="needs-validation novalidate" id="geographicalViewerExportMenuForm">
                            <div className="form-group">
                                <label htmlFor="size" className="font-weight-bold required">
                                    <FormattedMessage id="project.elements.map.exportPDF.size" />
                                </label>
                                <Field as="select" id="size" name="size" className="form-control">
                                    <FormattedMessage id="project.elements.map.exportPDF.size.a4">
                                        {(msg) => <option value="a4">{msg}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="project.elements.map.exportPDF.size.a3">
                                        {(msg) => <option value="a3">{msg}</option>}
                                    </FormattedMessage>
                                </Field>
                            </div>

                            <div className="form-group">
                                <label htmlFor="orientation" className="font-weight-bold required">
                                    <FormattedMessage id="project.elements.map.exportPDF.orientation" />
                                </label>
                                <Field as="select" id="orientation" name="orientation" className="form-control">
                                    <FormattedMessage id="project.elements.map.exportPDF.orientation.portrait">
                                        {(msg) => <option value="portrait">{msg}</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="project.elements.map.exportPDF.orientation.landscape">
                                        {(msg) => <option value="landscape">{msg}</option>}
                                    </FormattedMessage>
                                </Field>
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <Field as="input" type="checkbox" id="includeLegend" name="includeLegend" />
                                    </div>
                                </div>
                                <label htmlFor="includeLegend" className="btn input-group-text">
                                    <FormattedMessage id="project.elements.map.exportPDF.includeLegend" />
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <Field as="input" type="checkbox" id="includeScale" name="includeScale" />
                                    </div>
                                </div>
                                <label htmlFor="includeScale" className="btn input-group-text">
                                    <FormattedMessage id="project.elements.map.exportPDF.includeScale" />
                                </label>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary" id="geographicalViewerExportMenuSubmit"
                            form="geographicalViewerExportMenuForm"
                            disabled={generatingPDF}
                        >
                            {generatingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            &nbsp;
                            <FormattedMessage id="project.elements.map.exportPDF.save" />
                        </button>
                        <button type="reset" className="btn btn-danger" id="geographicalViewerExportMenuCancel"
                            form="geographicalViewerExportMenuForm"
                            disabled={generatingPDF}
                            onClick={() => {
                                resetForm()
                                closeModalWindow()
                            }}
                        >
                            <FormattedMessage id="project.app.Body.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </Formik >
    )
}

export default GeographicalViewerExportMenu;