import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';
import { generateIdentificatorForLoadingAction } from '../app/util';

const findTotalConfigurationParametersCompleted = totalConfigurationParameter => ({
    type: actionTypes.FIND_TOTAL_CONFIGURATION_PARAMETER_COMPLETED,
    totalConfigurationParameter
});

export const findTotalConfigurationParameters = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.configurationParameterService.findAllConfigurationParameter(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalConfigurationParametersCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess(result);
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
};

const updateConfigurationParameterCompleted = (updatedConfigurationParameter) => ({
    type: actionTypes.UPDATE_CONFIGURATION_PARAMETER_COMPLETED,
    updatedConfigurationParameter
})

export const updateConfigurationParameter = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.configurationParameterService.updateConfigurationParameter(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(updateConfigurationParameterCompleted(result));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}