import React from "react";
import jQuery from "jquery";
import InternationalizationRender, { getInternationalization, hasHelp } from "../app/components/InternationalizationRender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, Field } from "formik";
import GeometricElementContactDataField from "../geometricElements/components/GeometricElementContactDataField";

export const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toLocaleUpperCase() + rest.join('');
}

export const initActionButtonTooltip = () => {
    jQuery(function () {
        jQuery('.actionButton').tooltip({
            trigger: "hover",
            placement: 'right',
            boundary: 'window'
        });
    });

    jQuery(function () {
        jQuery('.actionButton').tooltip().click(function () {
            jQuery('.actionButton').tooltip("hide")
        });
    });
}

export const initTooltipHelp = () => {
    jQuery(function () {
        //show tooltip
        jQuery('.helpTooltip').tooltip({
            html: true,
            placement: 'right',
            trigger: 'click',
            // Prevent placement flip
            fallbackPlacement: ['right'],
            boundary: 'window',
            // Show tables and custom styles inside tooltip
            sanitize: false,
            template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                '</div><div class="help-tooltip tooltip-inner"></div></div>'
        });
        //hide it when clicking anywhere else
        jQuery('body').on('click', function (e) {
            jQuery('.helpTooltip').each(function () {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                    jQuery(this).tooltip('hide');
                }
            });
        });
    });

    //Prevent clicking on help button activates input associated with label
    jQuery(document).on('tap click', 'label sup', function (event, data) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    });
}

export const renderLanguageOptions = (activeLanguages, language, allCodes) => {
    return activeLanguages.map(languageCode => {
        let formattedLanguage;
        let splittedLanguage = languageCode.split("-");
        formattedLanguage = `${splittedLanguage[0].toLowerCase()}-${splittedLanguage[1]}`
        return (
            <option value={formattedLanguage} key={formattedLanguage}>
                {getInternationalization(language, languageCode, allCodes, activeLanguages)}
            </option>
        )
    })
}

export const sortGeometricElementAttributeValueComparator = (value1, value2) => {
    if (value1.elementTypeAttributeType.attributeOrder < value2.elementTypeAttributeType.attributeOrder) {
        return -1;
    }
    if (value2.elementTypeAttributeType.attributeOrder < value1.elementTypeAttributeType.attributeOrder) {
        return 1;
    }

    return 0;
}

export const sortGeometricElementTypeAttributeTypeComparator = (attribute1, attribute2) => {
    if (attribute1.attributeOrder < attribute2.attributeOrder) {
        return -1;
    }
    if (attribute2.attributeOrder < attribute1.attributeOrder) {
        return 1;
    }

    return 0;
}

export const sortValuesAndGroupByAttributeType = (attributeValueList) => {
    let attributeNameList = [];
    let attributes = [];
    let values = [];
    attributeValueList.sort(sortGeometricElementAttributeValueComparator);

    attributeValueList.forEach(attributeValue => {
        let code = attributeValue.elementTypeAttributeType.attributeType.code.code;
        let type = attributeValue.elementTypeAttributeType.attributeType.dataType;
        let attributeType = { code, type };
        if (attributeNameList.includes(code)) {
            let attributeTypeIndex = attributeNameList.indexOf(code);
            values[attributeTypeIndex].push(attributeValue.value);
        } else {
            attributeNameList.push(code);
            attributes.push(attributeType);
            values.push([attributeValue.value]);
        }
    });

    return { attributes, values }
}

export const GeometricElementTypeAttributeFieldLabel = ({ locale, attributeType, allCodes, activeLanguages, checkbox }) => {

    return (

        <label htmlFor={attributeType.attributeType.code.code}
            className={`${!checkbox ? `font-weight-bold ${attributeType.mandatory ? "required" : ""}` : 'btn input-group-text'}`}
        >
            <InternationalizationRender
                value={attributeType.attributeType.code.code}
            />
            &nbsp;
            {hasHelp(locale, attributeType.attributeType.helpCode) &&
                <sup className="helpTooltip btn-link"
                    style={{ cursor: "pointer" }}
                    id={"help" + attributeType.attributeType.code.code}
                    aria-hidden={true}
                    data-toggle="tooltip"
                    data-placement="left"
                    data-original-title={

                        getInternationalization(
                            locale,
                            attributeType.attributeType.helpCode.code,
                            allCodes,
                            activeLanguages
                        )
                    }
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </sup>
            }
        </label>

    );

}

export const GeometricElementTypeAttributeFieldErrorMessage = ({ setFieldError, attributeType, errors }) => {

    return (

        <ErrorMessage name={attributeType.attributeType.code.code} render={(msg) =>
            <div id={attributeType.attributeType.code.code + 'error'}
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
                hidden={typeof errors[attributeType.attributeType.code.code] === "undefined"}
            >
                <button type="button" className="close" aria-label="Close"
                    onClick={() => setFieldError(`${attributeType.attributeType.code.code}`, undefined)}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                {msg}
            </div>
        }
        />

    )

}

export const SelectTypeInput = ({ setFieldError, formatMessage, multiple, locale, errors, attributeType, allCodes, activeLanguages }) => {

    return (
        <>
            <div className="form-group">
                <GeometricElementTypeAttributeFieldLabel
                    locale={locale}
                    attributeType={attributeType}
                    allCodes={allCodes}
                    activeLanguages={activeLanguages}
                    checkbox={false}
                />
                <Field as="select"
                    className="form-control"
                    id={attributeType.attributeType.code.code}
                    name={attributeType.attributeType.code.code}
                    multiple={multiple}
                >
                    {!multiple ? <option value="">{formatMessage({ id: 'project.elements.selectOneOption' })}</option> : ""}
                    {allCodes.filter(code =>
                        code.codeGroup.id === attributeType.attributeType.valueGroupCode.id
                    ).map(code =>
                        <option value={code.code} key={code.code}>
                            {getInternationalization(locale, code.code, allCodes, activeLanguages)}
                        </option>
                    )}
                </Field>
            </div>
            <GeometricElementTypeAttributeFieldErrorMessage
                attributeType={attributeType}
                errors={errors}
                setFieldError={setFieldError}
            />
        </>
    );

}

export const GeometricElementTypeAttributesFields = ({ formatMessage, setFieldError, allCodes, activeLanguages, values, errors, locale, attributeType }) => {

    switch (attributeType.attributeType.dataType) {

        case "MULTISELECT":

            return (

                <SelectTypeInput
                    setFieldError={setFieldError}
                    formatMessage={formatMessage}
                    multiple={true}
                    locale={locale}
                    errors={errors}
                    attributeType={attributeType}
                    allCodes={allCodes}
                    activeLanguages={activeLanguages}
                    key={attributeType.attributeType.id}
                />

            )

        case "SELECT":

            return (

                <SelectTypeInput
                    setFieldError={setFieldError}
                    formatMessage={formatMessage}
                    multiple={true}
                    locale={locale}
                    errors={errors}
                    attributeType={attributeType}
                    allCodes={allCodes}
                    activeLanguages={activeLanguages}
                    key={attributeType.attributeType.id}
                />

            )

        case "TEXT":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field as="input"
                            type="text"
                            className="form-control"
                            id={attributeType.attributeType.code.code}
                            name={attributeType.attributeType.code.code}
                        />
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        case "DATE":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field as="input"
                            type="date"
                            className="form-control"
                            id={attributeType.attributeType.code.code} name={attributeType.attributeType.code.code}
                        />
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        case "DATETIME":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field type="datetime-local" className="form-control"
                            id={attributeType.attributeType.code.code}
                            name={attributeType.attributeType.code.code}
                        />
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        case "NUMBER":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field as="input" type="number" className="form-control"
                            id={attributeType.attributeType.code.code}
                            name={attributeType.attributeType.code.code}
                        />
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        case "FLOAT":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field as="input" type="number" step="any"
                            className="form-control"
                            id={attributeType.attributeType.code.code}
                            name={attributeType.attributeType.code.code}
                        />
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        case "CONTACT_DATA":

            return (

                <GeometricElementContactDataField
                    key={attributeType.attributeType.id}
                    attributeType={attributeType}
                    setFieldError={setFieldError}
                    isPublic={values.isPublic}
                    errors={errors}

                />

            )

        case "BOOLEAN_CHECK":

            return (

                <div className="input-group mb-3" key={attributeType.attributeType.id}>
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <Field as="input" type="checkbox"
                                id={attributeType.attributeType.code.code}
                                name={attributeType.attributeType.code.code}
                                checked={values[attributeType.attributeType.code.code] === "true" || values[attributeType.attributeType.code.code] === true}
                            />
                        </div>
                    </div>
                    <GeometricElementTypeAttributeFieldLabel
                        locale={locale}
                        attributeType={attributeType}
                        allCodes={allCodes}
                        activeLanguages={activeLanguages}
                        checkbox={true}
                    />
                </div>

            )

        case "BOOLEAN_SELECT":

            return (

                <div key={attributeType.attributeType.id}>
                    <div className="form-group">
                        <GeometricElementTypeAttributeFieldLabel
                            locale={locale}
                            attributeType={attributeType}
                            allCodes={allCodes}
                            activeLanguages={activeLanguages}
                            checkbox={false}
                        />
                        <Field as="select"
                            className="form-control"
                            id={attributeType.attributeType.code.code}
                            name={attributeType.attributeType.code.code}
                        >
                            <option value="">
                                {formatMessage({ id: 'project.elements.selectOneOption' })}
                            </option>
                            <option value="true">
                                {formatMessage({ id: 'project.common.yes' })}
                            </option>
                            <option value="false">
                                {formatMessage({ id: 'project.common.no' })}
                            </option>
                        </Field>
                    </div>
                    <GeometricElementTypeAttributeFieldErrorMessage
                        attributeType={attributeType}
                        errors={errors}
                        setFieldError={setFieldError}
                    />
                </div>

            )

        default:

            return <></>;

    }

}

export const forceChangeLanguage = (language) => {

    const languageSelector = document.getElementById('languageSelector');
    languageSelector.value = language;
    languageSelector.dispatchEvent(new Event('change'));

}