const getModuleState = state => state.app;

export const getError = state => getModuleState(state).error;

export const isLoading = state => getModuleState(state).loading.length > 0;

export const getLanguage = state => getModuleState(state).language;

export const getActiveLanguages = state => getModuleState(state).activeLanguages;

export const getAvailableLanguages = state => getModuleState(state).availableLanguages;

export const getApplicationData = state => getModuleState(state).applicationData;

export const getLocale = state => getModuleState(state).locale;