import React from 'react';
import * as actions from '../actions';
import * as selectors from '../selectors';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faChevronCircleDown, faChevronCircleUp, faPlus } from '@fortawesome/free-solid-svg-icons'
import jQuery from 'jquery';
import filterFactory, { textFilter, Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import { DeleteDialog } from '../../common';
import AddModifyGeometricLayerGroup from './AddModifyGeometricLayerGroup';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        user: userSelectors.getUser(state),
        allCodes: selectors.getAllCodes(state),
        totalGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state)
    }
}

const BACKGROUND_LAYER_GROUP = "BACKGROUND_LAYER_GROUP";

class AllGeometricLayerGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalGeometricLayerGroup: null,
            expandedRows: [],
            sizePerPage: 10,
            filters: {},
            backendErrors: null,
            deleteGeometricLayerGroupDialogShow: false,
            deleteGeometricLayerGroupId: "",
            deleteGeometricLayerGroupName: "",
            addModifyGeometricLayerGroupModalShow: false,
            geometricLayerGroupToModify: null
        }
    }

    componentDidMount() {
        this.setState({ totalGeometricLayerGroup: this.props.totalGeometricLayerGroup });
    }

    componentDidUpdate() {
        jQuery('.modal-dialog').draggable({
            cursor: 'move',
            handle: '.modal-header'
        });
        if (!this.state.totalGeometricLayerGroup && this.props.totalGeometricLayerGroup) {
            this.setState({ totalGeometricLayerGroup: this.props.totalGeometricLayerGroup });
        }
    }

    render() {

        let setBackendErrors = (backendErrors) => {
            this.setState({ backendErrors: backendErrors });
        }

        let handleBtnClick = (row) => {
            if (!this.state.expandedRows.includes(row.id)) {
                this.setState({ expandedRows: [row.id] });
            } else {
                this.setState({ expandedRows: [] });
            }
        }

        let clearFilters = (geometricLayerGroupId) => {
            let { idFilter, codeFilter, includeInsideGroupLayerFilter,
                orderFilter, initiallyOpenFilter, placeBehindOtherLayersFilter } = this.state.filters;
            if (geometricLayerGroupId) {
                idFilter(`${geometricLayerGroupId}`)
            } else {
                idFilter('');
            }
            codeFilter('');
            includeInsideGroupLayerFilter('');
            orderFilter('');
            initiallyOpenFilter('');
            placeBehindOtherLayersFilter('');
        }

        if (this.props.user) {

            if (this.props.user.userRoleDto.code === "ADMIN") {

                let booleanFilterOptions = [{
                    value: true,
                    label: this.props.intl.formatMessage({ id: 'project.common.yes' })
                }, {
                    value: false,
                    label: this.props.intl.formatMessage({ id: 'project.common.no' })
                }];

                jQuery(function () {
                    jQuery('.actionButton').tooltip({ trigger: "hover" });
                });

                jQuery(function () {
                    jQuery('.actionButton').tooltip().click(function () {
                        jQuery('.actionButton').tooltip("hide");
                    });
                });

                const geometricLayerColumns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    headerAlign: 'left'
                },
                {
                    dataField: 'internalName',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalName" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) =>
                        cellContent || "-"
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'order',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.order" }),
                    headerAlign: 'left',
                    sort: true
                }, {
                    dataField: 'type.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.type" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    )
                }, {
                    dataField: 'internalComments',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layer.internalComments" }),
                    headerAlign: 'left',
                    sort: true,
                    formatter: (cellContent, row) => (
                        cellContent ? cellContent !== "" ? cellContent : "-" : "-"
                    )
                }];

                const expandRow = {
                    renderer: row => (
                        <div className="card card-body" key={row.id + "container"}>
                            <h6 className="card-title text-left" key={row.id + "title"}>
                                <FormattedMessage id="project.app.Header.admin.layer" />
                            </h6>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                columns={geometricLayerColumns}
                                data={row.listGeometricLayer}
                                rowClasses="text-left"
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                noDataIndication={<FormattedMessage id="project.elements.geometricLayer.layer.noElements" />}
                                striped
                                condensed
                                key={row.id + 'table'}
                            />
                        </div>
                    ),
                    expanded: this.state.expandedRows,
                    expandByColumnOnly: true,
                }

                const columns = [{
                    dataField: 'id',
                    text: 'Id',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    headerAlign: 'left',
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: "id" }),
                        comparator: Comparator.EQ,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.idFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'code.code',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.name" }),
                    headerAlign: 'left',
                    formatter: (cellContent, row) => (
                        <InternationalizationRender locale={this.props.language} value={cellContent} listInternationalization={this.props.allCodes} />
                    ),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.name" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.codeFilter = filter;
                                return { filters }
                            })
                        },
                    }),
                    filterValue: (cell, row) => getInternationalization(this.props.language, row.code.code, this.props.allCodes, this.props.activeLanguages)
                }, {
                    dataField: 'placeBehindOtherLayers',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.placeBehindOtherLayers" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.placeBehindOtherLayers" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.placeBehindOtherLayersFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'includeInsideGroupLayer',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.includesInsideLayerGroup" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.includesInsideLayerGroup" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.includeInsideGroupLayerFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'order',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.order" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    filter: textFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.text.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.order" }).toLocaleLowerCase() }),
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.orderFilter = filter;
                                return { filters }
                            })
                        }
                    })
                }, {
                    dataField: 'initiallyOpen',
                    text: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.initiallyOpen" }),
                    headerAlign: 'left',
                    sort: true,
                    onSort: (field, order) => this.setState({
                        currentSortField: field,
                        currentSortOrder: order
                    }),
                    formatter: (cellContent, row) => (
                        cellContent ? <span><FormattedMessage id="project.common.yes" /></span> :
                            <span><FormattedMessage id="project.common.no" /></span>
                    ),
                    filter: selectFilter({
                        placeholder: this.props.intl.formatMessage({ id: 'project.common.tables.filter.select.placeholder' },
                            { column: this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.initiallyOpen" }).toLocaleLowerCase() }),
                        options: booleanFilterOptions,
                        getFilter: (filter) => {
                            this.setState(previousState => {
                                let filters = { ...previousState.filters };
                                filters.initiallyOpenFilter = filter;
                                return { filters }
                            })
                        }
                    }),
                }, {
                    dataField: 'action',
                    isDummyField: true,
                    text: this.props.intl.formatMessage({ id: "project.common.action" }),
                    headerAlign: 'left',
                    formatExtraData: this.state.expandedRows,
                    formatter: (cellContent, row, rowIndex, formatExtraData) => (
                        <div className="text-left">
                            <span
                                onClick={() => {
                                    this.setState({
                                        backendErrors: null,
                                        addModifyGeometricLayerGroupModalShow: true,
                                        geometricLayerGroupToModify: row
                                    })
                                }}
                                className="actionButton btn-link"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "0.5em"
                                }}
                                id={"modify" + row.id}
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.props.intl.formatMessage({ id: "project.common.modify" })}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </span>
                            {row.code.code !== BACKGROUND_LAYER_GROUP ?
                                <span onClick={() =>
                                    this.setState({
                                        backendErrors: null,
                                        deleteGeometricLayerGroupDialogShow: true,
                                        deleteGeometricLayerGroupId: row.id,
                                        deleteGeometricLayerGroupName: getInternationalization(this.props.language, row.code.code, this.props.allCodes, this.props.activeLanguages)
                                    })
                                }
                                    className="actionButton btn-link"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    id={"delete" + row.id}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.common.delete" })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                                :
                                ""
                            }
                            {row.listGeometricLayer && row.listGeometricLayer.length ?
                                <span id={"hideShowLayers" + row.id}
                                    className="actionButton btn-link"
                                    onClick={() => handleBtnClick(row)}
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "0.5em"
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={this.props.intl.formatMessage({ id: "project.elements.geometricLayer.layerGroup.showLayers" })}
                                >
                                    {<FontAwesomeIcon icon={!formatExtraData.includes(row.id) ? faChevronCircleDown : faChevronCircleUp} />}
                                </span>
                                : ""
                            }
                        </div>
                    )
                },
                ];

                return (
                    <div className="card">
                        <br />
                        <div className="card-body">
                            <h3><FormattedMessage id="project.app.Header.admin.layerGroup" /></h3>
                            <div className="text-right">
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            backendErrors: null,
                                            addModifyGeometricLayerGroupModalShow: true,
                                            geometricLayerGroupToModify: null
                                        })
                                    }}
                                    id="addLayerGroup"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                    <FormattedMessage id="project.elements.geometricLayer.layerGroup.add" />
                                </button>
                            </div>

                            {/* Add/Modify geometric layer group dialog */}
                            <AddModifyGeometricLayerGroup
                                modalShow={this.state.addModifyGeometricLayerGroupModalShow}
                                geometricLayerGroupToModify={this.state.geometricLayerGroupToModify}
                                backendErrors={this.state.backendErrors}
                                setBackendErrors={setBackendErrors}
                                handleSubmit={() => {
                                    this.setState({
                                        addModifyGeometricLayerGroupModalShow: false,
                                        totalGeometricLayerGroup: this.props.totalGeometricLayerGroup
                                    });
                                    if (this.state.geometricLayerGroupToModify) {
                                        clearFilters(this.state.geometricLayerGroupToModify.id);
                                    } else {
                                        let newGeometricLayerGroup =
                                            this.props.totalGeometricLayerGroup[this.props.totalGeometricLayerGroup.length - 1];
                                        if (newGeometricLayerGroup && newGeometricLayerGroup.id) {
                                            clearFilters(newGeometricLayerGroup.id);
                                        } else {
                                            clearFilters();
                                        }
                                    }
                                }}
                                hideModalWindow={() => this.setState({ addModifyGeometricLayerGroupModalShow: false })}
                            />

                            {/* Delete geometric layer group dialog */}
                            <DeleteDialog
                                modalShow={this.state.deleteGeometricLayerGroupDialogShow}
                                title={this.props.intl.formatMessage({ id: 'project.common.delete' })}
                                details={this.props.intl.formatMessage(
                                    { id: 'project.elements.delete.message' },
                                    { element: <b>{this.state.deleteGeometricLayerGroupName}</b> })
                                }
                                backendErrors={this.state.backendErrors}
                                hideModalWindow={() => { this.setState({ deleteGeometricLayerGroupDialogShow: false }) }}
                                handleSubmit={() => {
                                    this.props.dispatch(actions.deleteGeometricLayerGroup(this.state.deleteGeometricLayerGroupId,
                                        () => {
                                            let newTotalPages = Math.ceil(this.props.totalGeometricLayerGroup.length / this.state.currentSize);
                                            if (newTotalPages > 0 && newTotalPages < this.state.currentPage) {
                                                // First the page is changed, then it sets the new data. If done at the same time, 
                                                // react-bootstrap-table-next will throw an exception
                                                this.setState(previousState => {
                                                    return {
                                                        currentPage: previousState.currentPage - 1,
                                                        deleteGeometricLayerGroupDialogShow: false
                                                    }
                                                }, () => this.setState({ totalGeometricLayerGroup: this.props.totalGeometricLayerGroup }));
                                            } else {
                                                this.setState({
                                                    deleteGeometricLayerGroupDialogShow: false,
                                                    totalGeometricLayerGroup: this.props.totalGeometricLayerGroup
                                                });
                                            }
                                        },
                                        (error) => setBackendErrors(error)))
                                }}
                                setBackendErrors={setBackendErrors}
                            />

                            <br />
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                rowClasses="text-left"
                                data={this.state.totalGeometricLayerGroup ?
                                    this.state.totalGeometricLayerGroup : []
                                }
                                noDataIndication={this.state.totalGeometricLayerGroup ?
                                    this.props.intl.formatMessage(
                                        { id: "project.elements.geometricLayer.layerGroup.noElements" }) :
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                }
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                                expandRow={expandRow}
                                filter={filterFactory()}
                                filterPosition={"top"}
                                striped
                                condensed
                                pagination={paginationFactory({
                                    sizePerPage: this.state.currentSize,
                                    showTotal: true,
                                    alwaysShowAllBtns: true,
                                    hidePageListOnlyOnePage: true,
                                    paginationTotalRenderer: (from, to, size) => {
                                        return <FormattedMessage id="project.common.tables.totalElements"
                                            values={{
                                                from: from,
                                                to: to,
                                                total: size
                                            }
                                            }
                                        />
                                    },
                                    onPageChange: (page, sizePerPage) => {
                                        this.setState({ sizePerPage, expandedRows: [] })
                                    },
                                    onSizePerPageChange: (sizePerPage, page) => {
                                        this.setState({ sizePerPage: sizePerPage })
                                    },
                                    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
                                        <li
                                            key={text}
                                            role="presentation"
                                            className={`dropdown-item ${this.state.sizePerPage === Number(text) ? "active" : ""}`}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSizePerPageChange(page);
                                            }}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {text}
                                        </li>
                                    ),
                                    nextPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.nextPageTitle' }),
                                    prePageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.prePageTitle' }),
                                    lastPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.lastPageTitle' }),
                                    firstPageTitle: this.props.intl.formatMessage({ id: 'project.common.pagination.firstPageTitle' }),
                                })}
                            />
                        </div>
                        <br />
                    </div>
                );
            }

            if (this.props.user.userRoleDto.code !== "ADMIN") {
                return (
                    <div className="container text-center">
                        <br />
                        <div className="card-body alert alert-danger" role="alert">
                            <h4 className="card-text"><FormattedMessage id="project.common.permissionDenied" /></h4>
                        </div>
                    </div>
                );
            }

            return null;

        }

        if (!this.props.user) {
            return (
                <div className="container text-center">
                    <br />
                    <div className="card-body alert alert-danger" role="alert">
                        <h4 className="card-text"><FormattedMessage id="project.common.mustLogin" /></h4>
                    </div>
                </div>
            );
        }

        return null;
    }

}

export default withRouter(connect(mapStateToProps)(injectIntl(AllGeometricLayerGroup)));