import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import jQuery from 'jquery';

const AddModifyGeometricElementConfirmation = ({ modalShow, functionToExecute, closeModalWindow, geometricElementConfirmationContactDataAlert }) => {

    useEffect(() => {
        jQuery('#geometricElementConfirmationAlert').html(geometricElementConfirmationContactDataAlert);
    });

    return (
        <Modal show={modalShow} onHide={closeModalWindow} scrollable={true} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="project.app.Body.accept" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="geometricElementConfirmationAlert" className="alert alert-warning" role="alert">
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button id="confirmContactData" type="submit" className="btn btn-primary"
                    onClick={() => {
                        functionToExecute();
                    }}
                >
                    <FormattedMessage id="project.app.Body.accept" />
                </button>
                <button id="cancelContactData" type="button" className="btn btn-danger"
                    onClick={() => {
                        closeModalWindow();
                    }}
                >
                    <FormattedMessage id="project.app.Body.cancel" />
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddModifyGeometricElementConfirmation;