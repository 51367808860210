import { Control } from 'ol/control';
import { getArea, getLength } from 'ol/sphere';
import * as actions from '../actions';
import jQuery from 'jquery';
import { Draw, Modify, Snap } from 'ol/interaction';
import { noModifierKeys } from "ol/events/condition";
import DragZoom from 'ol/interaction/DragZoom';
import { unByKey } from 'ol/Observable';
import { boundingExtent, buffer as createBuffer } from 'ol/extent';
import { transform } from 'ol/proj';
import autocomplete from 'autocompleter';
import { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_ELEMENT_TEXT_SINGULAR } from '../../app/components/InternationalizationRender';
import { VirtualSelect } from 'virtual-select-plugin/src/virtual-select'

const SEARCH_ACTIVATE_PROGRESSIVE = 'SEARCH_ACTIVATE_PROGRESSIVE';
const SEARCH_ACTIVATE_DIRECT = 'SEARCH_ACTIVATE_DIRECT';

const commonControlActions = (options, map) => {

  options.disableAllInteractions(map);
  options.hideAllOverlays(map);
  options.closeAddGeometricElementForm(map);
  options.source.forEachFeature(feature => {

    options.source.removeFeature(feature);

  });
  jQuery('#addGeometricElementSelector').prop("disabled", true);
  jQuery('#popupControllerSelectorDiv').removeClass('show');

}

const deactivateSearchControl = () => {

  jQuery('#centerEntityPopulationDiv').removeClass('ol-control-active');
  jQuery('#progressiveCenterDiv').removeClass('show');
  jQuery('#searchCenterDiv').removeClass('show');

}

const removeActiveClass = () => {

  jQuery('.ol-control-active').each(function () {

    if (jQuery(this).attr('id') !== 'centerEntityPopulationDiv') {

      jQuery(this).removeClass('ol-control-active');

    }

  });

}

const setElementsCursorToDefault = () => {

  jQuery('.layer-switcher').css('cursor', 'default');
  jQuery('.legend').css('cursor', 'default');
  jQuery('.ol-zoomslider').css('cursor', 'default');
  jQuery('.ol-popup').css('cursor', 'default');
  jQuery('.ol-overviewmap').css('cursor', 'default');

}

export const setCursorToEditGeometry = () => {

  var canvas = document.createElement('canvas');
  canvas.width = 24;
  canvas.height = 24;
  var context = canvas.getContext('2d');
  document.fonts.ready.then(() => {

    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(90 * Math.PI / 180);
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.font = '900 24px "Font Awesome 5 Free"';
    context.fillText('\uF303', 0, 0);
    var dataUrl = canvas.toDataURL('image/png');
    jQuery('#map').css('cursor', `url('${dataUrl}'), auto`);
  });

}

export const PopupController = (function (Control) {
  var options;
  var initialSelectedOptions = [];
  function PopupController(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.id = "popupControllerRootDiv";
    rootElement.className = 'ol-popup-control ol-unselectable ol-control';
    var button = document.createElement('button');

    button.innerHTML = '<i class="fas fa-info-circle"></i>';

    button.id = "poupcontrollerbutton";
    button.title = options.formatMessage({ id: "project.elements.map.popupTip" }, {
      plural: getInternationalization(options.locale, PARAMETER_ELEMENT_TEXT_PLURAL, options.allCodes, options.activeLanguages)
    });
    jQuery(button).attr('data-toggle', 'toggle');
    jQuery(button).attr('data-target', '#popupControllerSelectorDiv');

    var layerSelectorDiv = document.createElement('div');
    layerSelectorDiv.id = 'popupControllerSelectorDiv';
    var layerSelectorDivContent = document.createElement('div');
    layerSelectorDivContent.id = 'popupControllerSelectorDivContent';
    layerSelectorDivContent.style.width = '250px';
    layerSelectorDiv.appendChild(layerSelectorDivContent);

    var element = document.createElement('div');
    element.id = 'popupcontrollerdiv';
    element.appendChild(button);
    rootElement.appendChild(element);
    rootElement.appendChild(layerSelectorDiv);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    let selectOptions = [];
    options.map.getLayerGroup().getLayersArray().reverse().forEach((layer, index) => {
      if (layer.get('supportsGetFeatureInfo') || layer.get('isGeometricElementType')) {
        if (!selectOptions.find(value => value.label === layer.get('title'))) {
          selectOptions.push({ label: layer.get('title'), value: index })
          if (layer.get('isGeometricElementType')) {
            initialSelectedOptions.push(index);
          }
        }
      }
    });

    VirtualSelect.init({
      ele: layerSelectorDivContent,
      options: selectOptions,
      dropboxWrapper: '#map',
      dropboxWidth: layerSelectorDivContent.style.width,
      zIndex: 9999,
      multiple: true,
      selectedValue: initialSelectedOptions,
      placeholder: options.formatMessage({ id: "project.elements.map.popupControl.select.placeholder" }),
      noOptionsText: options.formatMessage({ id: "project.elements.map.popupControl.select.noOptionsText" }),
      noSearchResultsText: options.formatMessage({ id: "project.elements.map.popupControl.select.noSearchResultsText" }),
      selectAllText: options.formatMessage({ id: "project.elements.map.popupControl.select.selectAllText" }),
      searchPlaceholderText: options.formatMessage({ id: "project.elements.map.popupControl.select.searchPlaceholderText" }),
      optionSelectedText: options.formatMessage({ id: "project.elements.map.popupControl.select.optionSelectedText" }),
      optionsSelectedText: options.formatMessage({ id: "project.elements.map.popupControl.select.optionsSelectedText" }),
      allOptionsSelectedText: options.formatMessage({ id: "project.elements.map.popupControl.select.allOptionsSelectedText" }),
      clearButtonText: options.formatMessage({ id: "project.elements.map.popupControl.select.clearButtonText" }),
      moreText: options.formatMessage({ id: "project.elements.map.popupControl.select.moreText" })
    });

    button.addEventListener('click', this.handlePopupController.bind(this), false);

    if (options.initiallyEnabled) {

      element.classList.add('ol-control-active');
      layerSelectorDiv.classList.add('show');
      jQuery('#map').css('cursor', 'help');
      setElementsCursorToDefault();

    }

  }

  if (Control) PopupController.__proto__ = Control;
  PopupController.prototype = Object.create(Control && Control.prototype);
  PopupController.prototype.constructor = PopupController;

  PopupController.prototype.handlePopupController = function handlePopupController() {

    commonControlActions(options, this.getMap());

    if (jQuery('#popupcontrollerdiv').hasClass('ol-control-active')) {

      removeActiveClass();
      jQuery('#map').css('cursor', 'default');
      jQuery('#popupControllerSelectorDiv').removeClass('show');

    } else {

      removeActiveClass();
      jQuery('#popupcontrollerdiv').addClass('ol-control-active');
      jQuery('#map').css('cursor', 'help');
      setElementsCursorToDefault();
      jQuery('#popupControllerSelectorDiv').addClass('show');

    }

  }

  return PopupController;
}(Control));

export const AddGeometricElementController = (function (Control) {
  var options = null;
  function AddGeometricElementController(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.className = 'ol-add-geometric-element-control ol-unselectable ol-control';
    rootElement.id = 'addGeometricElementRootDiv';

    var selector = document.createElement('select');
    selector.id = 'addGeometricElementSelector';
    selector.disabled = true;
    var selectorOptions = "";
    selector.className = 'ol-unselectable ol-control';
    options.allGeometricElementType.forEach(geometricElementType => {

      if ((geometricElementType.geometryType === "ANY_GEOMETRY") ||
        geometricElementType.geometryType.includes("POINT")) {

        selectorOptions += '<option value="' + geometricElementType.id + '-Point">' +
          getInternationalization(options.locale, geometricElementType.code, options.allCodes, options.activeLanguages) +
          ' - ' + options.formatMessage({ id: "project.elements.geom.Point" }) + '</option>';

      }
      if ((geometricElementType.geometryType === "ANY_GEOMETRY") ||
        geometricElementType.geometryType.includes("POLYGON")) {

        selectorOptions += '<option value="' + geometricElementType.id + '-Polygon">' +
          getInternationalization(options.locale, geometricElementType.code, options.allCodes, options.activeLanguages) +
          ' - ' + options.formatMessage({ id: "project.elements.geom.Polygon" }) + '</option>';

      }

      if ((geometricElementType.geometryType === "ANY_GEOMETRY") ||
        geometricElementType.geometryType.includes("LINE")) {

        selectorOptions += '<option value="' + geometricElementType.id + '-LineString">' +
          getInternationalization(options.locale, geometricElementType.code, options.allCodes, options.activeLanguages) +
          ' - ' + options.formatMessage({ id: "project.elements.geom.LineString" }) + '</option>';

      }

    });

    selector.innerHTML = selectorOptions;

    var element = document.createElement('div');
    element.id = "addGeometricElementDiv";

    var button = document.createElement('button');

    button.innerHTML = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor">' +
      '<path d="M20 15h4.071v2h-4.071v4.071h-2v-4.071h-4.071v-2h4.071v-4.071h2v4.071zm-8 6h-12v-2h12v2zm0-4.024h-12v-2h12v2zm0-3.976h-12v-2h12v2zm12-' +
      '4h-24v-2h24v2zm0-4h-24v-2h24v2z"/></svg>';

    button.id = "addGeometricElementButton";
    button.title = options.formatMessage({ id: "project.elements.map.addElementTip" }, {
      singular: getInternationalization(options.locale, PARAMETER_ELEMENT_TEXT_SINGULAR, options.allCodes, options.activeLanguages)
    });

    if (options.allGeometricElementType.length === 0) {
      button.disabled = true;
    }

    element.appendChild(button);

    rootElement.appendChild(element);
    rootElement.appendChild(selector);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    button.addEventListener('click', this.handleAddGeometricElementController.bind(this), false);
    selector.addEventListener('change', this.handleAddGeometricElementSelector.bind(this), false);
  }

  if (Control) AddGeometricElementController.__proto__ = Control;
  AddGeometricElementController.prototype = Object.create(Control && Control.prototype);
  AddGeometricElementController.prototype.constructor = AddGeometricElementController;

  AddGeometricElementController.prototype.handleAddGeometricElementController = function handleAddGeometricElementController(e) {

    commonControlActions(options, this.getMap());

    if (!jQuery('#addGeometricElementRootDiv').hasClass('ol-control-active')) {

      jQuery('#map').css('cursor', 'default');
      removeActiveClass();
      jQuery('#addGeometricElementRootDiv').addClass('ol-control-active');
      jQuery('#addGeometricElementSelector').prop("disabled", false);
      jQuery('.layer-switcher').removeClass('shown');
      jQuery('.layer-switcher button').text("");
      setCursorToEditGeometry();

      let drawControl = new Draw({
        source: options.source,
        type: jQuery('#addGeometricElementSelector').val().split("-")[1],
        stopClick: true
      });

      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(drawControl, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(drawControl);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      let map = this.getMap();
      modifyInteraction.setActive(false);
      options.dispatch(actions.findGeometricElementTypeById(jQuery("#addGeometricElementSelector").val().split("-")[0]));

      if (options.enableGeographicalViewerInsertionToast) {

        map.addControl(options.insertInfoToastGeometry);

      }

      drawControl.on('drawend', function (event) {
        var feature = event.feature;
        var featureToTransform = feature.clone();
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326');
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
        options.openAddGeometricElementForm(map, geometry.transform('EPSG:4326', 'EPSG:3857'));
        drawControl.setActive(false);
        modifyInteraction.setActive(true);
        if (options.enableGeographicalViewerInsertionToast) {

          map.removeControl(options.insertInfoToastGeometry);

        }
        if (options.enableGeographicalViewerModificationToast) {

          map.addControl(options.modifyInfoToastGeometry);

        }
      });

      modifyInteraction.on('modifyend', function (event) {
        var features = event.features;
        var featureToTransform = features.array_[0].clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
      });

    } else {

      removeActiveClass();
      jQuery('#map').css('cursor', 'default');

    }

  }

  AddGeometricElementController.prototype.handleAddGeometricElementSelector = function handleAddGeometricElementSelector(e) {
    options.source.forEachFeature(feature => {
      options.source.removeFeature(feature);
    });
    options.disableAllInteractions(this.getMap());
    options.closeAddGeometricElementForm(this.getMap());
    setCursorToEditGeometry();

    if (jQuery('#addGeometricElementRootDiv').hasClass('ol-control-active')) {
      let drawControl = new Draw({
        source: options.source,
        type: jQuery('#addGeometricElementSelector').val().split("-")[1],
        stopClick: true
      });

      let modifyInteraction = new Modify({
        source: options.source
      });

      let snapInteraction = new Snap({
        source: options.source
      })

      options.setInteractions(drawControl, null, modifyInteraction, snapInteraction);

      this.getMap().addInteraction(drawControl);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      let map = this.getMap();
      modifyInteraction.setActive(false);
      options.dispatch(actions.findGeometricElementTypeById(jQuery("#addGeometricElementSelector").val().split("-")[0]));

      if (options.enableGeographicalViewerInsertionToast) {

        map.addControl(options.insertInfoToastGeometry);

      }
      drawControl.on('drawend', function (event) {
        var feature = event.feature;
        var featureToTransform = feature.clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
        options.openAddGeometricElementForm(map, geometry.transform('EPSG:4326', 'EPSG:3857'));
        geometry.transform('EPSG:4326', 'EPSG:3857')
        drawControl.setActive(false);
        modifyInteraction.setActive(true);
        if (options.enableGeographicalViewerInsertionToast) {

          map.removeControl(options.insertInfoToastGeometry);

        }
        if (options.enableGeographicalViewerModificationToast) {

          map.addControl(options.modifyInfoToastGeometry);

        }
      });

      modifyInteraction.on('modifyend', function (event) {
        var features = event.features;
        var featureToTransform = features.array_[0].clone()
        var geometry = featureToTransform.getGeometry();
        geometry.transform('EPSG:3857', 'EPSG:4326')
        options.handleGeom({
          "type": `${geometry.getType()}`,
          "coordinates": geometry.getCoordinates()
        });
      });
    }

  }

  return AddGeometricElementController;

}(Control));

export const AreaMeasureController = (function (Control) {
  var options = null;

  function AreaMeasureController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">' +
      '<path d="M22 15v2h-1v-14h-14v-1h2v-2h-6v3h-3v2h3v16h16v3h2v-3h3v-6h-2zm-3 2h-1v-2h-2v2h-1v-2h-2v2h-1v-2h-2v2h-1v-2h-2v-1h2v-2h' +
      '-2v-1h2v-2h-2v-1h2v-2h-2v-1h12v12z"/></svg>';
    button.title = options.formatMessage({ id: "project.elements.map.measureAreaTip" });

    var element = document.createElement('div');
    element.className = 'ol-measure-area-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleAreaMeasureController.bind(this), false);

  }

  if (Control) AreaMeasureController.__proto__ = Control;
  AreaMeasureController.prototype = Object.create(Control && Control.prototype);
  AreaMeasureController.prototype.constructor = AreaMeasureController;

  AreaMeasureController.prototype.handleAreaMeasureController = function handleAreaMeasureController() {

    commonControlActions(options, this.getMap());

    if (jQuery('.ol-measure-area-control').hasClass('ol-control-active')) {

      jQuery('.ol-measure-area-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');

    } else {

      var areaMeasureDraw = new Draw({
        source: options.source,
        type: 'Polygon',
        stopClick: true
      });
      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(areaMeasureDraw, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(areaMeasureDraw);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      removeActiveClass();
      jQuery('.ol-measure-area-control').addClass('ol-control-active');
      jQuery('#map').css('cursor', 'crosshair');
      setElementsCursorToDefault();

      let formatArea = function (polygon) {
        var area = getArea(polygon);
        var output;
        if (area > 1000000) {
          output = (Math.round(area / 1000000 * 100) / 100) + ' km<sup>2</sup>';
        } else {
          if (area > 10000) {
            output = (Math.round(area / 10000 * 100) / 100) + ' ha'
          } else {
            output = (Math.round(area * 100) / 100) + ' m<sup>2</sup>'
          }
        }
        return output;
      }

      var feature;
      var listener;

      areaMeasureDraw.on('drawstart', function (event) {
        modifyInteraction.setActive(false);
        snapInteraction.setActive(false);
        options.source.forEachFeature(feature => {
          options.source.removeFeature(feature);
        });
        feature = event.feature;
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatArea(geom);
          options.overlay.setPosition(geom.getInteriorPoint().getCoordinates())
        });
      })

      areaMeasureDraw.on('drawend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
        modifyInteraction.setActive(true);
        snapInteraction.setActive(true);
      });

      modifyInteraction.on('modifystart', function (event) {
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatArea(geom);
          options.overlay.setPosition(geom.getInteriorPoint().getCoordinates())
        });
      });

      modifyInteraction.on('modifyend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
      });
    }

  }

  return AreaMeasureController;
}(Control));

export const LengthMeasureController = (function (Control) {
  var options = null;

  function LengthMeasureController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">' +
      '<path d="M18.342 0l-2.469 2.47 2.121 2.121-.707.707-2.121-2.121-1.414 1.414 1.414 1.414-.707.707-1.414-1.414-1.414 1.414 1.414' +
      ' 1.414-.707.707-1.414-1.414-1.414 1.414 2.121 2.122-.707.707-2.121-2.121-1.414 1.414 1.414 1.414-.708.707-1.414-1.414-1.414 1.414' +
      ' 1.414 1.414-.708.709-1.414-1.414-1.414 1.413 2.121 2.121-.706.706-2.122-2.121-2.438 2.439 5.656 5.657 18.344-18.343z"/></svg>';

    button.title = options.formatMessage({ id: "project.elements.map.measureLengthTip" });

    var element = document.createElement('div');
    element.className = 'ol-measure-length-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleLengthMeasureController.bind(this), false);

  }

  if (Control) LengthMeasureController.__proto__ = Control;
  LengthMeasureController.prototype = Object.create(Control && Control.prototype);
  LengthMeasureController.prototype.constructor = LengthMeasureController;

  LengthMeasureController.prototype.handleLengthMeasureController = function handleLengthMeasureController() {

    commonControlActions(options, this.getMap());

    if (jQuery('.ol-measure-length-control').hasClass('ol-control-active')) {

      jQuery('.ol-measure-length-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');

    } else {

      var lengthMeasureDraw = new Draw({
        source: options.source,
        type: 'LineString',
        stopClick: true
      });
      let modifyInteraction = new Modify({
        source: options.source
      });
      let snapInteraction = new Snap({
        source: options.source
      });
      options.setInteractions(lengthMeasureDraw, null, modifyInteraction, snapInteraction);
      this.getMap().addInteraction(lengthMeasureDraw);
      this.getMap().addInteraction(modifyInteraction);
      this.getMap().addInteraction(snapInteraction);
      removeActiveClass();
      jQuery('#map').css('cursor', 'crosshair');
      setElementsCursorToDefault();
      jQuery('.ol-measure-length-control').addClass('ol-control-active');

      let formatLength = function (line) {
        var length = getLength(line);
        var output;
        if (length > 1000) {
          output = (Math.round(length / 1000 * 100) / 100) + ' km';
        } else {
          output = (Math.round(length * 100) / 100) + ' m'
        }

        return output;
      }

      var feature;
      var listener;

      lengthMeasureDraw.on('drawstart', function (event) {
        modifyInteraction.setActive(false);
        snapInteraction.setActive(false);
        options.source.forEachFeature(feature => {
          options.source.removeFeature(feature);
        });
        feature = event.feature
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatLength(geom);
          options.overlay.setPosition(geom.getLastCoordinate());
        })
      })

      lengthMeasureDraw.on('drawend', function (event) {
        options.overlay.setOffset([0, -7]);
        unByKey(listener);
        modifyInteraction.setActive(true);
        snapInteraction.setActive(true);
      })

      modifyInteraction.on('modifystart', function (event) {
        listener = feature.getGeometry().on('change', function (event) {
          var geom = event.target;
          options.overlayElement.innerHTML = formatLength(geom);
          options.overlay.setPosition(geom.getLastCoordinate());
        })
        event.features = null;
      })

      modifyInteraction.on('modifyend', function (event) {
        options.overlay.setOffset([0, -7]);
        event.features = null;
        unByKey(listener);
      })

    }

  }

  return LengthMeasureController;
}(Control));

export const ZoomToExtentController = (function (Control) {
  var options = null;

  function ZoomToExtentController(opt_options) {
    options = opt_options || {};

    var button = document.createElement('button');

    button.innerHTML = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1em" height="1em" viewBox="0 0 260.000000 260.000000"
    preserveAspectRatio="xMidYMid meet" fill="currentColor">

    <g transform="translate(0.000000,260.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M171 2466 c-52 -29 -50 -5 -51 -833 0 -578 3 -779 12 -798 24 -52 42
    -55 366 -55 l299 0 36 -54 c20 -30 59 -77 87 -105 254 -254 653 -293 949 -92
    l63 43 237 -236 c168 -168 243 -236 259 -236 24 0 72 41 72 60 0 30 -46 82
    -249 284 -122 121 -221 222 -221 224 0 1 19 31 42 65 169 249 169 585 0 834
    -53 78 -128 153 -198 200 l-54 36 0 299 c0 324 -3 342 -55 366 -40 18 -1561
    16 -1594 -2z m1509 -356 c0 -132 -3 -240 -8 -240 -4 0 -39 7 -77 15 -237 50
    -499 -30 -675 -205 -175 -176 -255 -438 -205 -675 8 -38 15 -73 15 -77 0 -5
    -108 -8 -240 -8 l-240 0 0 715 0 715 715 0 715 0 0 -240z m-170 -490 c19 -19
    20 -33 20 -220 l0 -200 195 0 c182 0 196 -1 215 -20 11 -11 20 -31 20 -45 0
    -14 -9 -34 -20 -45 -19 -19 -33 -20 -215 -20 l-195 0 0 -195 c0 -182 -1 -196
    -20 -215 -11 -11 -31 -20 -45 -20 -14 0 -34 9 -45 20 -19 19 -20 33 -20 215
    l0 195 -200 0 c-187 0 -201 1 -220 20 -11 11 -20 31 -20 45 0 14 9 34 20 45
    19 19 33 20 220 20 l200 0 0 200 c0 187 1 201 20 220 11 11 31 20 45 20 14 0
    34 -9 45 -20z"/>
    </g>
    </svg>`;

    button.title = options.formatMessage({ id: "project.elements.map.zoomToExtentTip" });

    var element = document.createElement('div');
    element.className = 'ol-zoom-extent-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleZoomToExtentController.bind(this), false);

  }

  if (Control) ZoomToExtentController.__proto__ = Control;
  ZoomToExtentController.prototype = Object.create(Control && Control.prototype);
  ZoomToExtentController.prototype.constructor = ZoomToExtentController;

  ZoomToExtentController.prototype.handleZoomToExtentController = function handleZoomToExtentController() {

    commonControlActions(options, this.getMap());

    if (jQuery('.ol-zoom-extent-control').hasClass('ol-control-active')) {

      jQuery('.ol-zoom-extent-control').removeClass('ol-control-active');
      jQuery('#map').css('cursor', 'default');

    } else {

      var dragZoom = new DragZoom({ condition: noModifierKeys });
      options.setInteractions(null, dragZoom, null, null);
      this.getMap().addInteraction(dragZoom);
      removeActiveClass();
      jQuery('#map').css('cursor', 'crosshair');
      setElementsCursorToDefault();
      jQuery('.ol-zoom-extent-control').addClass('ol-control-active');

    }

  }

  return ZoomToExtentController;
}(Control));

//Center from population entity

export const EntityPopulationCenterControl = (function (Control) {
  var options = null;
  var searchType = "PROGRESSIVE";
  function EntityPopulationCenterControl(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.className = 'ol-center-entity-population ol-unselectable ol-control';
    rootElement.id = 'centerEntityPopulationRootDiv';

    var element = document.createElement('div');
    element.className = 'btn-group dropright';
    element.id = "centerEntityPopulationDiv";

    if ([SEARCH_ACTIVATE_PROGRESSIVE, SEARCH_ACTIVATE_DIRECT].includes(options.defaultActivate)) {

      element.classList.add('ol-control-active');

    }

    var button = document.createElement('button');

    button.innerHTML = '<i class="fas fa-search-location"></i>';

    button.id = "centerEntityPopulationButton";
    button.title = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    jQuery(button).attr('data-toggle', 'toggle');
    jQuery(button).attr('data-target', '#search');

    var expandButton = document.createElement('button');
    expandButton.id = "centerEntityPopulationExpandButton";
    expandButton.className = "dropdown-toggle dropdown-toggle-split";
    expandButton.title = options.formatMessage({ id: 'project.elements.map.searchType' });
    jQuery(expandButton).attr('data-toggle', 'dropdown');
    jQuery(expandButton).attr('aria-haspopup', 'true');
    jQuery(expandButton).attr('aria-expanded', 'false');

    var dropdownMenu = document.createElement('div');
    dropdownMenu.className = "dropdown-menu";

    var firstDropdownItem = document.createElement('a');
    var secondDropdownItem = document.createElement('a');

    firstDropdownItem.id = "progressiveCenter";
    secondDropdownItem.id = "searchCenter";

    firstDropdownItem.className = "btn dropdown-item";
    secondDropdownItem.className = "btn dropdown-item";

    firstDropdownItem.innerHTML = options.formatMessage({ id: 'project.elements.map.progressiveCenter' });
    secondDropdownItem.innerHTML = options.formatMessage({ id: 'project.elements.map.searchCenter' });

    //Progressive center elements
    let progressiveCenterDiv = document.createElement('div');
    progressiveCenterDiv.id = 'progressiveCenterDiv';

    if (options.defaultActivate === SEARCH_ACTIVATE_PROGRESSIVE) {

      searchType = 'PROGRESSIVE';
      jQuery(progressiveCenterDiv).css('transition', 'max-width .8s ease-in');
      jQuery(progressiveCenterDiv).addClass('show');

    }

    var selector1 = document.createElement('select');
    var selector2 = document.createElement('select');
    var selector3 = document.createElement('select');
    var selector4 = document.createElement('select');

    var centerProgressiveButton = document.createElement('button');
    centerProgressiveButton.innerHTML = '<i class="fas fa-map-marked"></i>';
    centerProgressiveButton.id = "center";
    centerProgressiveButton.title = options.formatMessage({ id: 'project.common.center' });
    let selector1Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.provinces" }) + '</option>';
    options.listProvinces.forEach(prov => {
      selector1Html += '<option value="' + prov.c + '">' + prov.n + '</option>';
    });
    selector1.innerHTML = selector1Html;
    selector2.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.councils" }) + '</option>';
    selector3.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.parishes" }) + '</option>';
    selector4.innerHTML = '<option value="">' + options.formatMessage({ id: "project.elements.map.entityPopulations" }) + '</option>';
    selector1.id = 'centerProvince';
    selector2.id = 'centerCouncil';
    selector3.id = 'centerParish';
    selector4.id = 'centerEntityPopulation';
    selector1.title = options.formatMessage({ id: "project.elements.map.provinces" });
    selector2.title = options.formatMessage({ id: "project.elements.map.councils" });
    selector3.title = options.formatMessage({ id: "project.elements.map.parishes" });
    selector4.title = options.formatMessage({ id: "project.elements.map.entityPopulations" });
    selector2.disabled = true;
    selector3.disabled = true;
    selector4.disabled = true;
    progressiveCenterDiv.appendChild(selector1);
    progressiveCenterDiv.appendChild(selector2);
    progressiveCenterDiv.appendChild(selector3);
    progressiveCenterDiv.appendChild(selector4);
    progressiveCenterDiv.appendChild(centerProgressiveButton);

    //Search center elements 
    let searchCenterDiv = document.createElement('div');
    searchCenterDiv.id = 'searchCenterDiv';

    if (options.defaultActivate === SEARCH_ACTIVATE_DIRECT) {

      searchType = 'SEARCH';
      jQuery(searchCenterDiv).css('transition', 'max-width .8s ease-in');
      jQuery(searchCenterDiv).addClass('show');

    }

    var centerSearchButton = document.createElement('button');
    centerSearchButton.innerHTML = '<i class="fas fa-map-marked"></i>';
    centerSearchButton.id = "center";
    centerSearchButton.title = options.formatMessage({ id: 'project.common.center' });

    var input = document.createElement('input');
    input.placeholder = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    input.title = options.formatMessage({ id: 'project.elements.map.searchEntityPopulation' });
    input.id = 'centerSearchInput';
    searchCenterDiv.appendChild(input);
    searchCenterDiv.appendChild(centerSearchButton);

    dropdownMenu.appendChild(firstDropdownItem);
    dropdownMenu.appendChild(secondDropdownItem);

    element.appendChild(button);
    element.appendChild(expandButton);
    element.appendChild(dropdownMenu);

    rootElement.appendChild(element);
    rootElement.appendChild(progressiveCenterDiv);
    rootElement.appendChild(searchCenterDiv);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    button.addEventListener('click', this.handleEntityPopulationCenterControl.bind(this), false);
    firstDropdownItem.addEventListener('click', this.handleFirstDropdownItemControl.bind(this), false);
    secondDropdownItem.addEventListener('click', this.handleSecondDropdownItemControl.bind(this), false);
    selector1.addEventListener('change', this.handleSelector1Control.bind(this), false);
    selector2.addEventListener('change', this.handleSelector2Control.bind(this), false);
    selector3.addEventListener('change', this.handleSelector3Control.bind(this), false);
    input.addEventListener('keyup', this.handleInputSearch.bind(this), false);
    centerProgressiveButton.addEventListener('click', this.handleCenterProgressiveButton.bind(this), false);
    centerSearchButton.addEventListener('click', this.handleCenterSearchButton.bind(this), false);
  }

  if (Control) EntityPopulationCenterControl.__proto__ = Control;
  EntityPopulationCenterControl.prototype = Object.create(Control && Control.prototype);
  EntityPopulationCenterControl.prototype.constructor = EntityPopulationCenterControl;

  let firstStateHandler = () => {
    jQuery('#centerProvince').val('').change();
    jQuery('#centerCouncil').val('').change();
    jQuery('#centerParish').val('').change();
    jQuery('#centerEntityPopulation').val('').change();
    jQuery('#centerCouncil').attr('disabled', true);
    jQuery('#centerParish').attr('disabled', true);
    jQuery('#centerEntityPopulation').attr('disabled', true);
    jQuery('#centerSearchInput').val("").change();
  }

  EntityPopulationCenterControl.prototype.handleEntityPopulationCenterControl = function handleEntityPopulationCenterControl(e) {

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      firstStateHandler();
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      if (searchType === "PROGRESSIVE") {
        jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
        jQuery('#progressiveCenterDiv').addClass('show');
      }
      if (searchType === "SEARCH") {
        jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
        jQuery('#searchCenterDiv').addClass('show');
      }
    } else {
      firstStateHandler();
      deactivateSearchControl();
      jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#progressiveCenterDiv').removeClass('show');
      jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
      jQuery('#searchCenterDiv').removeClass('show');
    }

  }

  EntityPopulationCenterControl.prototype.handleFirstDropdownItemControl = function handleFirstDropdownItemControl(e) {

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      jQuery('#progressiveCenterDiv').css('transition', 'max-width .8s ease-in');
    } else {
      jQuery('#searchCenterDiv').css('transition', 'none');
      jQuery('#progressiveCenterDiv').css('transition', 'none');
    }

    searchType = 'PROGRESSIVE';
    firstStateHandler();
    jQuery('#searchCenterDiv').removeClass('show');
    jQuery('#progressiveCenterDiv').addClass('show');

  }

  EntityPopulationCenterControl.prototype.handleSecondDropdownItemControl = function handleSecondDropdownItemControl(e) {

    if (!jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      jQuery('#centerEntityPopulationDiv').addClass('ol-control-active');
      jQuery('#searchCenterDiv').css('transition', 'max-width .8s ease-in');
    } else {
      jQuery('#progressiveCenterDiv').css('transition', 'none');
      jQuery('#searchCenterDiv').css('transition', 'none');
    }

    searchType = 'SEARCH';
    jQuery('#progressiveCenterDiv').removeClass('show');
    jQuery('#searchCenterDiv').addClass('show');

  }

  EntityPopulationCenterControl.prototype.handleSelector1Control = function handleSelector1Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      if (jQuery('#centerProvince').val() !== "") {
        let councils = jQuery('#centerCouncil');
        let parishes = jQuery('#centerParish');
        let entityPopulations = jQuery('#centerEntityPopulation');
        let selector2Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.councils" }) + '</option>';
        options.listCouncils.forEach(coun => {
          if (coun.c.startsWith(jQuery('#centerProvince').val())) {
            selector2Html += '<option value="' + coun.c + '">' + coun.n + '</option>';
          }
        });
        councils.html(selector2Html);
        councils.attr('disabled', false);
        parishes.val("").change();
        parishes.attr("disabled", true);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      } else {
        firstStateHandler();
      }
    }

  }

  EntityPopulationCenterControl.prototype.handleSelector2Control = function handleSelector2Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      let parishes = jQuery('#centerParish');
      let entityPopulations = jQuery('#centerEntityPopulation');
      if (jQuery('#centerCouncil').val() !== "") {
        let selector3Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.parishes" }) + '</option>';
        options.listParishes.forEach(par => {
          if (par.c.startsWith(jQuery('#centerCouncil').val())) {
            selector3Html += '<option value="' + par.c + '">' + par.n + '</option>';
          }
        });
        parishes.html(selector3Html);
        parishes.attr('disabled', false);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      } else {
        parishes.val("").change();
        parishes.attr('disabled', true);
        entityPopulations.val("").change();
        entityPopulations.attr("disabled", true);
      }
    }

  }

  EntityPopulationCenterControl.prototype.handleSelector3Control = function handleSelector3Control(e) {

    if (jQuery('#centerEntityPopulationDiv').hasClass('ol-control-active')) {
      let entityPopulations = jQuery('#centerEntityPopulation');
      if (jQuery('#centerParish').val() !== "") {
        let selector4Html = '<option value="">' + options.formatMessage({ id: "project.elements.map.entityPopulations" }) + '</option>';
        options.listEntityPopulations.forEach(ep => {
          if (ep.c.startsWith(jQuery('#centerParish').val())) {
            selector4Html += '<option value="' + ep.b + '">' + ep.n + '</option>';
          }
        });
        entityPopulations.html(selector4Html);
        entityPopulations.attr('disabled', false);
      } else {
        entityPopulations.val("").change();
        entityPopulations.attr('disabled', true);
      }
    }

  }

  let createBoundingExtent = (bbox, buffer) => {
    bbox = bbox.split(",");
    bbox = bbox.map(coordinate => coordinate.split(" "));
    bbox = bbox.map(coordinate => coordinate.map(Number));
    let bboxExtent = boundingExtent([
      transform(bbox[0], 'EPSG:4326', 'EPSG:3857'),
      transform(bbox[1], 'EPSG:4326', 'EPSG:3857')
    ]);

    return createBuffer(bboxExtent, buffer);
  }

  EntityPopulationCenterControl.prototype.handleCenterProgressiveButton = function handleCenterProgressiveButton(e) {
    let provinces = jQuery('#centerProvince');
    let councils = jQuery('#centerCouncil');
    let parishes = jQuery('#centerParish');
    let entityPopulations = jQuery('#centerEntityPopulation');

    if (provinces.val() !== "" && councils.val() === "" && parishes.val() === "" && entityPopulations.val() === "") {
      let province = options.listProvinces.filter(province => province.c === provinces.val());
      this.getMap().getView().fit(
        createBoundingExtent(province[0].b, options.provincesBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() === "" && entityPopulations.val() === "") {
      let council = options.listCouncils.filter(council => council.c === councils.val());
      this.getMap().getView().fit(
        createBoundingExtent(council[0].b, options.councilsBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() !== "" && entityPopulations.val() === "") {
      let parish = options.listParishes.filter(parish => parish.c === parishes.val());
      this.getMap().getView().fit(
        createBoundingExtent(parish[0].b, options.parishesBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else if (provinces.val() !== "" && councils.val() !== "" && parishes.val() !== "" && entityPopulations.val() !== "") {
      this.getMap().getView().fit(
        createBoundingExtent(entityPopulations.val(), options.entityPopulationsBuffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    }
  }

  let searchCenterBbox = "";
  let buffer = 100;

  EntityPopulationCenterControl.prototype.handleInputSearch = function handleInputSearch(e) {
    searchCenterBbox = "";
    autocomplete({
      minLength: 3,
      input: document.getElementById('centerSearchInput'),
      fetch: function (text, update) {
        text = text.toLowerCase();
        var suggestions = options.listProvinces.filter(val => {
          return val.n.toLowerCase().includes(text)
        });
        suggestions = options.listCouncils.filter(val => {
          return val.n.toLowerCase().includes(text)
        });
        suggestions = suggestions.concat(options.listParishes.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        suggestions = suggestions.concat(options.listEntityPopulations.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        suggestions = suggestions.concat(options.listCounties.filter(val => {
          return val.n.toLowerCase().includes(text)
        }));
        update(suggestions);
      },
      render: function (item, currentValue) {
        var div = document.createElement('div');
        if (item.t === "EP" || item.t === "PA") {
          let councilCode = item.c.substring(0, 5);
          let councilName = options.listCouncils.filter(val => {
            return val.c === councilCode
          });
          item.label = "* " + item.n + " - " + councilName[0].n
        } else {
          item.label = "* " + item.n;
        }
        div.textContent = item.label;
        if (item.t === "PR") {
          item.group = options.formatMessage({ id: "project.elements.map.provinces" });
        } else if (item.t === "MU") {
          item.group = options.formatMessage({ id: "project.elements.map.councils" });
        } else if (item.t === "PA") {
          item.group = options.formatMessage({ id: "project.elements.map.parishes" });
        } else if (item.t === "EP") {
          item.group = options.formatMessage({ id: "project.elements.map.entityPopulations" });
        } else if (item.t === "CO") {
          item.group = options.formatMessage({ id: "project.elements.map.counties" });
        }

        return div;
      },
      onSelect: function (item, input) {
        input.value = item.label.substring(2, item.label.length);
        if (item.t === "PR") {
          buffer = options.provincesBuffer;
        } else if (item.t === "MU") {
          buffer = options.councilsBuffer;
        } else if (item.t === "PA") {
          buffer = options.parishesBuffer;
        } else if (item.t === "EP") {
          buffer = options.entityPopulationsBuffer;
        } else if (item.t === "CO") {
          buffer = options.countiesBuffer;
        }
        searchCenterBbox = item.b;
        jQuery('div.autocomplete').hide();
      },

    });
  }

  EntityPopulationCenterControl.prototype.handleCenterSearchButton = function handleCenterSearchButton(e) {
    jQuery('#centerSearchInput').removeClass('inputError');
    if (searchCenterBbox !== "") {
      this.getMap().getView().fit(
        createBoundingExtent(searchCenterBbox, buffer),
        {
          size: this.getMap().getSize(),
          duration: 100
        }
      );
    } else {
      // Get input text, delete council name (if entity population or parish) and text to lower case
      let text = document.getElementById('centerSearchInput').value;
      if (text.split("-").length > 0) {
        text = text.split("-")[0].trim();
      }
      text = text.toLowerCase();

      let provinceSearch = options.listProvinces.filter(province => province.n.toLowerCase().includes(text));
      // Search on provinces
      if (provinceSearch.length > 0) {
        this.getMap().getView().fit(
          createBoundingExtent(provinceSearch[0].b, options.provincesBuffer),
          {
            size: this.getMap().getSize(),
            duration: 100
          }
        );
        // If not province, search on counties
      } else {
        let countySearch = options.listCounties.filter(county => county.n.toLowerCase().includes(text));
        if (countySearch.length > 0) {
          this.getMap().getView().fit(
            createBoundingExtent(countySearch[0].b, options.countiesBuffer),
            {
              size: this.getMap().getSize(),
              duration: 100
            }
          );
          // If not county, search on councils
        } else {
          let councilSearch = options.listCouncils.filter(council => council.n.toLowerCase().includes(text));
          if (councilSearch.length > 0) {
            this.getMap().getView().fit(
              createBoundingExtent(councilSearch[0].b, options.councilsBuffer),
              {
                size: this.getMap().getSize(),
                duration: 100
              }
            );
            // If not council, search on parishes
          } else {
            let parishSearch = options.listParishes.filter(parish => parish.n.toLowerCase().includes(text));
            if (parishSearch.length > 0) {
              this.getMap().getView().fit(
                createBoundingExtent(parishSearch[0].b, options.parishesBuffer),
                {
                  size: this.getMap().getSize(),
                  duration: 100
                }
              );
              // If not parish, search on population entities
            } else {
              let populationEntitySearch = options.listEntityPopulations.filter(ep => ep.n.toLowerCase().includes(text));
              if (populationEntitySearch.length > 0) {
                this.getMap().getView().fit(
                  createBoundingExtent(populationEntitySearch[0].b, options.entityPopulationsBuffer),
                  {
                    size: this.getMap().getSize(),
                    duration: 100
                  }
                );
                // If not population entity, show error
              } else {
                jQuery('#centerSearchInput').addClass('inputError');
              }
            }
          }
        }
      }
    }
  }

  return EntityPopulationCenterControl;

}(Control));

// Show legend control
export const ShowLegendControl = (function (Control) {
  var options = null;

  function ShowLegendControl(opt_options) {
    options = opt_options || {};

    var rootElement = document.createElement('div');
    rootElement.className = 'ol-legend-control ol-control';

    var button = document.createElement('button');

    button.innerHTML = 'L';
    button.title = options.formatMessage({ id: "project.elements.map.legendTip" });

    var element = document.createElement('div');
    element.className = 'ol-show-legend-control ol-unselectable ol-control';
    element.appendChild(button);

    rootElement.appendChild(element);
    rootElement.appendChild(options.legendComponent);

    Control.call(this, {
      element: rootElement,
      target: options.target
    });

    button.addEventListener('click', this.handleShowLegendControl.bind(this), false);

  }

  if (Control) ShowLegendControl.__proto__ = Control;
  ShowLegendControl.prototype = Object.create(Control && Control.prototype);
  ShowLegendControl.prototype.constructor = ShowLegendControl;

  ShowLegendControl.prototype.handleShowLegendControl = function handleShowLegendControl() {
    options.hideShowLegend();
  }

  return ShowLegendControl;
}(Control));

// Export to PDF control
export const ExportToPDFControl = (function (Control) {
  var options = null;
  var button = null;

  function ExportToPDFControl(opt_options) {
    options = opt_options || {};

    button = document.createElement('button');

    button.innerHTML = '<i class="fas fa-file-pdf"></i>';
    button.title = options.formatMessage({ id: "project.elements.map.exportPDFTip" });

    var element = document.createElement('div');
    element.className = 'ol-export-pdf-control ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.handleExportToPDFController.bind(this), false);

  }

  if (Control) ExportToPDFControl.__proto__ = Control;
  ExportToPDFControl.prototype = Object.create(Control && Control.prototype);
  ExportToPDFControl.prototype.constructor = ExportToPDFControl;

  ExportToPDFControl.prototype.handleExportToPDFController = function handleExportToPDFController() {
    // button.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div>';
    // button.disabled = true;
    options.showExportPDFMenu();
  }

  return ExportToPDFControl;
}(Control));

// Info toast on create or modify geometry
export const InfoToastInsertModifyGeometry = (function (Control) {
  var options = null;

  function InfoToastInsertModifyGeometry(opt_options) {
    options = opt_options || {};

    var element = document.createElement('div');
    element.className = 'ol-info-toast-geometry toast show';

    var elementBody = document.createElement('div');
    elementBody.className = 'toast-body';
    elementBody.innerHTML = options.text;

    element.appendChild(elementBody);

    Control.call(this, {
      element: element,
      target: options.target
    });

  }

  if (Control) InfoToastInsertModifyGeometry.__proto__ = Control;
  InfoToastInsertModifyGeometry.prototype = Object.create(Control && Control.prototype);
  InfoToastInsertModifyGeometry.prototype.constructor = InfoToastInsertModifyGeometry;

  return InfoToastInsertModifyGeometry;
}(Control));