import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as actions from '../../users/actions';
import * as geometricElementActions from '../../geometricElements/actions'

const Logout = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(actions.logout());
        dispatch(geometricElementActions.mapCurrentExtent(null));
        history.push('/');
    });

    return null;

}

export default Logout;