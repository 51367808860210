import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import { connect } from 'react-redux';
import { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL } from '../../app/components/InternationalizationRender';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faImages, faInfoCircle, faLink, faMapMarkedAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { initActionButtonTooltip } from '../../common/util';

const IGNORE_LAYER_DATA_KEY = ['geometry', 'gid'];

const mapStateToProps = function (state) {
    return {
        user: userSelectors.getUser(state),
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state),
        allGeometricElementType: selectors.getTotalGeometricElementType(state),
        mapCurrentExtent: selectors.getMapCurrentExtent(state)
    }
}

const GeographicalViewerPopup = ({
    allLayersInfo,
    failedLayers,
    loading,
    setGeometricElementFileGalleryModalShowAndFiles,
    map,
    modifyFeature,
    drawSource,
    centerFeature,
    showRelateGeometricElements,
    enableGeometricElementsRelation,
    showJoinGeometricElement,
    ...props
}) => {

    let renderLayersInfo = (values) => {
        initActionButtonTooltip();
        let moreThanOneLayer = allLayersInfo.length > 1
        return (
            <div className="mr-2">
                {
                    !failedLayers.length && !values.length &&
                    <div className="alert alert-warning" role="alert">
                        <FormattedMessage
                            id="project.elements.map.popupControl.noResult"
                        />
                    </div>
                }
                {
                    failedLayers.length > 0 &&
                    <div className="alert alert-warning" role="alert">
                        <FormattedMessage
                            id="project.elements.map.popupControl.failedLayers"
                            values={{ failedLayers: <b>{failedLayers.join(', ')}</b> }}
                        />
                    </div>
                }
                {values.map((layer, index) =>
                    layer ?
                        <div className="card" key={layer.layer}>
                            <div className="card-header bg-secondary text-white" id={"otherLayersInfo" + index}
                                data-toggle={moreThanOneLayer && 'collapse'}
                                data-target={moreThanOneLayer && '#otherLayersInfoCollapse' + index}
                                aria-controls={moreThanOneLayer && 'otherLayersInfoCollapse' + index}
                            >
                                <b className="card-title">{layer.layer}</b>
                            </div>
                            <div id={'otherLayersInfoCollapse' + index}
                                className={`card-body p-1 m-1 ${moreThanOneLayer && 'collapse show'}`}
                                aria-labelledby={moreThanOneLayer && 'otherLayersInfo' + index}
                            >
                                {layer.isGeometricElementType ?
                                    layer.data.map(data => {
                                        let moreThanOneFeature = layer.data.length > 1;
                                        let values = data.get('value');
                                        let dataTypeList = data.get('data_type');
                                        return (
                                            <div className="card" key={'geometricElement' + data.get('id')}>
                                                <div className="card-header"
                                                    id={'geometricElementDataTitle' + data.get('id')}
                                                    data-toggle={moreThanOneFeature && 'collapse'}
                                                    data-target={moreThanOneFeature && '#geometricElementDataCollapse' + data.get('id')}
                                                    aria-controls={moreThanOneFeature && 'geometricElementDataCollapse' + data.get('id')}
                                                >
                                                    <b className="card-title">{data.get('id')}<br />{data.get('description')}</b>
                                                </div>
                                                <div id={'geometricElementDataCollapse' + data.get('id')}
                                                    key={data.geometricElement ? data.geometricElement.id : data.get('id')}
                                                    className={`card-body ${moreThanOneFeature && 'collapse show'}`}
                                                    aria-labelledby={moreThanOneFeature && 'geometricElementDataTitle' + data.get('id')}
                                                >
                                                    {values[0] ?
                                                        values.map((value, index) => {
                                                            let key = Object.keys(value)[0];
                                                            let dataType = dataTypeList[index];
                                                            return (
                                                                <div className="text-left" key={key}>
                                                                    <b className="card-title">
                                                                        {getInternationalization(props.language, key, props.allCodes, props.activeLanguages)}
                                                                    </b>
                                                                    <p className="card-text">
                                                                        {value[key].reverse().map((value, index, array) => {

                                                                            if (["MULTISELECT", "SELECT"].includes(dataType)) {

                                                                                let parsedValue = "";
                                                                                if (value && value !== "") {

                                                                                    parsedValue += getInternationalization(props.language, value, props.allCodes, props.activeLanguages);

                                                                                } else {

                                                                                    parsedValue += "-"

                                                                                }
                                                                                parsedValue += array.length - 1 !== index ? ", " : "";

                                                                                return parsedValue;

                                                                            } else {

                                                                                if (["BOOLEAN_CHECK", "BOOLEAN_SELECT"].includes(dataType)) {

                                                                                    if (value != null) {

                                                                                        return value === "true" ?
                                                                                            <FormattedMessage
                                                                                                id="project.common.yes"
                                                                                            /> :
                                                                                            <FormattedMessage
                                                                                                id="project.common.no"
                                                                                            />

                                                                                    }

                                                                                    return "-"

                                                                                }

                                                                                return value ? value : "-";

                                                                            }

                                                                        })}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        ""
                                                    }

                                                    {props.user && props.user.userRoleDto.code === "ADMIN" &&
                                                        <div className="text-left">
                                                            <b className="card-title">
                                                                {props.intl.formatMessage({ id: 'project.elements.internalComments' })}
                                                            </b>
                                                            <p className="card-text">
                                                                {data.get('internal_comments') && data.get('internal_comments') !== "" ?
                                                                    data.get('internal_comments')
                                                                    :
                                                                    "-"
                                                                }
                                                            </p>
                                                        </div>
                                                    }

                                                    {
                                                        !props.history.location.pathname.includes(String(data.get('id'))) &&
                                                        <span
                                                            className="actionButton btn-link"
                                                            id={`showDetails${data.get('id')}`}
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={props.intl.formatMessage({ id: "project.common.showDetails" })}
                                                            onClick={() => props.history.push("/geometric_element/details/" + data.get('id'))}
                                                        >
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </span>
                                                    }

                                                    {
                                                        data.geometricElement &&
                                                            data.geometricElement.listGeometricElementFileDto &&
                                                            data.geometricElement.listGeometricElementFileDto.filter(
                                                                file => file.contentType.startsWith("image/")).length
                                                            ?
                                                            <span className="actionButton btn-link"
                                                                id={`viewFiles${data.get('id')}`}
                                                                data-toggle="tooltip"
                                                                data-placement="right"
                                                                title={props.intl.formatMessage({ id: "project.common.viewFiles" })}
                                                                onClick={() => {
                                                                    setGeometricElementFileGalleryModalShowAndFiles(
                                                                        true,
                                                                        data.geometricElement.listGeometricElementFileDto
                                                                    );
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faImages} />
                                                            </span>
                                                            :
                                                            ""
                                                    }

                                                    <span className="actionButton btn-link"
                                                        id={`center${data.get('id')}`}
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={props.intl.formatMessage({ id: "project.common.center" })}
                                                        onClick={() =>
                                                            centerFeature(map, data)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                                    </span>

                                                    {
                                                        (
                                                            props.user &&
                                                            ((props.user.id === data.get("user_account_id"))
                                                                || props.user.userRoleDto.code === "ADMIN")
                                                        ) &&
                                                        <span className="actionButton btn-link"
                                                            id={`modify${data.get('id')}`}
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={props.intl.formatMessage({ id: "project.common.modify" })}
                                                            onClick={() => {
                                                                props.dispatch(actions.findGeometricElementTypeById(data.get('geometric_element_type_id')));
                                                                modifyFeature(map, drawSource, data);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </span>
                                                    }
                                                    {enableGeometricElementsRelation &&
                                                        <span id={"relateGeometricElement" + data.get('id')}
                                                            onClick={() => {
                                                                showRelateGeometricElements(data.geometricElement);
                                                            }}
                                                            className="actionButton btn-link"
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={props.intl.formatMessage({ id: "project.elements.relations.relate" }, {
                                                                plural: getInternationalization(props.language, PARAMETER_ELEMENT_TEXT_PLURAL, props.allCodes, props.activeLanguages)
                                                            })}
                                                        >
                                                            <FontAwesomeIcon icon={faLink} />
                                                        </span>
                                                    }
                                                    {data.geometricElement && data.geometricElement.userCanJoin &&
                                                        <span id={"joinGeometricElement" + data.get('id')}
                                                            onClick={() => {
                                                                showJoinGeometricElement(data.geometricElement);
                                                            }}
                                                            className="actionButton btn-link"
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title={getInternationalization(props.language, data.geometricElement.geometricElementTypeDto.joinTooltipText, props.allCodes, props.activeLanguages)}
                                                        >
                                                            <FontAwesomeIcon icon={faUserPlus} />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    Array.isArray(layer.data) ?
                                        layer.data.map((data, layerIndex) => {
                                            let moreThanOneFeature = layer.data.length > 1;
                                            let dataProperties = data.getProperties();
                                            let dataKeysProperties = Object.keys(dataProperties).filter(key => !IGNORE_LAYER_DATA_KEY.includes(key));
                                            return (
                                                <div className="card" key={`layerDataRootElement${layerIndex}-${index}`}>
                                                    {moreThanOneFeature &&
                                                        <div className="card-header"
                                                            id={`layerDataTitle${index}-${layerIndex}`}
                                                            data-toggle={'collapse'}
                                                            data-target={`#layerData${index}-${layerIndex}`}
                                                            aria-controls={`layerData${index}-${layerIndex}`}
                                                        >
                                                            <b className="card-title"></b>
                                                        </div>
                                                    }
                                                    <div id={`layerData${index}-${layerIndex}`}
                                                        className={`card-body ${moreThanOneFeature && 'collapse show'}`}
                                                        aria-labelledby={moreThanOneFeature && `layerDataTitle${index}-${layerIndex}`}
                                                    >
                                                        {dataKeysProperties.map((key, index) => {
                                                            return (
                                                                <div className="text-left" key={layerIndex + 'layer_data' + index}>
                                                                    <b className="card-title">
                                                                        {key}
                                                                    </b>
                                                                    <p className="card-text">
                                                                        {(dataProperties[key] && dataProperties[key] !== "")
                                                                            ?
                                                                            dataProperties[key]
                                                                            :
                                                                            '-'
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <span className="card-text">{layer.data}</span>
                                }
                            </div>
                        </div>
                        : ""
                )
                }
            </div >
        )
    }

    return (
        <div id="layersPopup" className="ol-popup">
            <a href="." className="ol-popup-closer btn-link" id="popupCloser">
                <i className="fas fa-times"></i>
            </a>
            <div id="popupContent" className="ol-popup-content">
                {loading ?
                    <div className="text-center">
                        <div className="spinner-border m-2" role="status" style={{ width: '3rem', height: '3rem' }}>
                            <span className="sr-only"></span>
                        </div>
                    </div>
                    :
                    renderLayersInfo(allLayersInfo)
                }
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeographicalViewerPopup)));