import React, { useEffect } from 'react';
import jQuery from 'jquery';

const Footer = (props) => {

    useEffect(() => {

        if (props.footerInfo) {
            jQuery('#footerinfo').html(props.footerInfo);
        }
        if (props.footerText1) {
            jQuery('#footerText1').html(props.footerText1);
        }
        if (props.footerText2) {
            jQuery('#footerText2').html(props.footerText2);
        }
        if (props.footerText3) {
            jQuery('#footerText3').html(props.footerText3);
        }
        if (props.footerText4) {
            jQuery('#footerText4').html(props.footerText4);
        }
        if (props.footerText5) {
            jQuery('#footerText5').html(props.footerText5);
        }
        if (props.footerText6) {
            jQuery('#footerText6').html(props.footerText6);
        }
        if (props.footerText7) {
            jQuery('#footerText7').html(props.footerText7);
        }
        if (props.footerText8) {
            jQuery('#footerText8').html(props.footerText8);
        }

    }, [props]);

    return (
        <footer className="font-small bg-light pt-4">
            <div className="container-fluid text-center text-md-left">

                <div className="row justify-content-md-center">

                    {(props.footerText || props.footerImage1) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText1 && <li id="footerText1"></li>}
                                {props.footerImage1 && <img src={`${props.footerImage1}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                    {(props.footerText2 || props.footerImage2) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText2 && <li id="footerText2"></li>}
                                {props.footerImage2 && <img src={`${props.footerImage2}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                    {(props.footerText3 || props.footerImage3) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText3 && <li id="footerText3"></li>}
                                {props.footerImage3 && <img src={`${props.footerImage3}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                    {(props.footerText4 || props.footerImage4) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText4 && <li id="footerText4"></li>}
                                {props.footerImage4 && <img src={`${props.footerImage4}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                </div>
                <div className="row">

                    {(props.footerText5 || props.footerImage5) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText5 && <li id="footerText5"></li>}
                                {props.footerImage5 && <img src={`${props.footerImage5}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                    {(props.footerText6 || props.footerImage6) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText6 && <li id="footerText6"></li>}
                                {props.footerImage6 && <img src={`${props.footerImage6}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                    {(props.footerText7 || props.footerImage7) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText7 && <li id="footerText7"></li>}
                                {props.footerImage7 && <img src={`${props.footerImage7}`} className="img-fluid" alt=""></img>}
                            </ul>

                        </div>
                    }

                    {(props.footerText8 || props.footerImage8) &&
                        <div className="col">

                            <ul className="list-unstyled">

                                {props.footerText8 && <li id="footerText8"></li>}
                                {props.footerImage8 && <img src={`${props.footerImage8}`} className="img-fluid" alt=""></img>}

                            </ul>

                        </div>
                    }

                </div>
            </div>
            <div id="footerinfo" className="text-center py-3">
            </div>
        </footer>
    )
}

export default Footer;