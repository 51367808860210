import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as FilePond from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';
import * as actions from '../actions';
import * as appActions from '../../app/actions';
import * as geometricElementsSelector from '../../geometricElements/selectors';
import * as geometricElementActions from '../../geometricElements/actions';
import * as appSelectors from '../../app/selectors';
import { Errors } from '../../common';
import { Editor } from '@tinymce/tinymce-react'
import jQuery from 'jquery'
import InternationalizationRender, { getInternationalization, hasHelp } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ChromePicker } from 'react-color';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        availableLanguages: appSelectors.getAvailableLanguages(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: geometricElementsSelector.getAllCodes(state)
    }
}

export const internationalizatedTextSplit = (locale) => `[SEP-${locale}]`;

const UpdateConfigurationParameter = ({ modalShow, backendErrors, configurationParameterToUpdate, setBackendErrors, handleSubmit, hideModalWindow, ...props }) => {

    FilePond.registerPlugin(FilePondPluginFileEncode);

    let [formikErrors, setFormikErrors] = useState(null);
    let [hasAddedFiles, setHasAddedFiles] = useState(false);
    let [activeLanguages, setActiveLanguages] = useState(props.activeLanguages);

    useEffect(() => {
        if (configurationParameterToUpdate) {
            if (hasHelp(props.language, configurationParameterToUpdate.attributeType.helpCode)) {
                jQuery('#helpAlert').html(
                    getInternationalization(
                        props.language,
                        configurationParameterToUpdate.attributeType.helpCode.code,
                        props.allCodes,
                        props.activeLanguages
                    )
                );
            }
        }
    });

    useEffect(() => {
        if (configurationParameterToUpdate) {
            if (configurationParameterToUpdate.attributeType.dataType === "LANGUAGE") {
                setActiveLanguages(props.activeLanguages);
            }
        }
    }, [configurationParameterToUpdate, props.activeLanguages]);

    let handleResetFormAndHideModalWindow = () => {
        hideModalWindow();
        setFormikErrors(null);
        setHasAddedFiles(false);
    }

    if (configurationParameterToUpdate && modalShow) {

        const isInternationalizatedText = configurationParameterToUpdate.attributeType.dataType === "INTERNATIONALIZATED_TEXT";
        const isLanguage = configurationParameterToUpdate.attributeType.dataType === "LANGUAGE";
        let initialValues = {
            value: configurationParameterToUpdate.value,
            mimeType: "",
            filename: "",
            defaultLanguage: props.activeLanguages[0]
        }

        let schema = {}

        if (configurationParameterToUpdate.mandatory) {
            schema["value"] = Yup.string().required(<FormattedMessage
                id="project.configurationParameter.required"
            />
            );
        }

        if (isInternationalizatedText) {
            props.activeLanguages.forEach(actLan => {

                if (configurationParameterToUpdate.mandatory) {

                    schema[actLan] = Yup.string().required(<FormattedMessage
                        id="project.app.Body.field.required"
                        values={{
                            field: <InternationalizationRender value={actLan} />,
                        }} />);

                }

                initialValues[actLan] = getInternationalization(
                    actLan.split("-")[0].toLowerCase(),
                    configurationParameterToUpdate.code + "_VALUE",
                    props.allCodes
                ) || ""

            });
        }

        schema = Yup.object().shape({ ...schema });

        if (formikErrors || backendErrors) {
            let container = jQuery('.modal-body');
            if (backendErrors) {
                container.scrollTop(0);
            } else {
                if (formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        const renderSwitch = (type, values, setFieldValue, errors, setFieldError) => {
            switch (type) {
                case 'IMAGE':
                    return (
                        <FilePond.FilePond
                            id="filePond"
                            allowMultiple={false}
                            labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                            labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                            labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                            labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                            onaddfile={(error, fileItem) => {
                                setHasAddedFiles(true);
                                setFieldValue("value", fileItem.getFileEncodeBase64String());
                                setFieldValue("mimeType", fileItem.fileType);
                                setFieldValue("filename", fileItem.filename);
                            }}
                            onremovefile={(error, file) => {
                                setHasAddedFiles(false);
                            }}
                        />
                    );
                case 'FORMATTED_TEXT':
                    return (
                        <Editor
                            id="value"
                            initialValue={configurationParameterToUpdate.value}
                            init={{
                                height: "75vh",
                                menubar: false,
                                language: props.language,
                                language_url: '/tinymce/' + props.language + '.js',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar: [
                                    'undo redo | formatselect | bold italic underline backcolor forecolor | link image | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist outdent indent | table | removeformat | help'
                                ]
                            }}
                            onEditorChange={(content, editor) => {
                                setFieldValue("value", content);
                            }}
                        />
                    )
                case 'BOOLEAN_CHECK':
                    return (
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <Field as="input" type="checkbox" id={configurationParameterToUpdate.code}
                                        name={configurationParameterToUpdate.code}
                                        checked={configurationParameterToUpdate.value === "true" ? true : false}
                                        onClick={() => {
                                            configurationParameterToUpdate.value =
                                                configurationParameterToUpdate.value === "true" ? "false" : "true";
                                            setFieldValue("value", configurationParameterToUpdate.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <label style={{ whiteSpace: 'pre-wrap' }} htmlFor={configurationParameterToUpdate.code} className="col-11 btn input-group-text">
                                <InternationalizationRender value={configurationParameterToUpdate.code} />
                            </label>
                        </div>
                    )
                case 'COLOR':
                    return (
                        <ChromePicker
                            color={configurationParameterToUpdate.value}
                            onChange={(color) => {
                                configurationParameterToUpdate.value = color.hex;
                                setFieldValue("value", configurationParameterToUpdate.value);
                            }}
                            disableAlpha={true}
                        />
                    )
                case 'INTERNATIONALIZATED_TEXT':
                    return props.activeLanguages.map(actLan => {
                        return (
                            <>
                                <div className="form-group">
                                    <label htmlFor={actLan} className={`font-weight-bold ${configurationParameterToUpdate.mandatory && 'required'}`}>
                                        <InternationalizationRender value={actLan} />
                                    </label>
                                    <Field id={actLan} name={actLan} className="form-control" />
                                </div>
                                <ErrorMessage name={actLan} render={(msg) =>
                                    <div id={actLan + "Error"} className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors[actLan] === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError(actLan, undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />
                            </>
                        )
                    });
                case 'LANGUAGE':
                    return (
                        <>
                            <div className="form-group">
                                <label htmlFor="type" className="font-weight-bold">
                                    <FormattedMessage id="project.configurationParameter.modify.defaultLanguage" />
                                </label>
                                <Field as="select" id="defaultLanguage" name="defaultLanguage" className="form-control"
                                    onChange={(event) => {
                                        setFieldValue("defaultLanguage", event.target.value)
                                        if (!activeLanguages.includes(event.target.value)) {
                                            setActiveLanguages([event.target.value, ...activeLanguages])
                                        }
                                    }}
                                >
                                    {props.availableLanguages.map(language => (
                                        <option value={language} key={language}>
                                            {getInternationalization(props.language, language, props.allCodes, props.activeLanguages)}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            {props.availableLanguages.map((language, index) => {
                                return (
                                    <div className="input-group mb-3" key={language}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field as="input" type="checkbox" id={"activeLanguages" + index}
                                                    name="activeLanguages"
                                                    checked={activeLanguages.includes(language)}
                                                    disabled={values.defaultLanguage === language}
                                                    onClick={() => {
                                                        if (activeLanguages.includes(language)) {
                                                            setActiveLanguages(languages => languages.filter(ac => ac !== language))
                                                        } else {
                                                            setActiveLanguages([language, ...activeLanguages])
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ whiteSpace: 'pre-wrap' }} htmlFor={"activeLanguages" + index} className="btn input-group-text">
                                            <InternationalizationRender value={language} />
                                        </label>
                                    </div>
                                )
                            })}
                        </>
                    )
                case 'SELECT':
                    return (
                        <Field as="select"
                            className="form-control"
                            id="value"
                            name="value"
                            multiple={type === 'MULTISELECT'}
                        >
                            {props.allCodes.filter(code =>
                                code.codeGroup.groupCode === configurationParameterToUpdate.code
                            ).map(code =>
                                <option value={code.code} key={code.code}>
                                    {getInternationalization(props.locale, code.code, props.allCodes, props.activeLanguages)}
                                </option>
                            )}
                        </Field>
                    )
                default:
                    return <Field as="input" name="value" className="form-control" id="value" />
            }
        };

        // Prevent Bootstrap dialog from blocking focusin
        jQuery(document).on('focusin', function (e) {
            if (jQuery(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
                e.stopImmediatePropagation();
            }
        });

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    let activeLanguagesOrdered = [];
                    // Put default language in first array position
                    if (isLanguage) {
                        activeLanguagesOrdered = activeLanguages.filter(ac => ac !== values.defaultLanguage);
                        activeLanguagesOrdered = [values.defaultLanguage, ...activeLanguagesOrdered];
                    }
                    let internationalizatedText = "";
                    if (isInternationalizatedText) {
                        props.activeLanguages.forEach((value) => {
                            let splittedLanguage = value.split("-")[0].toLowerCase();
                            internationalizatedText += values[value];
                            internationalizatedText += internationalizatedTextSplit(splittedLanguage);
                        })
                    }
                    var json = {
                        "value": !isInternationalizatedText ? !isLanguage ? values.value : activeLanguagesOrdered.join()
                            : internationalizatedText,
                        "mimeType": values.mimeType,
                        "filename": values.filename
                    }
                    props.dispatch(actions.updateConfigurationParameter(configurationParameterToUpdate.id, json,
                        () => {
                            if (configurationParameterToUpdate.attributeType.dataType === "INTERNATIONALIZATED_TEXT") {
                                props.dispatch(geometricElementActions.findAllCodes(() => {
                                    handleSubmit();
                                }));
                            } else {
                                if (isLanguage) {
                                    props.dispatch(appActions.activeLanguages(activeLanguagesOrdered));
                                }
                                handleSubmit();
                            }
                        },
                        errors => {
                            setBackendErrors(errors)
                        }));
                    setHasAddedFiles(false);
                    setSubmitting(false);
                }}
            >
                {({ values, setFieldValue, setFieldError, errors }) =>
                    <Modal show={modalShow} onHide={handleResetFormAndHideModalWindow} scrollable={true} size="lg" enforceFocus={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {<FormattedMessage id="project.configurationParameter.modify"
                                    values={{
                                        parameter:
                                            getInternationalization(props.language, configurationParameterToUpdate.code, props.allCodes, props.activeLanguages)
                                    }}
                                />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {hasHelp(props.language, configurationParameterToUpdate.attributeType.helpCode) ?
                                <div id="helpAlert" className="alert alert-primary text-left">
                                </div>
                                :
                                ""
                            }
                            <Form className="needs-validation novalidate" id="updateConfigurationParameterForm">
                                {errors ? setFormikErrors(errors) : ""}
                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />
                                {
                                    renderSwitch(configurationParameterToUpdate.attributeType.dataType,
                                        values, setFieldValue, errors, setFieldError)
                                }

                                <ErrorMessage
                                    name="value" render={(msg) =>
                                        <div id={"valueError"} className="alert alert-danger alert-dismissible fade show mt-3" role="alert"
                                            hidden={typeof errors["value"] === "undefined"}
                                        >
                                            <button type="button" className="close" aria-label="Close"
                                                onClick={() => setFieldError("value", undefined)}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {msg}
                                        </div>
                                    }
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary" id="updateParameterSubmit"
                                form="updateConfigurationParameterForm"
                                disabled={
                                    (configurationParameterToUpdate.attributeType.dataType === "IMAGE" && !hasAddedFiles)
                                    || (configurationParameterToUpdate.attributeType.dataType === "LANGUAGE" && !activeLanguages.length)
                                }
                            >
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <Button variant="danger" onClick={handleResetFormAndHideModalWindow} id="updateParameterCancel">
                                <FormattedMessage id="project.app.Body.cancel" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }


    return (null);
}

UpdateConfigurationParameter.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    configurationParameterToUpdate: PropTypes.object.isRequired,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(injectIntl(UpdateConfigurationParameter));