import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ExternalImage = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const onLoad = () => {
        setLoaded(true);
    }

    const onError = () => {
        setError(true);
        setLoaded(true);
    }

    return (
        <>
            <img
                style={{ display: loaded ? "block" : "none" }}
                onLoad={onLoad}
                onError={onError}
                src={props.src}
                alt={props.alt}
                {...props}
            />

            {!loaded && <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only"></span></div>}
            {error && <FormattedMessage id="project.common.images.error" />}
        </>
    )
}

export default ExternalImage;