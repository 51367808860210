import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as actions from '../actions';
import { FormattedMessage } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as selectors from '../selectors';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import * as Yup from 'yup';
import jQuery from 'jquery';
import * as appSelectors from '../../app/selectors';
import * as geometricElementSelectors from '../../geometricElements/selectors';
import { forceChangeLanguage, renderLanguageOptions } from '../../common/util';
import { reauthenticationCallback } from '../../app/util';

const mapStateToProps = function (state) {

    return {
        language: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: geometricElementSelectors.getAllCodes(state),
        user: selectors.getUser(state),
        roleByCode: selectors.getRoleByCode(state),
        totalUserAccounts: selectors.getTotalUserAccounts(state),
        configurationParameters: configurationParameterSelectors.getTotalConfigurationParameters(state)
    }
}
class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null,
        }
    }

    enableSignupContactDataAlert = false;
    signupContactDataAlert = null;

    initialValues = {
        login: '',
        password: '',
        confirmPassword: '',
        locale: ''
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.handleAddNewUser !== this.props.handleAddNewUser) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.state.formikErrors !== null || this.props.backendErrors !== null) {
            let container = jQuery('.modal-body');
            if (container.length > 0) {
                if (this.props.backendErrors) {
                    container.scrollTop(0);
                } else {
                    if (this.state.formikErrors) {
                        let field = jQuery('.alert-danger:visible:first').prev();
                        if (field.length)
                            jQuery(container).animate({
                                scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                            });
                    }
                }
            }
        }

        if (this.enableSignupContactDataAlert && this.signupContactDataAlert) {
            jQuery("#signupContactDataAlert").html(this.signupContactDataAlert);
        }

    }

    render() {

        if (this.props.configurationParameters) {
            this.enableSignupContactDataAlert = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("ENABLE_SIGN_UP_CONTACT_DATA_ALERT") !== -1);

            if (this.enableSignupContactDataAlert.length > 0) {
                this.enableSignupContactDataAlert = (this.enableSignupContactDataAlert[0].value === 'true');
            } else {
                this.enableSignupContactDataAlert = false;
            }

            this.signupContactDataAlert = this.props.configurationParameters.filter(configurationParameter =>
                configurationParameter.code.indexOf("SIGN_UP_CONTACT_DATA_ALERT") !== -1);

            if (this.signupContactDataAlert.length > 0) {
                this.signupContactDataAlert = this.signupContactDataAlert[0].value;
            } else {
                this.signupContactDataAlert = null;
            }
        }

        let Schema = Yup.object().shape({
            login: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.login" />,
                }} />),
            password: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.password" />,
                }} />),
            confirmPassword: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    <FormattedMessage id="project.users.confirmPassword.error" />
                ).required(<FormattedMessage
                    id="project.app.Body.field.required"
                    values={{
                        field: <FormattedMessage id="project.users.confirmPassword" />
                    }} />),
                otherwise: Yup.string().notRequired()
            }),
            locale: Yup.string().required(<FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <FormattedMessage id="project.users.locale" />,
                }} />)
        });

        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.setState({ backendErrors: null });
                    let userToInsert = {
                        'login': values.login,
                        'password': values.password,
                        'locked': false,
                        'locale': values.locale,
                    }

                    if (this.props.user) {
                        if (this.props.user.userRoleDto.code === "ADMIN") {
                            userToInsert["userRoleDto"] = this.props.roleByCode.result
                            this.props.dispatch(actions.createUserAccount(userToInsert,
                                () => {
                                    this.props.hideModalWindow();
                                    this.props.handleAddNewUser();
                                    resetForm();
                                },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                }
                            ));
                        }
                    } else {
                        userToInsert["userRoleDto"] = this.props.roleByCode.result
                        this.props.dispatch(actions.signUp(userToInsert,
                            (user) => {

                                forceChangeLanguage(user.user.locale);

                                this.props.hideModalWindow();
                                resetForm();

                            },
                            errors => {
                                this.props.setBackendErrors(errors);
                            },
                            () => reauthenticationCallback({ dispatch: this.props.dispatch, history: this.props.history })
                        ));
                    }
                    setSubmitting(false);
                }}
            >
                {({ resetForm, errors, setFieldError }) =>
                    <Modal show={this.props.modalShow} onHide={() => { resetForm(); this.props.hideModalWindow(); }} scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="project.app.Header.signUp" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="signUpForm">
                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />
                                <div className="form-group">
                                    <label htmlFor="login" className="font-weight-bold required">
                                        <FormattedMessage id="project.users.login" />
                                    </label>
                                    <Field id="login" name="login" type="text" className="form-control" />
                                </div>

                                <ErrorMessage name="login" render={(msg) =>
                                    <div id="loginError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.login === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("login", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="locale" className="font-weight-bold required">
                                        <FormattedMessage id="project.users.locale" />
                                    </label>
                                    <Field as="select" id="locale" name="locale" className="form-control">
                                        <FormattedMessage id="project.users.locale.pick">
                                            {(message) => <option value="">{message}</option>}
                                        </FormattedMessage>
                                        {renderLanguageOptions(this.props.activeLanguages, this.props.language, this.props.allCodes)}
                                    </Field>
                                </div>

                                <ErrorMessage name="locale" render={(msg) =>
                                    <div id="localeError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.locale === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("locale", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="password" className="font-weight-bold required">
                                        <FormattedMessage id="project.users.password" />
                                    </label>
                                    <Field id="password" name="password" type="password" className="form-control" />
                                </div>

                                <ErrorMessage name="password" render={(msg) =>
                                    <div id="passwordError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.password === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("password", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="confirmPassword" className="font-weight-bold required">
                                        <FormattedMessage id="project.users.confirmPassword" />
                                    </label>
                                    <Field id="confirmPassword" name="confirmPassword" type="password" className="form-control" />
                                </div>

                                <ErrorMessage name="confirmPassword" render={(msg) =>
                                    <div id="confirmPasswordError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.confirmPassword === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("confirmPassword", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                {this.enableSignupContactDataAlert && this.signupContactDataAlert &&
                                    <div id="signupContactDataAlert" className="alert alert-warning" role="alert" style={{ maxHeight: '6.5em', overflowY: 'auto' }}>
                                    </div>
                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="signUpSubmit" type="submit" className="btn btn-primary"
                                form="signUpForm"
                            >
                                <FormattedMessage id="project.app.Header.signUp" />
                            </button>
                            <button id="signUpCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    resetForm();
                                    this.props.hideModalWindow();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }
}

export default withRouter(connect(mapStateToProps)(SignUp));