import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import * as actions from '../actions';
import jQuery from 'jquery';

const mapStateToProps = function (state) {
    return {

        applicationData: selectors.getApplicationData(state)

    }
}

const ApplicationData = ({ applicationData, ...props }) => {

    useEffect(() => {

        jQuery('#mainFooter').scrollTop(0);

        if (!applicationData) {

            props.dispatch(actions.applicationData());

        }

        // eslint-disable-next-line
    }, []);

    return (

        <div className="card">

            <h3 className='card-header'><FormattedMessage id="project.app.ApplicationData.title" /></h3>
            <div className="card-body">

                <div className="row row-cols-md-1 row-cols-md-2 row-cols-md-3 row-cols-md-4 row-cols-md-5 row-cols-md-6 card-block">

                    <div className="row list-group-item border mb-3 mx-3">

                        <span className="col font-weight-bold">
                            <FormattedMessage id='project.app.ApplicationData.version' />
                        </span>
                        <span className="col">
                            {applicationData && applicationData.version}
                        </span>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default connect(mapStateToProps)(ApplicationData);