import * as actionTypes from './actionTypes';
import backend from '../../backend';
import * as appActions from '../app/actions';
import * as appSelectors from '../app/selectors';
import { generateIdentificatorForLoadingAction } from '../app/util';

const findAllProvincesCompleted = provinces => ({
    type: actionTypes.FIND_ALL_PROVINCES_COMPLETED,
    provinces
});

export const findAllProvinces = () => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.locationService.findAllProvinces(appSelectors.getLanguage(getState()),
        (provinces) => {
            dispatch(findAllProvincesCompleted(provinces));
            dispatch(appActions.loaded(loadingId));
        }, () => {

            dispatch(appActions.loaded(loadingId));
        });
}

const findMunicipalitiesByProvinceCompleted = municipalitiesByProvince => ({
    type: actionTypes.FIND_MUNICIPALITIES_BY_PROVINCE_COMPLETED,
    municipalitiesByProvince
});

export const findMunicipalitiesByProvince = (provinceCode) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.locationService.findAllMunicipalitiesByProvince(provinceCode, appSelectors.getLanguage(getState()),
        (municipalitiesByProvince) => {
            dispatch(findMunicipalitiesByProvinceCompleted(municipalitiesByProvince));
            dispatch(appActions.loaded(loadingId));
        }, () => {

            dispatch(appActions.loaded(loadingId));
        });
}

const findParishesByMunicipalityCompleted = parishesByMunicipality => ({
    type: actionTypes.FIND_PARISHES_BY_MUNICIPALITY_COMPLETED,
    parishesByMunicipality
});

export const findParishesByMunicipality = (provinceCode, municipalityIneCode) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.locationService.findAllParishesByMunicipality(provinceCode, municipalityIneCode, appSelectors.getLanguage(getState()),
        (parishesByMunicipality) => {
            dispatch(findParishesByMunicipalityCompleted(parishesByMunicipality));
            dispatch(appActions.loaded(loadingId));
        }, () => {

            dispatch(appActions.loaded(loadingId));
        });
}