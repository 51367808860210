import React from "react";
import * as appSelectors from '../../app/selectors'
import * as actions from '../actions';
import * as selectors from '../selectors';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import jQuery from 'jquery';
import InternationalizationRender, { getInternationalization } from '../../app/components/InternationalizationRender';
import PropTypes from 'prop-types';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = function (state) {
    return ({
        locale: appSelectors.getLanguage(state),
        activeLanguages: appSelectors.getActiveLanguages(state),
        allCodes: selectors.getAllCodes(state)
    });
}

const Schema = Yup.object().shape({
    order: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.geometricLayer.layerGroup.order" />,
        }} />)
});

export const validateLayerGroupName = function (activeLanguages, values) {
    let schema = {};

    activeLanguages.forEach(language => {
        if (!values[language] || values[language].length === 0) {
            schema[language] = <FormattedMessage
                id="project.app.Body.field.required"
                values={{
                    field: <InternationalizationRender value={language} />,
                }}
            />
        }
    })

    return schema
}

class AddModifyGeometricLayerGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricLayerGroupToModify !== this.props.geometricLayerGroupToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors || this.props.backendErrors) {
            let container = jQuery('.modal-body');
            if (this.props.backendErrors) {
                container.scrollTop(0);
            } else {
                if (this.state.formikErrors) {
                    let field = jQuery('.alert:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }
        jQuery(function () {
            //show tooltip
            jQuery('.helpTooltip').tooltip({
                html: true,
                placement: 'right',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['right'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });
        });

        //Prevent clicking on help button activates input associated with label
        jQuery(document).on('tap click', 'label sup', function (event, data) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        });
    }

    render() {

        let initialValues = {
            includesInsideGroupLayer: false,
            order: "",
            geometricLayerGroupEn: "",
            geometricLayerGroupEs: "",
            geometricLayerGroupGl: "",
            initiallyOpen: false,
            placeBehindOtherLayers: false
        }

        this.props.activeLanguages.forEach(actLan => {
            initialValues[actLan] = ""
        })

        if (this.props.geometricLayerGroupToModify) {
            initialValues = {
                includesInsideGroupLayer: this.props.geometricLayerGroupToModify.includeInsideGroupLayer,
                order: this.props.geometricLayerGroupToModify.order,
                geometricLayerGroupCode: this.props.geometricLayerGroupToModify.code.code,
                initiallyOpen: this.props.geometricLayerGroupToModify.initiallyOpen,
                placeBehindOtherLayers: this.props.geometricLayerGroupToModify.placeBehindOtherLayers
            }

            this.props.activeLanguages.forEach(actLan => {
                let splittedLanguage = actLan.split("-")[0].toLowerCase();
                initialValues[actLan] = getInternationalization(
                    splittedLanguage,
                    this.props.geometricLayerGroupToModify.code.code,
                    this.props.allCodes
                ) || ""
            })
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Schema}
                validateOnBlur={false}
                validateOnChange={false}
                validate={(values) => validateLayerGroupName(this.props.activeLanguages, values)}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.props.setBackendErrors(null);
                    let geometricLayerForInsert = {
                        "codeDto": {
                            "id": -1,
                            "code": this.props.geometricLayerGroupToModify ?
                                values.geometricLayerGroupCode : Math.random().toString(36).substring(2).toUpperCase() + '_GROUP_LAYER',
                            "codeGroup": {
                                "id": -1,
                                "codeGroup": "GEOMETRIC_LAYER_GROUP_GROUP_CODE"
                            },
                            "listInternationalizationDto": []
                        },
                        "includeInsideGroupLayer": values.placeBehindOtherLayers ? false : values.includesInsideGroupLayer,
                        "order": values.order,
                        "initiallyOpen": values.initiallyOpen,
                        "placeBehindOtherLayers": values.placeBehindOtherLayers
                    }

                    let geometricLayerGroupCode = this.props.geometricLayerGroupToModify ?
                        values.geometricLayerGroupCode : Math.random().toString(36).substring(2).toUpperCase() + '_GROUP_LAYER';

                    this.props.activeLanguages.forEach(actLan => {
                        let splittedLanguage = actLan.split("-")[0].toLowerCase();
                        geometricLayerForInsert.codeDto.listInternationalizationDto.push({
                            "codeDto": {
                                "id": -1,
                                "code": geometricLayerGroupCode,
                                "codeGroup": {
                                    "id": -1,
                                    "groupCode": geometricLayerGroupCode
                                }
                            },
                            "language": splittedLanguage,
                            "description": values[actLan]
                        })
                    });


                    if (this.props.geometricLayerGroupToModify) {
                        this.props.dispatch(actions.modifyGeometricLayerGroup(this.props.geometricLayerGroupToModify.id, geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    resetForm();
                                    this.props.handleSubmit();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    } else {
                        this.props.dispatch(actions.addGeometricLayerGroup(geometricLayerForInsert, () => {
                            this.props.dispatch(actions.findAllCodes(
                                () => {
                                    resetForm();
                                    this.props.handleSubmit();
                                }
                            ));
                        },
                            errors => {
                                this.props.setBackendErrors(errors);
                            }));
                    }
                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldError, resetForm }) =>
                    <Modal show={this.props.modalShow} onHide={this.props.hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.geometricLayerGroupToModify ?
                                    <FormattedMessage id="project.elements.geometricLayer.layerGroup.modify" />
                                    :
                                    <FormattedMessage id="project.elements.geometricLayer.layerGroup.add" />
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricLayerGroupForm">

                                {errors ? this.setState({ formikErrors: errors }) : ""}
                                <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                <Field id="geometricLayerGroupCode" name="geometricLayerGroupCode"
                                    disabled={true} hidden={true} />

                                <div className="card">

                                    <h5 className="card-header">
                                        <FormattedMessage id="project.elements.geometricLayer.layerGroup.name" />
                                    </h5>

                                    <div className="card-body">
                                        <div className="form-group">
                                            {this.props.activeLanguages.map(actLan => {
                                                return (
                                                    <>
                                                        <div className="input-group mb-3 no-gutters">
                                                            <div className="input-group-prepend col-2">
                                                                <span className="input-group-text col-12 required">
                                                                    <InternationalizationRender value={actLan} />
                                                                </span>
                                                            </div>
                                                            <Field id={actLan} name={actLan} as="input" className="form-control" />
                                                        </div>
                                                        <ErrorMessage name={actLan} render={(msg) =>
                                                            <div id={`${actLan}Error`} className="alert alert-danger alert-dismissible fade show"
                                                                role="alert" hidden={typeof errors[actLan] === "undefined"}
                                                            >
                                                                <button type="button" className="close" aria-label="Close"
                                                                    onClick={() => setFieldError(actLan, undefined)}
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                                {msg}
                                                            </div>
                                                        }
                                                        />
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group mb-3" style={{ marginTop: '1rem' }}>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="placeBehindOtherLayers" name="placeBehindOtherLayers" />
                                        </div>
                                    </div>
                                    <label htmlFor="placeBehindOtherLayers" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layerGroup.placeBehindOtherLayers" />
                                    </label>
                                </div>

                                {!values.placeBehindOtherLayers ?
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="includesInsideGroupLayer" name="includesInsideGroupLayer" />
                                            </div>
                                        </div>
                                        <label htmlFor="includesInsideGroupLayer" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.geometricLayer.layerGroup.includesInsideLayerGroup" />
                                        </label>
                                    </div>
                                    :
                                    ""
                                }

                                <div className="form-group">
                                    <label htmlFor="order" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.geometricLayer.layerGroup.order" />
                                        &nbsp;
                                        <sup id="orderHelp"
                                            className="helpTooltip btn-link"
                                            style={{ cursor: "pointer" }}
                                            aria-hidden={true}
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-original-title={
                                                this.props.intl.formatMessage({
                                                    id: "project.elements.geometricLayer.layerGroup.order.help"
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </sup>
                                    </label>
                                    <Field type="number" id="order" name="order" className="form-control" />
                                </div>
                                <ErrorMessage name="order" render={(msg) =>
                                    <div id="orderError" className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.order === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("order", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Field type="checkbox" id="initiallyOpen" name="initiallyOpen" />
                                        </div>
                                    </div>
                                    <label htmlFor="initiallyOpen" className="btn input-group-text">
                                        <FormattedMessage id="project.elements.geometricLayer.layerGroup.initiallyOpen" />
                                    </label>
                                </div>

                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <button id="createGeometricLayerGroupSubmit" type="submit" className="btn btn-primary"
                                form="addGeometricLayerGroupForm"
                            >
                                <FormattedMessage id="project.app.Body.accept" />
                            </button>
                            <button id="createGeometricLayerGroupCancel" type="button" className="btn btn-danger"
                                onClick={() => {
                                    this.props.hideModalWindow();
                                    resetForm();
                                }}
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik >
        );
    }
}

AddModifyGeometricLayerGroup.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricLayerGroupToModify: PropTypes.object,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(injectIntl(AddModifyGeometricLayerGroup));