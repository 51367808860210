import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import OSM from "ol/source/OSM";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS";
import LayerSwitcher from "ol-layerswitcher";
import Group from 'ol/layer/Group';
import XYZ from 'ol/source/XYZ';
import { Modify } from 'ol/interaction';
import {
  AddGeometricElementController, AreaMeasureController,
  LengthMeasureController, ZoomToExtentController, PopupController, EntityPopulationCenterControl, ShowLegendControl, ExportToPDFControl, setCursorToEditGeometry, InfoToastInsertModifyGeometry
} from './OpenlayersCustomControllers';
import { FullScreen, ZoomSlider, OverviewMap, ZoomToExtent, Zoom, ScaleLine, MousePosition } from 'ol/control';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Overlay, Collection, Feature } from "ol";
import jQuery from 'jquery';
import { GeoJSON, WFS } from 'ol/format';
import { equalTo as equalToFilter, or as orFilter } from 'ol/format/filter';
import { boundingExtent, buffer, getHeight, isEmpty } from 'ol/extent';
import * as userSelectors from '../../users/selectors'
import * as selectors from '../selectors';
import * as appSelectors from '../../app/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddGeometricElement from './AddGeometricElement';
import * as elementService from '../../../backend/elementService';
import * as configurationParameterSelectors from '../../configurationParameter/selectors';
import { FormattedMessage, injectIntl } from "react-intl";
import * as actions from '../actions';
import * as appActions from '../../app/actions';
import { Circle as CircleStyle, Style, Fill, Stroke } from "ol/style";
import GeometricElementFileGallery from "./GeometricElementFileGallery";
import { getInternationalization, PARAMETER_ELEMENT_TEXT_PLURAL, PARAMETER_GEOGRAPHICAL_VIEWER_INSERTION_TOAST_TEXT, PARAMETER_GEOGRAPHICAL_VIEWER_MODIFICATION_TOAST_TEXT, PARAMETER_MY_ELEMENTS_TEXT } from "../../app/components/InternationalizationRender";
import GeographicalViewerLegend from "./GeographicalViewerLegend";
import { Modal } from "react-bootstrap";
import GeographicalViewerExportMenu from "./GeographicalViewerExportMenu";
import GeographicalViewerPopup from "./GeographicalViewerPopup";
import { groupBy } from 'lodash';
import { transform } from "ol/proj";
import { format } from "ol/coordinate";
import GeoBookmark from 'ol-ext/control/GeoBookmark';
import { register } from 'ol/proj/proj4'
import { get as getProjection } from "ol/proj/";
import proj4 from "proj4";
import AddModifyGeometricElementRelation from "./AddModifyGeometricElementRelation";
import AddModifyGeometricElementJoin from './AddModifyGeometricElementJoin';
import { capitalizeFirstLetter } from "../../common/util";
import { Login, SignUp } from "../../users";

export const MAP_ID = "map";
export const CLICK_PIXEL_TOLERANCE = 10;
export const MAP_CRS = 'EPSG:3857';
export const FEATURES_CRS = 'EPSG:4326';
export const WMS_VERSION = '1.3.0';

export const getFeatureInfoCodes = {
  INFO_FORMAT_TEXT: 'text/plain',
  INFO_FORMAT_HTML: 'text/html',
  INFO_FORMAT_JSON: 'application/json',
  INFO_FORMAT_GEOJSON: 'application/geojson',
  INFO_FORMAT_GML_2: 'application/vnd.ogc.gml',
  INFO_FORMAT_GML_3: 'application/vnd.ogc.gml/3.1.1'
}

const mapStateToProps = function (state) {
  return {
    user: userSelectors.getUser(state),
    locale: appSelectors.getLanguage(state),
    activeLanguages: appSelectors.getActiveLanguages(state),
    allCodes: selectors.getAllCodes(state),
    geometricElementType: selectors.getGeometricElementType(state),
    allGeometricElementType: selectors.getTotalGeometricElementType(state),
    parameters: configurationParameterSelectors.getTotalConfigurationParameters(state),
    listProvinces: selectors.getListProvinces(state),
    listCouncils: selectors.getListCouncils(state),
    listParishes: selectors.getListParishes(state),
    listEntityPopulations: selectors.getListEntityPopulations(state),
    listCounties: selectors.getListCounties(state),
    listAllGeometricLayerGroup: selectors.getTotalGeometricLayerGroup(state),
    mapCurrentExtent: selectors.getMapCurrentExtent(state)
  }
}

let hideAllOverlays = (map) => {
  map.getOverlays().forEach(overlay => {
    overlay.setPosition(null);
  });
}

const insertGeometricLayerGroup = (geometricLayerGroup, map, otherLayersGroup, locale, allCodes, activeLanguages, allowConfigureLayersOpacity) => {
  if (geometricLayerGroup.listGeometricLayer.length) {
    let group = new Group({
      title: getInternationalization(locale, geometricLayerGroup.code.code, allCodes, activeLanguages),
      fold: geometricLayerGroup.initiallyOpen ? 'open' : 'close'
    });
    geometricLayerGroup.includeInsideGroupLayer ?
      otherLayersGroup.getLayers().push(group)
      :
      map.addLayer(group);
    geometricLayerGroup.listGeometricLayer.sort((a, b) => {
      return a.order - b.order;
    }).forEach(geometricLayer => {
      let source = null;
      if (geometricLayer.type.code === 'WMS') {
        source = new TileWMS({
          projection: MAP_CRS,
          url:
            geometricLayer.serverUrl ?
              geometricLayer.useProxy ?
                `${process.env.REACT_APP_BACKEND_URL}/layerserver`
                : geometricLayer.serverUrl
              :
              `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'layers': [geometricLayer.internalName],
            'VERSION': geometricLayer.version ? geometricLayer.version : WMS_VERSION,
            'GL_ID': geometricLayer.useProxy ? geometricLayer.id : null
          },
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === 'WMS_DATABASE') {
        source = new TileWMS({
          projection: MAP_CRS,
          url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'layers': [geometricLayer.internalName],
            'VERSION': WMS_VERSION,
            'RL': geometricLayer.refreshLegend
          },
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === 'WMS_SHAPE') {
        source = new TileWMS({
          projection: MAP_CRS,
          url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          params: {
            'layers': [geometricLayer.internalName],
            'VERSION': WMS_VERSION,
            'RL': geometricLayer.refreshLegend
          },
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === "OSM") {
        source = new OSM({
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (geometricLayer.type.code === "XYZ") {
        source = new XYZ({
          url: geometricLayer.serverUrl,
          // Prevent CORS error when exporting to PDF
          crossOrigin: 'anonymous'
        });
      }
      if (source) {
        let layer = new TileLayer({
          title: getInternationalization(locale, geometricLayer.code.code, allCodes, activeLanguages),
          enableOpacitySlider: allowConfigureLayersOpacity && geometricLayer.allowChangeOpacity,
          opacity: geometricLayer.defaultOpacity ? geometricLayer.defaultOpacity : 1,
          visible: geometricLayer.initiallyVisible ? true : false,
          legendType: geometricLayer.legendType,
          legendTypeValue: geometricLayer.legendTypeValue,
          showAllLayersOnLegend: geometricLayer.showAllLayersOnLegend,
          source: source,
          type: geometricLayer.geometricLayerGroup.code.code === "BACKGROUND_LAYER_GROUP" && 'base',
          supportsGetFeatureInfo: geometricLayer.supportsGetFeatureInfo,
          getFeatureInfoFormat: geometricLayer.informationFormatToUse,
          isGeometricElementType: false,
          isInternal: geometricLayer.type.code === "WMS_DATABASE"
        })
        if (geometricLayer.refreshLegend) {
          layer.refreshLegend = geometricLayer.refreshLegend
        }
        group.getLayers().insertAt(0, layer);
      }
    });
  }
}

class GeographicalViewer extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      center: [0, 0],
      zoom: 1,
      geom: null,
      createElement: props.createElement,
      featureToModify: props.featureToModify ?
        props.featureToModify :
        props.location.state && props.location.state.featureToModify ? props.location.state.featureToModify : null,
      centerFeature: props.centerFeature ?
        props.centerFeature :
        props.location.state && props.location.state.centerFeature ? props.location.state.centerFeature : null,
      user: props.user,
      locale: props.locale,
      geometricElementFileGalleryModalShow: false,
      geometricElementFileGalleryFiles: null,
      drawInteraction: null,
      dragZoomInteraction: null,
      modifyInteraction: null,
      snapInteraction: null,
      showLegend: this.props.defaultEnableGeographicalViewerLegend,
      generatePdfErrorModalShow: false,
      generatePdfMenuModalShow: false,
      popupAllLayersInfo: [],
      popupFailedLayers: [],
      popupLoading: false,
      arePendingRequests: false,
      geometricElementToRelate: null,
      backendErrors: null,
      relateGeometricElementsModalVisible: false,
      geometricElementToJoin: null,
      joinGeometricElementsModalVisible: false,
      // State to force GeographicalViewerPopup to rerender itself
      geometricElementHasFinded: 0,
      loginModalShow: false,
      signUpModalShow: false
    };

    this.geographicalViewerLegendRef = React.createRef();

    this.showRelateGeometricElements = (geometricElementToRelate) => {
      this.setState({
        geometricElementToRelate,
        relateGeometricElementsModalVisible: true,
        backendErrors: null
      });
    }

    this.showJoinGeometricElement = (geometricElementToJoin) => {
      this.setState({
        geometricElementToJoin,
        joinGeometricElementsModalVisible: true,
        backendErrors: null
      });
    }

    this.setBackendErrors = (backendErrors) => {
      this.setState({ backendErrors });
    }

    this.hideShowLegend = () => {
      this.setState(prevState => ({
        showLegend: !prevState.showLegend
      }))
    }

    this.drawSource = new VectorSource({
      format: new GeoJSON(),
      wrapX: false
    });

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    };

    this.resetModifyFeature = () => {
      this.setState({ featureToModify: null })
    }

    this.resetCreateElement = () => {

      this.setState({ createElement: null });

    }

    this.elementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.elements' },
        {
          plural: capitalizeFirstLetter(getInternationalization(this.props.locale, PARAMETER_ELEMENT_TEXT_PLURAL, this.props.allCodes, this.props.activeLanguages))
        }
      ),
      fold: 'open'
    })

    this.myElementsGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.app.Header.myContributions' }, {
        myElements: capitalizeFirstLetter(getInternationalization(this.props.locale, PARAMETER_MY_ELEMENTS_TEXT, this.props.allCodes, this.props.activeLanguages))
      }),
      fold: 'open'
    })

    this.layerSwitcherControl = new LayerSwitcher({
      activationMode: 'click',
      groupSelectStyle: 'children',
      startActive: true,
      tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.layersTip.show' }),
      collapseTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.layersTip.hide' }),
      opacityTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.opacityLabelTip' })
    });

    this.otherLayersGroup = new Group({
      title: this.props.intl.formatMessage({ id: 'project.elements.map.otherLayers' }),
      fold: 'open',
      layers: [],
    });

    let fullScreenLabel = document.createElement('span');
    fullScreenLabel.innerHTML = '<i class="fas fa-expand-alt"></i>';
    let fullScreenLabelActive = document.createElement('span');
    fullScreenLabelActive.innerHTML = '<i class="fas fa-compress-alt"></i>';
    let zoomInLabel = document.createElement('span');
    zoomInLabel.innerHTML = '<i class="fas fa-plus"></i>';
    let zoomOutLabel = document.createElement('span');
    zoomOutLabel.innerHTML = '<i class="fas fa-minus"></i>';

    this.olmap = new Map({
      controls: [
        new FullScreen({
          label: fullScreenLabel,
          labelActive: fullScreenLabelActive,
          tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fullScreenTip' })
        }),
        new Zoom({
          zoomInLabel: zoomInLabel,
          zoomInTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.zoomInTipLabel' }),
          zoomOutLabel: zoomOutLabel,
          zoomOutTipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.zoomOutTipLabel' }),
        }),
        new ZoomSlider()
      ],
      target: null,
      layers: [],
      view: new View({
        projection: MAP_CRS,
        center: this.state.center,
        zoom: this.state.zoom,
        extent: !isEmpty(this.props.maxExtentParameter) ? this.props.maxExtentParameter : undefined
      })
    });

    this.insertInfoToastGeometry = new InfoToastInsertModifyGeometry({
      text: getInternationalization(this.props.locale, PARAMETER_GEOGRAPHICAL_VIEWER_INSERTION_TOAST_TEXT, this.props.allCodes, this.props.activeLanguages)
    });

    this.modifyInfoToastGeometry = new InfoToastInsertModifyGeometry({
      text: getInternationalization(this.props.locale, PARAMETER_GEOGRAPHICAL_VIEWER_MODIFICATION_TOAST_TEXT, this.props.allCodes, this.props.activeLanguages)
    });

  }
  handleCreateElement = () => {

    if (this.state.createElement) {

      if (this.state.user) {

        jQuery("#addGeometricElementSelector").val(this.state.createElement);
        if (!jQuery("#addGeometricElementRootDiv").hasClass("ol-control-active")) {

          jQuery("#addGeometricElementButton").trigger('click');

        }
        this.props.history.replace('/');

      } else {

        this.setState({

          loginModalShow: true

        });

      }

    }

  }

  handleOpenSignUpModalWindow = () => {

    this.setBackendErrors(null);
    this.setState({

      loginModalShow: false,
      signUpModalShow: true

    });

  }

  componentDidMount() {

    this.olmap.on('moveend', () => {
      this.props.dispatch(actions.mapCurrentExtent(this.olmap.getView().calculateExtent()));
    })

    this.removeAllAddedInteractions = () => {
      this.olmap.removeInteraction(this.state.drawInteraction);
      this.olmap.removeInteraction(this.state.dragZoomInteraction);
      this.olmap.removeInteraction(this.state.modifyInteraction);
      this.olmap.removeInteraction(this.state.snapInteraction);
      this.olmap.removeControl(this.insertInfoToastGeometry);
      this.olmap.removeControl(this.modifyInfoToastGeometry);
    }

    this.setInteractions = (drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction) => {
      this.setState({
        drawInteraction, dragZoomInteraction, modifyInteraction, snapInteraction
      });
    }

    this.addGeometricElementControl = new AddGeometricElementController({

      source: this.drawSource,
      setInteractions: this.setInteractions,
      allGeometricElementType: this.props.allGeometricElementType,
      handleGeom: this.handleGeom,
      openAddGeometricElementForm: this.props.openAddGeometricElementForm,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      dispatch: this.props.dispatch,
      disableAllInteractions: this.removeAllAddedInteractions,
      hideAllOverlays: hideAllOverlays,
      allCodes: this.props.allCodes,
      locale: this.state.locale,
      activeLanguages: this.props.activeLanguages,
      formatMessage: this.props.intl.formatMessage,
      enableGeographicalViewerInsertionToast: this.props.enableGeographicalViewerInsertionToast,
      insertInfoToastGeometry: this.insertInfoToastGeometry,
      enableGeographicalViewerModificationToast: this.props.enableGeographicalViewerModificationToast,
      modifyInfoToastGeometry: this.modifyInfoToastGeometry

    });

    this._isMounted = true;
    var map = this.olmap;
    var allCodes = this.props.allCodes;
    this.olmap.setTarget("map");
    let openAddGeometricElementForm = this.props.openAddGeometricElementForm

    var popupCloser = document.getElementById('popupCloser');

    var overlay = new Overlay({
      element: ReactDOM.findDOMNode(this).querySelector('#layersPopup'),
      autoPan: true,
      offset: [0, -10],
      positioning: 'top-right'
    });

    map.addOverlay(overlay);

    popupCloser.onclick = function () {
      overlay.setPosition(undefined);
      popupCloser.blur();
      return false;
    }

    this.clearDrawSource = () => {
      this.drawSource.clear({ fast: true })
    }

    var drawVector = new VectorLayer({
      source: this.drawSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(200, 0, 0, 0.5)',
        }),
        stroke: new Stroke({
          color: 'rgba(180, 0, 0, 1)',
          width: 3
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(200, 0, 0, 0.5)',
          }),
          stroke: new Stroke({
            color: 'rgba(180, 0, 0, 1)',
            width: 2
          })
        })
      })
    });

    var measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    var measureTooltipOverlay = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });

    map.addOverlay(measureTooltipOverlay);

    this.handleGeom = geom => {
      this.setState({ geom: geom })
    }

    this.handleModifyFeature = (modifyFeature) => {
      this.setState({ featureToModify: modifyFeature })
    }

    let handleGeom = this.handleGeom;
    let handleModifyFeature = this.handleModifyFeature;
    let locale = this.state.locale;
    let user = this.props.user;
    let dispatch = this.props.dispatch;
    let setInteractions = this.setInteractions;
    let enableGeographicalViewerModificationToast = this.props.enableGeographicalViewerInsertionToast;
    let modifyInfoToastGeometry = this.modifyInfoToastGeometry

    this.modifyFeature = function (map, source, feature) {
      if (jQuery('#popupcontrollerdiv').hasClass('ol-control-active'))
        jQuery("#poupcontrollerbutton").click();

      if (!(feature instanceof Feature)) {
        if (jQuery('.layer-switcher').hasClass('shown'))
          jQuery('.layer-switcher button').click();
        var featureRequest = new WFS().writeGetFeature({
          srsName: FEATURES_CRS,
          featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
          featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
          outputFormat: 'application/json',
          geometryName: FEATURES_CRS,
          filter: equalToFilter('id', feature.id)
        });

        let configFetch = {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest)
        }

        if (configFetch.headers) {
          configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
        } else {
          configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
        }

        fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`, configFetch)
          .then(function (response) {
            return response.json();
          }).then(function (json) {
            var features = new GeoJSON().readFeatures(json);
            let featureToModify = features[0];
            let clonedFeature = featureToModify.clone();
            clonedFeature.getGeometry().transform(FEATURES_CRS, MAP_CRS);
            let featureCollection = new Collection([]);
            featureCollection.getArray().splice(0, 0, clonedFeature);
            let modifyInteraction = new Modify({
              source: source
            });
            setInteractions(null, null, modifyInteraction, null);
            map.addInteraction(modifyInteraction);
            source.addFeature(clonedFeature);
            modifyInteraction.on('modifyend', function (event) {
              var features = event.features;
              var featureToTransform = features.array_[0].clone()
              var geometry = featureToTransform.getGeometry();
              geometry.transform(MAP_CRS, FEATURES_CRS)
              handleGeom({
                "type": `${geometry.getType()}`,
                "coordinates": geometry.getCoordinates()
              });
            });
            if (enableGeographicalViewerModificationToast) {

              map.addControl(modifyInfoToastGeometry);

            }
            openAddGeometricElementForm(map);
            setCursorToEditGeometry();
          });
      } else {
        let clonedFeature = feature.clone();
        let startModifyFeature = (featureToModify) => {
          if (jQuery('.layer-switcher').hasClass('shown'))
            jQuery('.layer-switcher button').click();
          handleModifyFeature(featureToModify);
          openAddGeometricElementForm(map);
          clonedFeature.getGeometry().transform(FEATURES_CRS, MAP_CRS);
          let featureCollection = new Collection([]);
          featureCollection.getArray().splice(0, 0, clonedFeature);
          let modifyInteraction = new Modify({
            source: source
          });
          setInteractions(null, null, modifyInteraction, null);
          map.addInteraction(modifyInteraction);
          source.addFeature(clonedFeature);
          modifyInteraction.on('modifyend', function (event) {
            var features = event.features;
            var featureToTransform = features.array_[0].clone();
            var geometry = featureToTransform.getGeometry();
            geometry.transform(MAP_CRS, FEATURES_CRS);
            handleGeom({
              "type": `${geometry.getType()}`,
              "coordinates": geometry.getCoordinates()
            });
          });
          if (enableGeographicalViewerModificationToast) {

            map.addControl(modifyInfoToastGeometry);

          }
          setCursorToEditGeometry();
        }
        if (user) {
          if (user.userRoleDto.code === "ADMIN") {
            elementService.findAdminGeometricElementById(clonedFeature.get('id'), locale,
              (featureToModify) => {
                startModifyFeature(featureToModify);
              }, () => {
                dispatch(appActions.error({
                  message:
                    <FormattedMessage
                      id="project.elements.modify.error"
                    />
                }));
              });
          } else {
            elementService.findUserGeometricElementById(clonedFeature.get('id'), locale,
              (featureToModify) => {
                startModifyFeature(featureToModify);
              }, () => {
                dispatch(appActions.error({
                  message:
                    <FormattedMessage
                      id="project.elements.modify.error"
                    />
                }));
              });
          }
        } else {
          dispatch(appActions.error({
            message:
              <FormattedMessage
                id="project.elements.modify.error"
              />
          }));
        }
      }
    };

    let removeCenterFeature = () => {
      this.setState({ centerFeature: null });
    }

    this.centerFeature = function (map, feature) {
      let geometry = null;
      if (feature instanceof Feature) {
        geometry = feature.clone().getGeometry();
      } else {
        geometry = new GeoJSON().readGeometry(feature.geom);
      }
      geometry.transform(FEATURES_CRS, MAP_CRS)

      if (geometry.getType() === 'Point') {
        map.getView().fit(
          buffer(geometry.getExtent(), 50),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      } else {
        map.getView().fit(
          buffer(geometry.getExtent(), getHeight(geometry.getExtent())),
          {
            size: map.getSize(),
            duration: 100,
            callback: function () {
              overlay.panIntoView({ margin: 20 });
            }
          }
        );
      }
      removeCenterFeature()
    };

    const getAllGeometricElementType = async (features) => {
      features.forEach(async feature => {
        let getGeometricElementUrl = (featureId) => {
          return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}`
        }
        let authorization = {
          method: 'GET',
        }
        if (this.props.user) {
          if (authorization.headers) {
            authorization.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
          } else {
            authorization.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
          }
          if (this.props.user.userRoleDto.code === "ADMIN") {
            getGeometricElementUrl = (featureId) => {
              return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}/admin`
            }
          } else {
            getGeometricElementUrl = (featureId) => {
              return `${process.env.REACT_APP_BACKEND_URL}/elements/geometric_element/${featureId}/user`
            }
          }
        }
        return await fetch(getGeometricElementUrl(`${feature.get('id')}`),
          authorization
        ).then(async (response) => {
          const geometricElement = await response.json();
          feature.geometricElement = geometricElement
          this.setState(prevState => ({ geometricElementHasFinded: prevState.geometricElementHasFinded + 1 }))
        });
      });
      return features;
    }

    const getAllLayersInfo = (coordinates) => {
      this.setState({
        popupLoading: true,
        popupFailedLayers: [],
        popupAllLayersInfo: [],
        arePendingRequests: true
      });

      jQuery('#map').css('cursor', 'wait');

      let mapLayers = [];
      let popupFetchAbortControllers = [];
      let props = this.props;

      if (jQuery('#popupControllerSelectorDivContent').length) {
        var extent = map.getView().calculateExtent();
        var offsetWidth = document.getElementById(MAP_ID).offsetWidth;
        var metersPerPixel = Math.abs(extent[0] - extent[2]) / offsetWidth;
        var tolerance = this.props.clickPixelTolerance * metersPerPixel;
        const selectedLayers = document.querySelector('#popupControllerSelectorDivContent').getSelectedOptions().map(option => Number(option.value));
        let filteredLayers = map.getLayerGroup().getLayersArray().reverse();
        filteredLayers = filteredLayers.filter((layer, index) => selectedLayers.includes(index));
        let geometricElementLayersFilter = filteredLayers.filter(layer => layer.get('isGeometricElementType'));
        geometricElementLayersFilter = geometricElementLayersFilter.map(layer =>
          equalToFilter('geometric_element_type_id', layer.get('geometricElementTypeId')));

        let geometricElementLayersRequest = null;
        if (geometricElementLayersFilter.length) {
          var featureRequest = new WFS().writeGetFeature({
            srsName: FEATURES_CRS,
            featureNS: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
            featureTypes: ['geometric_element_point', 'geometric_element_line', 'geometric_element_polygon'],
            outputFormat: 'application/json',
            geometryName: FEATURES_CRS,
            bbox: boundingExtent(
              [
                transform([
                  coordinates[0] - tolerance,
                  coordinates[1] - tolerance
                ],
                  MAP_CRS, FEATURES_CRS),
                transform([
                  coordinates[0] + tolerance,
                  coordinates[1] + tolerance
                ],
                  MAP_CRS, FEATURES_CRS)
              ]
            ),
            filter: geometricElementLayersFilter.length > 1 ? orFilter(...geometricElementLayersFilter) : geometricElementLayersFilter[0]
          });
          const geometricElementsAbortController = new AbortController();
          popupFetchAbortControllers.push(geometricElementsAbortController);
          const geometricElementsTimeout = setTimeout(() => geometricElementsAbortController.abort(), this.props.otherLayersInfoTimeout);
          let configFetch = {
            method: 'POST',
            body: new XMLSerializer().serializeToString(featureRequest),
            signal: geometricElementsAbortController.signal
          }
          if (sessionStorage.getItem("serviceToken")) {
            if (configFetch.headers) {
              configFetch.headers['Authorization'] = `Bearer ${sessionStorage.getItem("serviceToken")}`;
            } else {
              configFetch.headers = { 'Authorization': `Bearer ${sessionStorage.getItem("serviceToken")}` };
            }
          }

          geometricElementLayersRequest = fetch(`${process.env.REACT_APP_BACKEND_URL}/mapserver?SERVICE=WFS`,
            configFetch
          ).then(async function (response) {
            clearTimeout(geometricElementsTimeout);
            const data = await response.json();
            var features = new GeoJSON().readFeatures(data);
            features = await getAllGeometricElementType(features);
            features = groupBy(features, 'values_.geometric_element_type');
            let geometricElementTypeCodes = Object.keys(features);
            return geometricElementTypeCodes.map(code => {
              return {
                layer: getInternationalization(props.locale, code, props.allCodes, props.activeLanguages),
                data: features[code],
                isGeometricElementType: true,
                contentType: response.headers.get("content-type")
              };
            });
          }).catch(error => {
            let failedLayers = "";
            let geometricElementLayers = filteredLayers.filter(layer => layer.get('isGeometricElementType'));
            geometricElementLayers.forEach((layer, index, array) => {
              failedLayers += layer.get('title');
              if (index < array.length - 1) {
                failedLayers += ', ';
              }
            });

            return failedLayers;
          });
        }

        let otherGeometricLayersRequests = filteredLayers
          .filter(layer => !layer.get('isGeometricElementType') && layer.get('supportsGetFeatureInfo'));
        otherGeometricLayersRequests = otherGeometricLayersRequests.map(async layer => {
          let source = layer.get('source');
          let getFeatureInfoParams = {
            'INFO_FORMAT': getFeatureInfoCodes[layer.get('getFeatureInfoFormat')],
            'QUERY_LAYERS': source.getParams().layers
          }
          if (layer.get('isInternal')) {
            getFeatureInfoParams['FI_POINT_TOLERANCE'] = this.props.clickPixelTolerance.toFixed();
            getFeatureInfoParams['FI_LINE_TOLERANCE'] = this.props.clickPixelTolerance.toFixed();
            getFeatureInfoParams['FI_POLYGON_TOLERANCE'] = this.props.clickPixelTolerance.toFixed();
          }
          let url = source.getFeatureInfoUrl(
            coordinates,
            map.getView().getResolution(),
            'EPSG:3857',
            getFeatureInfoParams
          );
          if (url) {
            const abortController = new AbortController();
            popupFetchAbortControllers.push(abortController);
            const timeout = setTimeout(() => abortController.abort(), this.props.otherLayersInfoTimeout);
            return fetch(url, { signal: abortController.signal }).then(response => {
              clearTimeout(timeout);
              if (response.ok) {
                let contentType = response.headers.get("content-type") || getFeatureInfoCodes[layer.get('getFeatureInfoFormat')];

                if (contentType.includes('json')) {
                  return response.json().then(data => {
                    var features = new GeoJSON().readFeatures(data);
                    if (features.length > 0) {
                      return { layer: layer.get('title'), data: features, isGeometricElementType: false, contentType }
                    } else {
                      return null
                    }
                  });
                } else {
                  return response.text().then(data => {
                    if (!data.includes('LayerNotQueryable') && !data.includes('InvalidFormat')) {
                      return { layer: layer.get('title'), data, isGeometricElementType: false, contentType }
                    } else {
                      return layer.get('title');
                    }
                  });
                }
              } else {
                return layer.get('title');
              }
            }).catch(error => {
              return layer.get('title');
            })
          }
        });

        if (geometricElementLayersRequest) {
          mapLayers = [geometricElementLayersRequest];
        }
        mapLayers.push(...otherGeometricLayersRequests);
      }
      Promise.all(mapLayers).then(values => {
        jQuery('#map').css('cursor', 'help');
        let flatValues = [].concat.apply([], values);
        this.setState({
          arePendingRequests: false,
          popupLoading: false,
          popupFailedLayers: flatValues.filter(value => value && !value.data && !value.layer),
          popupAllLayersInfo: flatValues.filter(value => value && value.data)
        });
      });
    }

    this.showPopup = (evt) => {
      if ((jQuery('#popupcontrollerdiv').hasClass('ol-control-active')) && !this.state.arePendingRequests) {
        overlay.setPosition(evt.coordinate)
        getAllLayersInfo(evt.coordinate);
        return;
      }
    }

    // If max extent is valid add zoom to extent control
    if (!isEmpty(this.props.maxExtentParameter)) {
      map.addControl(new ZoomToExtent({
        extent: this.props.maxExtentParameter,
        label: this.props.maxExtentIcon,
        tipLabel: this.props.intl.formatMessage({ id: 'project.elements.map.fitToExtentTip' })
      }));
    }

    map.addControl(new AreaMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new LengthMeasureController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      overlay: measureTooltipOverlay,
      overlayElement: measureTooltipElement,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new ZoomToExtentController({
      source: this.drawSource,
      setInteractions: this.setInteractions,
      disableAllInteractions: this.removeAllAddedInteractions,
      closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
      hideAllOverlays: hideAllOverlays,
      formatMessage: this.props.intl.formatMessage
    }));
    map.addControl(new OverviewMap({
      layers: [new TileLayer({
        source: new OSM()
      })]
    }));

    if (this.props.showEntityPopulationCenterControl && this.props.listProvinces && this.props.listProvinces.length) {

      map.addControl(new EntityPopulationCenterControl({

        source: this.drawSource,
        setInteractions: this.setInteractions,
        disableAllInteractions: this.removeAllAddedInteractions,
        closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
        hideAllOverlays: hideAllOverlays,
        formatMessage: this.props.intl.formatMessage,
        listProvinces: this.props.listProvinces,
        listCouncils: this.props.listCouncils,
        listParishes: this.props.listParishes,
        listEntityPopulations: this.props.listEntityPopulations,
        listCounties: this.props.listCounties,
        provincesBuffer: this.props.provincesBuffer,
        councilsBuffer: this.props.councilsBuffer,
        parishesBuffer: this.props.parishesBuffer,
        entityPopulationsBuffer: this.props.entityPopulationsBuffer,
        countiesBuffer: this.props.countiesBuffer,
        defaultActivate: this.props.manageDefaultActivateGeographicalViewerSearch

      }));

    }

    map.addControl(new ShowLegendControl({
      hideShowLegend: this.hideShowLegend,
      formatMessage: this.props.intl.formatMessage,
      legendComponent: this.geographicalViewerLegendRef.current
    }))

    if (this.props.enableGeographicalViewerScale) {
      map.addControl(new ScaleLine({
        bar: true,
        steps: 2
      }));
    }
    if (this.props.allowExportGeographicalViewerToPDF) {
      map.addControl(new ExportToPDFControl({
        formatMessage: this.props.intl.formatMessage,
        showExportPDFMenu: () => { this.setState({ generatePdfMenuModalShow: true }) }
      }));
    }

    if (this.props.showGeoBookmarkControl) {
      map.addControl(new GeoBookmark({
        title: this.props.intl.formatMessage({ id: 'project.elements.map.bookmarkControl.title' }),
        placeholder: this.props.intl.formatMessage({ id: 'project.elements.map.bookmarkControl.placeholder' })
      }));
    }

    if (this.props.showMousePositionCoordinates) {
      // Support EPSG:25829 coordinates system
      proj4.defs("EPSG:25829", "+proj=utm +zone=29 +ellps=GRS80 +units=m +no_defs");
      register(proj4);
      let projection = getProjection(this.props.showMousePositionCoordinatesProjection);
      if (projection) {
        let mousePositionControl = new MousePosition({
          coordinateFormat: (coordinate) => {
            return format(coordinate, `{x}:{y} - ${this.props.showMousePositionCoordinatesProjection}`, 1)
          },
          undefinedHTML: null,
          projection: projection
        });
        map.addControl(mousePositionControl);
      }
    }

    if (this.state.user && this.props.canAddGeometricElement) {

      map.addControl(this.addGeometricElementControl);

    }

    let addAuthenticationWMSFunction = function (tile, src) {
      var client = new XMLHttpRequest();
      client.open('GET', src);
      client.responseType = "arraybuffer";
      if (sessionStorage.getItem("serviceToken")) {
        client.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("serviceToken"));
      }
      client.onload = function () {
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      }
      client.send();
    }

    let placeAfterOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.placeBehindOtherLayers);
    placeAfterOtherLayersGeometricLayerGroup = placeAfterOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    let placeInsideOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => layerGroup.includeInsideGroupLayer);
    placeInsideOtherLayersGeometricLayerGroup = placeInsideOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    let placeBeforeOtherLayersGeometricLayerGroup = this.props.listAllGeometricLayerGroup.filter(layerGroup => !layerGroup.placeBehindOtherLayers && !layerGroup.includeInsideGroupLayer);
    placeBeforeOtherLayersGeometricLayerGroup = placeBeforeOtherLayersGeometricLayerGroup.sort((a, b) => {
      return b.order - a.order;
    });

    placeAfterOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
      insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
        this.props.locale, this.props.allCodes, this.props.activeLanguages, this.props.allowConfigureLayersOpacity);
    });

    if (this.props.listAllGeometricLayerGroup.filter(geometricLayerGroup => (geometricLayerGroup.includeInsideGroupLayer && geometricLayerGroup.listGeometricLayer.length > 0)).length > 0) {
      placeInsideOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
        insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
          this.props.locale, this.props.allCodes, this.props.activeLanguages, this.props.allowConfigureLayersOpacity);
      });
      this.olmap.addLayer(this.otherLayersGroup);
    }

    placeBeforeOtherLayersGeometricLayerGroup.forEach(geometricLayerGroup => {
      insertGeometricLayerGroup(geometricLayerGroup, this.olmap, this.otherLayersGroup,
        this.props.locale, this.props.allCodes, this.props.activeLanguages, this.props.allowConfigureLayersOpacity);
    });

    this.refreshLayersCollection = () => {
      layersCollection.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
      myLayersColletion.forEach(layer => {
        let params = layer.getSource().getParams();
        params.t = new Date().getMilliseconds();
        layer.getSource().updateParams(params);
      });
    }

    //One layer per geometric element type
    let layersCollection = new Collection([]);
    //One layer per geometric element type (My elements)
    let myLayersColletion = new Collection([]);
    if (this.props.canQueryGeometricElement) {
      this.props.allGeometricElementType.forEach((geometricElementType, index) => {
        let styles = [];
        let geometryType = geometricElementType.geometryType;
        if (geometryType === "ANY_GEOMETRY") {
          styles = [
            geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "",
            geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "",
            geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "",
          ]
        } else {
          if (geometryType.includes("POINT")) {
            styles.push(geometricElementType.pointStyleName ? geometricElementType.pointStyleName : "")
          }
          if (geometryType.includes("LINE")) {
            styles.push(geometricElementType.lineStyleName ? geometricElementType.lineStyleName : "")
          }
          if (geometryType.includes("POLYGON")) {
            styles.push(geometricElementType.polygonStyleName ? geometricElementType.polygonStyleName : "")
          }
        }
        layersCollection.insertAt(index, new ImageLayer({
          title: getInternationalization(
            this.state.locale,
            geometricElementType.code,
            this.props.allCodes,
            this.props.activeLanguages
          ),
          enableOpacitySlider: this.props.allowConfigureLayersOpacity && geometricElementType.allowChangeOpacity,
          opacity: geometricElementType.defaultOpacity ? geometricElementType.defaultOpacity : 1,
          source: new ImageWMS({
            url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
            params: {
              'layers': [geometricElementType.code],
              'VERSION': WMS_VERSION,
              'REQUEST': 'GetMap',
              'TILED': true,
              'STYLES': styles
            },
            projection: MAP_CRS,
            imageLoadFunction: addAuthenticationWMSFunction,
            // Prevent CORS error when exporting to PDF
            crossOrigin: 'anonymous'
          }),
          projection: MAP_CRS,
          legendType: geometricElementType.legendType,
          legendTypeValue: geometricElementType.legendTypeValue,
          isGeometricElementType: true,
          geometricElementTypeId: geometricElementType.id
        }))

        myLayersColletion.insertAt(index, new ImageLayer({
          title: getInternationalization(
            this.state.locale,
            geometricElementType.code,
            this.props.allCodes,
            this.props.activeLanguages
          ),
          enableOpacitySlider: this.props.allowConfigureLayersOpacity && geometricElementType.allowChangeOpacity,
          opacity: geometricElementType.defaultOpacity ? geometricElementType.defaultOpacity : 1,
          source: new ImageWMS({
            url: `${process.env.REACT_APP_BACKEND_URL}/mapserver`,
            params: {
              'layers': ["MY_" + geometricElementType.code],
              'VERSION': WMS_VERSION,
              'REQUEST': 'GetMap',
              'TILED': true,
              'STYLES': styles
            },
            projection: MAP_CRS,
            imageLoadFunction: addAuthenticationWMSFunction,
            // Prevent CORS error when exporting to PDF
            crossOrigin: 'anonymous'
          }),
          projection: MAP_CRS,
          legendType: geometricElementType.legendType,
          legendTypeValue: geometricElementType.legendTypeValue,
          isGeometricElementType: true,
          geometricElementTypeId: geometricElementType.id
        }))
      });

      this.elementsGroup.setLayers(layersCollection);
      this.myElementsGroup.setLayers(myLayersColletion);

      this.olmap.addLayer(this.elementsGroup);
      if (this.state.user) {

        this.olmap.addLayer(this.myElementsGroup);

      }
    }

    map.addLayer(drawVector);

    this.olmap.addControl(this.layerSwitcherControl);

    // Only add popup control if any layer can be queried
    if (this.olmap.getLayerGroup().getLayersArray().some(layer => layer.get('supportsGetFeatureInfo') || layer.get('isGeometricElementType'))) {

      let controlInitiallyEnabled = !this.state.createElement && this.props.defaultEnablePopupControl;

      map.addControl(new PopupController({
        overlay: overlay,
        source: this.drawSource,
        allCodes: allCodes,
        locale: this.state.locale,
        activeLanguages: this.props.activeLanguages,
        user: this.state.user,
        disableAllInteractions: this.removeAllAddedInteractions,
        hideAllOverlays: hideAllOverlays,
        closeAddGeometricElementForm: this.props.closeAddGeometricElementForm,
        openAddGeometricElementForm: openAddGeometricElementForm,
        handleGeom: this.handleGeom,
        handleModifyFeature: this.handleModifyFeature,
        formatMessage: this.props.intl.formatMessage,
        showOverlay: () => { overlay.setPosition(this.state.popupCoordinates); },
        map: this.olmap,
        initiallyEnabled: controlInitiallyEnabled
      }));
      map.on('singleclick', (event) => this.showPopup(event));

    }

    if (this.props.mapCurrentExtent) {
      map.getView().fit(this.props.mapCurrentExtent);
    } else {
      if (!isEmpty(this.props.initialExtendParameter)) {
        map.getView().fit(this.props.initialExtendParameter);
      }
    }

    if (this.state.centerFeature) {
      this.centerFeature(this.olmap, this.state.centerFeature)
    }

    if (this.state.featureToModify && this.state.user) {
      this.modifyFeature(this.olmap, this.drawSource, this.state.featureToModify);
      this.centerFeature(this.olmap, this.state.featureToModify);
    }

    this.olmap.getLayerGroup().getLayers().forEach(baseLayer => {
      baseLayer.getLayersArray().forEach(layer => {
        layer.on('change:visible', () => this.forceUpdate());
      })
    })

    this.handleCreateElement();

  }

  componentDidUpdate(prevProps) {

    // Move legend
    jQuery('.legend').draggable({
      cursor: 'move'
    });

    if (this.props.locale !== this.state.locale) {

      this.setState({ locale: this.props.locale });
      this.forceUpdate();

    }

    if (this.props.location.state) {

      if (typeof prevProps.location.state === 'undefined') {

        if (this.props.location.state.featureToModify) {

          this.setState({ featureToModify: this.props.location.state.featureToModify });

        }
      } else {

        if (prevProps.location.state.featureToModify !== this.props.location.state.featureToModify) {

          this.setState({ featureToModify: this.props.location.state.featureToModify });

        }

      }
    }

    if (this.state.user !== this.props.user) {

      this.setState({ user: this.props.user }, () => {

        this.olmap.addLayer(this.myElementsGroup);

        this.layerSwitcherControl.renderPanel();

        if (this.props.canAddGeometricElement) {

          this.olmap.addControl(this.addGeometricElementControl);

        }

        this.handleCreateElement();

      });

    }

    if (this.props.createElement !== this.state.createElement) {

      this.setState({ createElement: this.props.createElement }, () => {

        this.handleCreateElement();

      });

    }

  }

  render() {

    return (
      <div id="geographicalViewer">
        <GeographicalViewerLegend
          layers={this.olmap.getLayerGroup().getLayersArray()}
          legendShow={this.state.showLegend}
          closeLegend={this.hideShowLegend}
          innerRef={this.geographicalViewerLegendRef}
        />
        <GeographicalViewerPopup
          allLayersInfo={this.state.popupAllLayersInfo}
          failedLayers={this.state.popupFailedLayers}
          loading={this.state.popupLoading}
          map={this.olmap}
          clickPixelTolerance={this.props.clickPixelTolerance}
          centerFeature={this.centerFeature}
          modifyFeature={this.modifyFeature}
          drawSource={this.drawSource}
          setGeometricElementFileGalleryModalShowAndFiles={(modalShow, files) =>
            this.setState({
              geometricElementFileGalleryModalShow: modalShow,
              geometricElementFileGalleryFiles: files
            })
          }
          showRelateGeometricElements={this.showRelateGeometricElements}
          enableGeometricElementsRelation={this.props.enableGeometricElementsRelation}
          showJoinGeometricElement={this.showJoinGeometricElement}
        />
        {/* View photos gallery dialog */}
        <GeometricElementFileGallery
          modalShow={this.state.geometricElementFileGalleryModalShow}
          geometricElementFiles={this.state.geometricElementFileGalleryFiles}
          hideModalWindow={() => this.setState({ geometricElementFileGalleryModalShow: false })}
        />
        <div id="map-container">
          <div id="map" className="openlayers-map"
            style={{
              width: this.props.style && this.props.style.width ? this.props.style.width : "100%",
              height: this.props.style && this.props.style.height ? this.props.style.height : "82vh"
            }}
          ></div>
        </div>
        {this.props.allowExportGeographicalViewerToPDF ?
          <div id="exportToPDF">
            {/* Export to pdf menu */}
            <GeographicalViewerExportMenu
              modalShow={this.state.generatePdfMenuModalShow}
              closeModalWindow={() => this.setState({ generatePdfMenuModalShow: false })}
              showError={() => { this.setState({ generatePdfErrorModalShow: true }) }}
              map={this.olmap}
            />
            {/* Error modal window on generate PDF */}
            <Modal show={this.state.generatePdfErrorModalShow}
              onHide={() => this.setState({ generatePdfErrorModalShow: false })}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage id="project.common.ErrorDialog.title" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage id="project.elements.map.exportPDFError" />
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={() => this.setState({ generatePdfErrorModalShow: false })}>
                  <FormattedMessage id="project.common.close" />
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          :
          ""}
        <AddGeometricElement
          geom={this.state.geom}
          closeAddGeometricElementForm={this.props.closeAddGeometricElementForm}
          clearDrawSource={this.clearDrawSource}
          removeAllAddedInteractions={this.removeAllAddedInteractions}
          refreshLayersCollection={this.refreshLayersCollection}
          restartAddGeometricElementInteraction={this.state.restartAddGeometricElementInteraction}
          modifyFeature={this.state.featureToModify}
          map={this.olmap}
          resetModifyFeature={this.resetModifyFeature}
          resetCreateElement={this.resetCreateElement}
          formatMessage={this.props.intl.formatMessage}
          submitModifyFromDetailsPage={this.props.submitModifyFromDetailsPage}
          cancelModifyFromDetailsPage={this.props.cancelModifyFromDetailsPage}
        />
        {/* Relate geometric elements dialog */}
        <AddModifyGeometricElementRelation
          modalShow={this.state.relateGeometricElementsModalVisible}
          geometricElement={this.state.geometricElementToRelate}
          handleSubmit={() => {
            this.setState({ relateGeometricElementsModalVisible: false });
            this.props.history.push({
              pathname: "/geometric_element/details/" + this.state.geometricElementToRelate.id,
              state: {
                scrollToRelations: true
              }
            });
          }}
          geometricElementRelationToModify={null}
          backendErrors={this.state.backendErrors}
          setBackendErrors={this.setBackendErrors}
          hideModalWindow={() => this.setState({ relateGeometricElementsModalVisible: false })}
        />
        {/* Join geometric elements dialog */}
        <AddModifyGeometricElementJoin
          modalShow={this.state.joinGeometricElementsModalVisible}
          geometricElement={this.state.geometricElementToJoin}
          handleSubmit={() => {
            this.setState({ joinGeometricElementsModalVisible: false });
            this.props.history.push({
              pathname: "/geometric_element/details/" + this.state.geometricElementToJoin.id,
              state: {
                scrollToJoins: true
              }
            });
          }}
          geometricElementJoinToModify={null}
          backendErrors={this.state.backendErrors}
          setBackendErrors={this.setBackendErrors}
          hideModalWindow={() => this.setState({ joinGeometricElementsModalVisible: false })}
        />
        <Login
          modalShow={this.state.loginModalShow}
          createElement={this.state.createElement}
          backendErrors={this.state.backendErrors}
          setBackendErrors={this.setBackendErrors}
          hideModalWindow={() => {

            this.setState({ loginModalShow: false });
            this.props.history.replace('/');

          }}
          handleOpenSignUpModalWindow={this.handleOpenSignUpModalWindow}
        />
        <SignUp
          modalShow={this.state.signUpModalShow}
          backendErrors={this.state.backendErrors}
          handleAddNewUser={null}
          setBackendErrors={this.setBackendErrors}
          createElement={this.state.createElement}
          hideModalWindow={() => {

            this.setState({ signUpModalShow: false });
            this.props.history.replace('/');

          }}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(injectIntl(GeographicalViewer)));