import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/app/components/App';
import * as serviceWorker from './serviceWorker';
import backend from './backend';
import app from './modules/app';
import { NetworkError } from './backend';
import { Provider } from 'react-redux';
import configureStore from './store';
import jQuery from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'ol/src/ol.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "ol-layerswitcher/src/ol-layerswitcher.css"
import './modules/globalstyles.css';
import 'autocompleter/autocomplete.css';
import 'bootstrap';
import 'filepond/dist/filepond.min.css';
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'
import 'ol-ext/control/GeoBookmark.css'
import 'filepond/dist/filepond.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './modules/geometricElements/components/openlayersControls.css';
import './modules/geometricElements/components/popup.css';

//In order to avoid name conflicts with jQuery-UI
jQuery.fn.bstooltip = jQuery.fn.tooltip;

//Configure store 
const store = configureStore();

/* Configure backend proxy. */
backend.init(error => store.dispatch(app.actions.error(new NetworkError())));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

serviceWorker.register();
