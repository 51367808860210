import * as actionTypes from './actionTypes';
import * as appSelectors from '../app/selectors';
import * as selectors from './selectors';
import * as appActions from '../app/actions';
import backend from '../../backend';
import { generateIdentificatorForLoadingAction } from '../app/util';

const findAllFileFormatCompleted = allFileFormat => ({
    type: actionTypes.FIND_ALL_FILE_FORMAT,
    allFileFormat
})

export const findAllFileFormat = (onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.attachedFileService.findAllFileFormat(appSelectors.getLanguage(getState()),
        result => {
            dispatch(findAllFileFormatCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        },
        error => {
            if (typeof onErrors === "function") {
                onSuccess(error);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

const findTotalGeneralAttachedFilesCompleted = totalGeneralAttachedFiles => ({
    type: actionTypes.FIND_TOTAL_GENERAL_ATTACHED_FILES_COMPLETED,
    totalGeneralAttachedFiles
})

export const findTotalGeneralAttachedFiles = (page, size, sortField, sortOrder, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.attachedFileService.findTotalGeneralAttachedFiles(page, size, sortField, sortOrder, appSelectors.getLanguage(getState()),
        result => {
            dispatch(findTotalGeneralAttachedFilesCompleted(result));
            if (typeof onSuccess === "function") {
                onSuccess(result);
            }
            dispatch(appActions.loaded(loadingId));
        },
        error => {
            if (typeof onErrors === "function") {
                onSuccess(error);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

const addGeneralAttachedFileCompleted = (addedGeneralAttachedFile) => ({
    type: actionTypes.ADD_GENERAL_ATTACHED_FILE_COMPLETED,
    addedGeneralAttachedFile
});

export const addGeneralAttachedFile = (json, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.attachedFileService.addGeneralAttachedFile(json, appSelectors.getLanguage(getState()),
        (result) => {
            dispatch(addGeneralAttachedFileCompleted(result))
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

const modifyGeneralAttachedFileCompleted = (modifiedGeneralAttachedFile) => ({
    type: actionTypes.MODIFY_GENERAL_ATTACHED_FILE_COMPLETED,
    modifiedGeneralAttachedFile
});

export const modifyGeneralAttachedFile = (id, json, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.attachedFileService.modifyGeneralAttachedFile(id, json, appSelectors.getLanguage(getState()),
        (result) => {
            let allGeneralAttachedFiles = selectors.getTotalGeneralAttachedFiles(getState());
            let filteredGeneralAttachedFile = allGeneralAttachedFiles
                .filter(generalAttachedFile => generalAttachedFile.id === Number(id))[0]
            let isJSONAdministrativeDivisions = [result.type, filteredGeneralAttachedFile.type].includes("JSON_ADMINISTRATIVE_DIVISIONS");
            dispatch(modifyGeneralAttachedFileCompleted({ result, isJSONAdministrativeDivisions }));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}

const deleteGeneralAttachedFileCompleted = (generalAttachedFile) => ({
    type: actionTypes.DELETE_GENERAL_ATTACHED_FILE_COMPLETED,
    generalAttachedFile
});

export const deleteGeneralAttachedFile = (id, onSuccess, onErrors) => (dispatch, getState) => {
    const loadingId = generateIdentificatorForLoadingAction();
    dispatch(appActions.loading(loadingId));
    backend.attachedFileService.deleteGeneralAttachedFile(id, appSelectors.getLanguage(getState()),
        () => {
            let allGeneralAttachedFiles = selectors.getTotalGeneralAttachedFiles(getState());
            let filteredGeneralAttachedFile = allGeneralAttachedFiles
                .filter(generalAttachedFile => generalAttachedFile.id === Number(id))[0]
            let isJSONAdministrativeDivisions = filteredGeneralAttachedFile.type === "JSON_ADMINISTRATIVE_DIVISIONS";
            dispatch(deleteGeneralAttachedFileCompleted({ id, isJSONAdministrativeDivisions }));
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
            dispatch(appActions.loaded(loadingId));
        }, (errors) => {
            if (typeof onErrors === 'function') {
                onErrors(errors);
            }
            dispatch(appActions.loaded(loadingId));
        });
}