import React, { useState } from 'react';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Modal } from "react-bootstrap";
import * as FilePond from 'react-filepond';
import PropTypes from 'prop-types';
import jQuery from 'jquery';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
    styleName: Yup.string().required(<FormattedMessage
        id="project.app.Body.field.required"
        values={{
            field: <FormattedMessage id="project.elements.styles.name" />,
        }} />)
})

const AddGeometricElementStyle = ({ title, modalShow, hideModalWindow, layerType, backendErrors, setBackendErrors, ...props }) => {

    let [hasAddedFiles, setHasAddedFiles] = useState(false);
    let [formikErrors, setFormikErrors] = useState(false);

    if (modalShow) {

        if (formikErrors || backendErrors) {
            let container = jQuery('.modal-body');
            if (backendErrors) {
                container.scrollTop(0);
            } else {
                if (formikErrors) {
                    let field = jQuery('.alert-danger:visible:first').prev();
                    if (field.length)
                        jQuery(container).animate({
                            scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                        });
                }
            }
        }

        const initialValues = {
            styleName: "",
            file: []
        }

        return (
            <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Schema}
                onSubmit={(values, { setSubmitting },) => {
                    let formData = new FormData();

                    formData.append("layerType", layerType);
                    formData.append("styleName", values.styleName);
                    formData.append("file", values.file[0]);

                    props.dispatch(actions.addGeometricElementStyle(formData,
                        () => {
                            setHasAddedFiles(false);
                            hideModalWindow();
                        },
                        (error) => setBackendErrors(error))
                    );
                    setSubmitting(false);
                }}
            >
                {({ setFieldValue, setFieldError, errors }) =>
                    <Modal show={modalShow} onHide={hideModalWindow} scrollable={true} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="needs-validation novalidate" id="addGeometricElementStyleForm">

                                {errors ? setFormikErrors(errors) : ""}

                                <Errors errors={backendErrors} onClose={() => setBackendErrors(null)} />

                                <div className="form-group">
                                    <label htmlFor="styleName" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.styles.name" />
                                    </label>
                                    <Field name="styleName" className="form-control" id="styleName" />
                                </div>
                                <ErrorMessage name="styleName" render={(msg) =>
                                    <div id='styleNameError' className="alert alert-danger alert-dismissible fade show" role="alert"
                                        hidden={typeof errors.styleName === "undefined"}
                                    >
                                        <button type="button" className="close" aria-label="Close"
                                            onClick={() => setFieldError("styleName", undefined)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {msg}
                                    </div>
                                }
                                />

                                <div className="form-group">
                                    <label htmlFor="styleName" className="font-weight-bold required">
                                        <FormattedMessage id="project.elements.styles.file" />
                                    </label>
                                    <FilePond.FilePond
                                        id="filePond"
                                        allowMultiple={false}
                                        labelIdle={props.intl.formatMessage({ id: 'project.common.filepond.labelIdle' })}
                                        labelTapToCancel={props.intl.formatMessage({ id: 'project.common.filepond.labelTapCancel' })}
                                        labelFileLoading={props.intl.formatMessage({ id: 'project.common.filepond.labelLoading' })}
                                        labelFileWaitingForSize={props.intl.formatMessage({ id: 'project.common.filepond.labelWaitForSize' })}
                                        onupdatefiles={fileItems => {
                                            setFieldValue("file", fileItems.map(fileItem => fileItem.file));
                                        }}
                                        onremovefile={(error, file) => {
                                            setHasAddedFiles(false);
                                        }}
                                        onaddfile={(error, file) => {
                                            setHasAddedFiles(true);
                                        }}
                                    />
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button id="addGeometricElementStyleSubmit"
                                type="submit"
                                className="btn btn-primary"
                                form="addGeometricElementStyleForm"
                                disabled={!hasAddedFiles}
                            >
                                <FormattedMessage id="project.app.Body.save" />
                            </button>
                            <button id="addGeometricElementStyleCancel"
                                onClick={() => {
                                    hideModalWindow()
                                }}
                                type="cancel"
                                className="btn btn-danger"
                            >
                                <FormattedMessage id="project.app.Body.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                }
            </Formik>
        );
    }

    return null;
}

AddGeometricElementStyle.propTypes = {
    title: PropTypes.object,
    modalShow: PropTypes.bool.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    layerType: PropTypes.string,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
}

export default connect()(injectIntl(AddGeometricElementStyle));