import React from 'react';
import * as appSelectors from '../../app/selectors';
import * as userSelectors from '../../users/selectors';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Errors } from '../../common';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import InternationalizationRender from '../../app/components/InternationalizationRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const mapStateToProps = function (state) {
    return ({
        authenticatedUser: userSelectors.getUser(state),
        language: appSelectors.getLanguage(state),
        allCodes: selectors.getAllCodes(state)
    });
}

class AddModifyGeometricElementJoin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formikErrors: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.modalShow !== this.props.modalShow) {
            return true;
        }

        if (nextProps.geometricElementJoinToModify !== this.props.geometricElementJoinToModify) {
            return true;
        }

        if (nextProps.backendErrors !== this.props.backendErrors) {
            return true;
        }

        if (nextProps.setBackendErrors !== this.props.setBackendErrors) {
            return true;
        }

        if (nextProps.handleSubmit !== this.props.handleSubmit) {
            return true;
        }

        if (nextProps.hideModalWindow !== this.props.hideModalWindow) {
            return true;
        }

        if (
            (nextState.formikErrors !== this.state.formikErrors) ||
            (nextProps.backendErrors !== this.props.backendErrors)
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (this.state.formikErrors !== null || this.props.backendErrors !== null) {
            let container = jQuery('.modal-body');
            if (container.length > 0) {
                if (this.props.backendErrors) {
                    container.scrollTop(0);
                } else {
                    if (this.state.formikErrors) {
                        let field = jQuery('.alert-danger:visible:first').prev();
                        if (field.length)
                            jQuery(container).animate({
                                scrollTop: ((field.offset().top - container.offset().top + container.scrollTop()))
                            });
                    }
                }
            }
        }
        jQuery(function () {
            //show tooltip
            jQuery('label sup.helpTooltip').tooltip({
                html: true,
                placement: 'right',
                trigger: 'click',
                // Prevent placement flip
                fallbackPlacement: ['right'],
                boundary: 'window',
                // Show tables and custom styles inside tooltip
                sanitize: false,
                template: '<div class="help-tooltip tooltip" role="tooltip"><div class="help-tooltip arrow">' +
                    '</div><div class="help-tooltip tooltip-inner"></div></div>'
            });
            //hide it when clicking anywhere else
            jQuery('body').on('click', function (e) {
                jQuery('.helpTooltip').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.tooltip').has(e.target).length === 0) {
                        jQuery(this).tooltip('hide');
                    }
                });
            });

            //Prevent clicking on help button activates input associated with label
            jQuery(document).on('tap click', 'label sup', function (event, data) {
                event.stopPropagation();
                event.preventDefault();
                return false;
            });
        });
    }

    render() {
        if (!this.props.geometricElement) {
            return null;
        }
        if (this.props.authenticatedUser) {
            let handleResetFormAndHideModalWindow = (resetForm) => {
                this.props.hideModalWindow();
                resetForm()
            }

            let initialValues = {
                isPublicJoin: true,
                isReviewedJoin: false,
                comments: ""
            }
            if (this.props.geometricElementJoinToModify) {
                initialValues = {
                    isPublicJoin: this.props.geometricElementJoinToModify.public,
                    isReviewedJoin: this.props.geometricElementJoinToModify.reviewed,
                    comments: this.props.geometricElementJoinToModify.comments
                }
            }
            return (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.props.setBackendErrors(null);
                        let geometricElementJoinForInsert = {
                            geometricElementId: this.props.geometricElement.id,
                            public: values.isPublicJoin,
                            reviewed: values.isReviewedJoin,
                            comments: values.comments,
                        }
                        if (this.props.geometricElementJoinToModify) {

                            this.props.dispatch(actions.updateGeometricElementJoin(this.props.geometricElementJoinToModify.id, geometricElementJoinForInsert, () => {
                                resetForm();
                                this.props.handleSubmit();
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                })
                            );
                        } else {
                            this.props.dispatch(actions.addGeometricElementJoin(geometricElementJoinForInsert, () => {
                                resetForm();
                                this.props.handleSubmit();
                            },
                                errors => {
                                    this.props.setBackendErrors(errors);
                                })
                            );
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ errors, resetForm }) =>
                        <Modal show={this.props.modalShow} onHide={() => handleResetFormAndHideModalWindow(resetForm)} scrollable={true} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <InternationalizationRender value={this.props.geometricElement.geometricElementTypeDto.joinTooltipText} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="needs-validation novalidate" id="addModifyGeometricElementJoinForm">
                                    {errors ? this.setState({ formikErrors: errors }) : ""}
                                    <Errors errors={this.props.backendErrors} onClose={() => this.props.setBackendErrors(null)} />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <Field type="checkbox" id="isPublicJoin" name="isPublicJoin" />
                                            </div>
                                        </div>
                                        <label htmlFor="isPublicJoin" className="btn input-group-text">
                                            <FormattedMessage id="project.elements.join.isPublic" />
                                            &nbsp;
                                            <sup id="isPublicHelp"
                                                className="helpTooltip btn-link"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden={true}
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                data-original-title={
                                                    this.props.intl.formatMessage({
                                                        id: this.props.authenticatedUser.userRoleDto.code !== "ADMIN" ?
                                                            "project.elements.join.isPublic.help.user"
                                                            :
                                                            "project.elements.join.isPublic.help.admin"
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </sup>
                                        </label>
                                    </div>

                                    {
                                        this.props.authenticatedUser &&
                                        this.props.authenticatedUser.userRoleDto.code === "ADMIN" &&
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <Field type="checkbox" id="isReviewedJoin" name="isReviewedJoin" />
                                                </div>
                                            </div>
                                            <label htmlFor="isReviewedJoin" className="btn input-group-text">
                                                <FormattedMessage id="project.elements.join.isReviewed" />
                                            </label>
                                        </div>
                                    }

                                    <div className="form-group">
                                        <label className="font-weight-bold" htmlFor="comments">
                                            <FormattedMessage id="project.elements.join.comments" />
                                        </label>
                                        <Field as="textarea" id="comments" name="comments" className="form-control" />
                                    </div>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button id="addModifyGeometricElementJoinSubmit" type="submit" className="btn btn-primary"
                                    form="addModifyGeometricElementJoinForm"
                                >
                                    <FormattedMessage id="project.app.Body.accept" />
                                </button>
                                <button id="addModifyGeometricElementJoinCancel" type="button" className="btn btn-danger"
                                    onClick={() => {
                                        handleResetFormAndHideModalWindow(resetForm)
                                    }}
                                >
                                    <FormattedMessage id="project.app.Body.cancel" />
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                </Formik>
            );
        } else {
            return (
                <Modal show={this.props.modalShow} onClose={this.props.hideModalWindow} onHide={this.props.hideModalWindow} scrollable={true} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <InternationalizationRender value={this.props.geometricElement.geometricElementTypeDto.joinTooltipText} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert alert-primary text-left">
                            <FormattedMessage id="project.elements.join.anonymous.message"
                                values={{
                                    link: <b>{this.props.intl.formatMessage({ id: "project.app.Header.login" }).toLocaleLowerCase()}</b>
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary"
                            onClick={this.props.hideModalWindow}
                        >
                            <FormattedMessage id="project.common.close" />
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }
}

AddModifyGeometricElementJoin.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    geometricElement: PropTypes.object.isRequired,
    backendErrors: PropTypes.object,
    setBackendErrors: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideModalWindow: PropTypes.func.isRequired,
    authenticatedUser: PropTypes.object
}

export default connect(mapStateToProps)(injectIntl(AddModifyGeometricElementJoin));